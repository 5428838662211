import turkey from './language/tr/translation.json';
import i18next from 'i18next';
i18next.t('Cybersmarties')
const textProcessor = {
    postNotification: function (text) {

        if (i18next.language === 'tr') {

            for (const [key, value] of Object.entries(turkey)) {
                const regex = new RegExp(key.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'g');
                text = text.replace(regex, value);
            }

        }

        return text;

    },

};

export default textProcessor;