import React, { useState, useRef, useEffect } from "react";
import "./App.css";
import io from "socket.io-client";
import "react-toastify/dist/ReactToastify.css";
import "a11y-dialog";
import "./component/inc/modal/Claire.css";

// router
import {
  Route,
  useNavigate,
  Routes,
  BrowserRouter as Router,
} from "react-router-dom";
import Home from "./component/otherComponent/home/Home";
// import Chat from './component/otherComponent/chat/Chat';
// import AddFriend from './component/otherComponent/Add-friend/AddFriend';
import School_Profile from "./component/otherComponent/school-Profile/School_Profile";
// import Subject_Chat from './component/otherComponent/subject-chat/Subject_Chat';
import Login from "./component/otherComponent/login/Login";
import My_Class from "./component/otherComponent/my-class/My_Class";
import My_Homework from "./component/otherComponent/my-homework/My_Homework";
import My_Homework_Teacher from "./component/otherComponent/my-homework-teacher/My_Homework_Teacher";
import Homework_Details from "./component/otherComponent/my-homework/Homework_Details";
import Generated_Reports from "./component/otherComponent/generated-report/Generated_Reports";
import Add_Classmate from "./component/otherComponent/add-classmate/Add_Classmate";
import My_Profile from "./component/otherComponent/my-profile/My_Profile";
// import Result_Details from './component/otherComponent/result-details/Result_Details';
import Privacy from "./component/otherComponent/privacy/Privacy";
import Term_Condition from "./component/otherComponent/terms-conditation/Term_Condition";
import Fun_Zone from "./component/otherComponent/funzone/Fun_Zone";
import Fun_zone_details from "./component/otherComponent/funzone/Fun_zone_details";
import About from "./component/otherComponent/about/About";
// import Countact from './component/otherComponent/contactus/Countact_us'
import Countact_us from "./component/otherComponent/contactus/Countact_us";
import Faq from "./component/otherComponent/faq/Faq";
// import My_Messages from './component/otherComponent/my-message/My_Messages';
import Comment from "./component/otherComponent/comment/Comment";
import Post from "./component/otherComponent/post/Post";
import Notification from "./component/otherComponent/notification/Notification";
import My_Class_Teacher from "./component/otherComponent/my-class/My_Class_Teacher";
import env from "react-dotenv";
import $ from "jquery";
import {
  clear_claire_bot_user_data,
  get_chat_room_list,
  get_chat_room_list_test,
  get_chat_room_history,
  get_chat_room_history_test,
  add_report,
  add_chat_report,
  get_chat_bot_questionList,
  limit_home_work_list,
  save_user_bot_data,
  get_bot_questions,
  get_claire_bot_user_status,
  set_claire_bot_user_status,
} from "./services/user_apis";
import Picker from "emoji-picker-react";
import localizify from "localizify";
import { useTranslation } from "react-i18next";
import en from "./languages/en";
import ar from "./languages/ar";
import tu from "./languages/tu";
import AddAnnouncement from "./component/otherComponent/my-class/AddAnnouncement";
import AddHomeWork from "./component/otherComponent/my-homework-teacher/AddHomeWork";
import AddOnlineClass from "./component/otherComponent/my-homework-teacher/AddOnlineClass";
import HomeWorkDetailsTeacher from "./component/otherComponent/my-homework-teacher/HomeWorkDetailsTeacher";
import ResultDetails from "./component/otherComponent/my-homework/ResultDetails";
import SubmitWorkDetails from "./component/otherComponent/my-homework-teacher/SubmitWorkDetails";
import RemarksSubmitWork from "./component/otherComponent/my-homework-teacher/RemarksSubmitWork";
import ReportListing from "./component/otherComponent/report/ReportListing";
import Friend_Profile from "./component/otherComponent/friend_profile/Friend_Profile";
import GLOBAL_CONSTANT from "./config/global";
import Tutorial from "./component/otherComponent/tutorial/Tutorial";
import Tutorial_details from "./component/otherComponent/tutorial/Tutorial_details";
import Swal from "sweetalert2";
// import Modal from './component/inc/modal/Modal';
import { timeConvert } from "./Utils/TimeUtils";
// import { fetchToken, onMessageListener } from '../src/firebase';
import NotificationSound from "./notification_sound.mp3";

import * as htmlparser2 from "htmlparser2";
import TextToSpeech from "./services/textToSpeech";
import A11yDialog from "a11y-dialog";
import Test from "./Test";

// Language change
localizify.add("en", en).add("ar", ar).add("tu", tu).setLocale("tu");

function App() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [userdata, setUserData] = useState(
    JSON.parse(localStorage.getItem("user")) | []
  );
  const [isDisplayChat, setIsDisplayChat] = useState(true);
  const [chatroomuser, setChatRoomUser] = useState();
  const [unreadMsgCount, setUnreadMsgCount] = useState(0);
  const [message, setMessage] = useState("");
  const [messagedata, setMessageList] = useState([]);
  const [socket, setSocket] = useState(null);
  const [chatroomlist, setChatRoomList] = useState([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(() => false);
  const [boatQuestionList, setBoatQuestionList] = useState([]);
  const [botVideoLink, setBotVideoLink] = useState("");
  const [isTokenFound, setTokenFound] = useState(false);
  const [homeworknotice, setHomeworknotice] = useState([]);
  var selectedChatId = "";
  var [selectedChat, setSelectedChat] = useState(null);
  const textareaRef = useRef(null);
  const audioPlayer = useRef(null);

  const claireRef = useRef();
  const [showClaireClose, setShowClaireClose] = useState(false);
  const [showClaireBack, setShowClaireBack] = useState(true);
  const [claireParentId, setClaireParentId] = useState(0);
  const [claireId, setClaireId] = useState(0);
  const [botVideoMessage, setBotVideoMessage] = useState("");
  const [mouthMove, setMouthMove] = useState(false);

  const [currPage, setCurrPage] = useState(1); // storing current page number
  const [prevPage, setPrevPage] = useState(0); // storing prev page number
  const [currentChatList, setCurrentChatList] = useState(""); // storing list
  const [wasLastList, setWasLastList] = useState(false); // setting a flag to know the last list
  const listInnerRef = useRef();
  const [prevHeight, setPrevHeight] = useState(0); // storing prev page number
  const [chatLimit, setChatLimit] = useState(10);
  const user = JSON.parse(localStorage.getItem("user"));

  global.GLOBAL_CONSTANT = GLOBAL_CONSTANT;

  const toggleEmojiPicker = () => setShowEmojiPicker((prev) => !prev);

  let report_subjectList = [
    { id: 1, name: t("It was mean to me") },
    { id: 2, name: t("He/She was making fun of me") },
    { id: 3, name: t("It was spreading lies about me") },
    { id: 4, name: t("It said bad things about me") },
    { id: 5, name: t("It used bad language") },
    { id: 6, name: t("I didn't like the question being asked") },
    { id: 7, name: t("It made me feel uncomfortable") },
    { id: 8, name: t("It was looking for personal information") },
    { id: 9, name: t("Other") },
  ];

  function getSocketUrl() {
    return `${GLOBAL_CONSTANT.API_URL}/socket`;
  }

  function getSocketUrlTest() {
    return `${GLOBAL_CONSTANT.CHAT_API}`;
  }

  // To check and redirect to login page if the user session is not loggedin
  if (user == null && window.location.pathname.split("/").pop() !== "") {
    let path = window.location.protocol + "//" + window.location.host;
    window.location.href = path;
  }

  React.useEffect(() => {
    // const user = JSON.parse(localStorage.getItem('user'))
    localStorage.removeItem("chatRoom");
    const user = JSON.parse(localStorage.getItem("user"));
    if (user === null) {
      setShowClaireClose(false);
    }
    if (user !== null) {
      setUserData(user);
      const newSocket = io(
        getSocketUrlTest() +
          "?user_id=" +
          user.user_id +
          "&user_type=" +
          user.user_role,
        {
          transports: ["websocket", "polling"],
          auth: {
            token: user.token,
          },
          reconnection: true,
          autoConnect: true,
          withCredentials: true,
        }
      );
      // console.log(newSocket);
      // const newSocket = io(getSocketUrl() + "?user_id=" + user.user_id + "&user_type=" + user.user_role, { reconnection: false, transports: [ "websocket" ] });
      // const newSocket = io(getSocketUrl() + "?user_id=" + user.user_id + "&user_type=" + user.user_role, { reconnection: true, transports: [ "websocket" ] });
      setSocket(newSocket);
      global.socket = newSocket;
      getChatBoatQuestions("");
      // onlineFriendList()
      user.user_role === "teacher" && $("a.chat-desk-icon").show();
      alertHomeworkOnlineClass();
      setClaireBotUserStatus();
      if (socket) onlineFriendList({ reset: true });
    }
    // return () => {
    //     if (socket != null) {
    //         socket.close()
    //     }
    // };
    // const interval = setInterval(() => {
    //     const user = JSON.parse(localStorage.getItem('user'))
    //     if (user !== null) {
    //         setUserData(user)
    //         const newSocket = io(getSocketUrl() + "?user_id=" + user.user_id + "&user_type=" + user.user_role);
    //         setSocket(newSocket);
    //     }
    //   }, 5000);
    //   return () => clearInterval(interval);
  }, []);

  const addingupmessages = async (newData) => {
    if (newData.code === 1) {
      // console.log("incoming messages", newData.data);
      // let allmssages = messagedata;
      // await allmssages.push(newData.data);
      let allmssages = [].concat(messagedata, [newData.data]);
      // console.log('allmssages', allmssages)
      if (allmssages !== null) await setMessageList(allmssages);
      // var objDiv = $("#chatMessages");
      // objDiv[0].scrollTop = objDiv[0].scrollHeight + 150;
    }
  };
  useEffect(() => {
    if (showClaireClose && userdata.length > 0) onlineFriendList();
    if (!showClaireClose && userdata.length > 0) {
      var images = [
        "claire_1.png",
        "claire_2.png",
        "claire_3.png",
        "claire_3.png",
        "claire_3.png",
        "claire_3.png",
        "claire_3.png",
      ];
      var eyesBlink = setInterval(function () {
        if (showClaireClose) clearInterval(eyesBlink);
        var image = document.getElementById("clairebot_img");
        if (user && image) {
          image.src = "/assets/web/" + images[0];
          setTimeout(() => {
            image.src = "/assets/web/" + images[1];
          }, 200);
        }
      }, 3000);
    }
  }, [showClaireClose]);

  // useEffect(() => {
  //     var theCounter = 0;
  //     const clearIntervalCounter = setInterval(()=>{
  //         if(showClaireClose && userdata && chatroomlist.length > 0) {
  //             chatroomlist.map((chatroom, i) => {
  //                 if(i < 10) {
  //                     var sendObj = { "school_id": user.school_id ,"sender_id": user.user_id ,"sender_type": user.user_role ,"receiver_id": chatroom.user_details.user_id ,"receiver_type": chatroom.user_details.user_role ,"message": `message test ${theCounter}`, "chat_room_id": chatroom.chat_room_id }
  //                     socket.emit('send_message', JSON.stringify(sendObj));
  //                     socket.emit('send_message', JSON.stringify(sendObj));
  //                     // console.log('Loop counter: ', chatroom);

  //                 }
  //                 theCounter++;
  //             });
  //         }
  //     }, 1000);
  //     return () => clearInterval(clearIntervalCounter);
  // });

  React.useEffect(() => {
    if (socket !== null) {
      socket.on("send_message", async (data) => {
        // console.log(data)
        if (data.message === "rest_keyword_send_msg_success") {
          if (
            data.code === 1 &&
            typeof data.data !== "undefined" &&
            data.data.length > 0
          )
            setMessageList(data.data);
          setMessage("");
          $("#message").val("");
        } else if (data.message === "rest_keyword_message_receive_success") {
          let userRoomData = JSON.parse(localStorage.getItem("chatRoom"));
          if (
            typeof userRoomData !== "undefined" &&
            userRoomData?.chat_room_id === data?.data?.chat_room_id
          ) {
            await getChatRoomDetails({ chat_room_id: data.data.chat_room_id });
          } else {
            await onlineFriendList({
              chat_room_id: data.data.chat_room_id,
              action: "add",
            });
            // await modifyChatRoomList(data)
          }
          audioPlayer.current.play();
        } else if (data.message === "rest_keyword_notification_send_success") {
          if (data.code === 1 && data.data) {
            let newChat = [];
            newChat.push(
              ...data.data.new,
              ...data.data.old,
              ...data.data.update
            );
            newChat = newChat
              .filter((chat) => chat.is_delete !== 1)
              .sort((a, b) => a.created_at - b.created_at);
            setMessageList(newChat);
          }
          setMessage("");
          $("#message").val("");
          setTimeout(() => {
            var objDiv = $("#chatMessages");
            if (objDiv && objDiv[0])
              objDiv[0].scrollTop = objDiv[0].scrollHeight + 150;
          }, 200);
        } else if (data.message === "rest_keyword_notification_send_failed") {
          Swal.fire({
            icon: "error",
            title: t("Cybersmarties"),
            text: t("Not able to deliver the message"),
            confirmButtonText: t("Okay"),
          });
          setMessage("");
          $("#message").val("");
        }
      });

      socket.on("change_group_info", (data) => {
        // // console.log("change_group_info")
        // // console.log("<p className='message'>" + JSON.stringify(data) + "</p>")
      });

      socket.on("message_delivered", async (data) => {
        // console.log("Message Deliverd", data)
        if (data.message === "rest_keyword_notification_not_send") {
          Swal.fire({
            icon: "error",
            title: t("Cybersmarties"),
            text: t("Not able to deliver the message"),
            confirmButtonText: t("Okay"),
          });
        } else {
          setMessage("");
          if (
            typeof data.data !== "undefined" &&
            data.data !== null &&
            typeof data.data.length !== "undefined"
          )
            setMessageList(data.data);
        }
        // await getChatRoomDetails(data.data.data.chat_room_id)
        // socket.on("message_delivered", (data) => {
        //     // console.log("Message Deliverd")
        // // console.log("<p className='message'>" + JSON.stringify(data) + "</p>")
      });

      socket.on("message_read", (data) => {
        // // console.log("message_read")
        // // console.log("<p className='message'>" + JSON.stringify(data) + "</p>")
      });

      socket.on("live", (data) => {
        // console.log("live");
        onlineFriendList({ reset: true });
      });

      socket.on("send_quote", (data) => {
        // // console.log("send_quote")
        // // console.log("<p className='message'>" + JSON.stringify(data) + "</p>")
      });

      socket.on("report_chat", (response) => {
        if (response.code === 1) {
          let newChat = [];
          newChat.push(
            ...response.data.new,
            ...response.data.old,
            ...response.data.update
          );
          newChat = newChat
            .filter((chat) => chat.is_delete !== 1)
            .sort((a, b) => a.created_at - b.created_at);
          // console.log('newChat', newChat);
          setMessageList(newChat);
          $("#ReportMessage_Modal_1").hide();
          $(".modal-backdrop").hide();
          $("#reportDescription").value = "";
          Swal.fire({
            icon: "success",
            title: t("Successful"),
            text: t(response.message),
          });
        }
      });

      socket.on("get_quote_list", (data) => {
        // // console.log("get_quote_list")
        // // console.log("<p className='message'>" + JSON.stringify(data) + "</p>")
      });

      socket.on("rest_keyword_message_user_offline", (data) => {
        // // console.log("rest_keyword_message_user_offline")
        // // console.log("<p className='message'>" + JSON.stringify(data) + "</p>")
      });
      socket.on("chat_list_refresh", (data) => {
        if (
          data.code === 1 &&
          data.message === "rest_keyword_chat_list_refresh_success"
        ) {
          onlineFriendList({ reset: true });
        } else {
          onlineFriendList();
        }
        // console.log("chat_list_refresh", (data));
        // $(".chatbox").hide();
        // // // console.log("chat_list_refresh")
        // if(data.code === 0) {
        //     onlineFriendList()
        // }
        // if(data.code === 1 && typeof (data.data).length !== 'undefined' && (data.data).length > 0) {
        //     // console.log("chat_list_refresh", data.data)
        //     setChatRoomList(data.data)
        // }
        // // // console.log("<p className='message'>" + JSON.stringify(data) + "</p>")
      });
      socket.on("block_word", (data) => {
        // onlineFriendList();
        // console.log("block_word", data);
        // // console.log("<p className='message'>" + JSON.stringify(data) + "</p>")
        $("#message").focus();
        Swal.fire({
          icon: "error",
          title: t("Cybersmarties"),
          text: t(data.message),
          confirmButtonText: t("Okay"),
        });
      });
      socket.on("chat_room_list", (response) => {
        // console.log("chat_room_list", response.data);
        if (response.code === 1) {
          // // console.log("Chat Count Check",response.data)
          let count = 0;
          if (response?.data?.length) {
            response.data.forEach((item) => {
              count = count + item?.unread_count;
            });
          }
          setUnreadMsgCount(count);
          setChatRoomList(response.data);
        } else if (response.code == "2") {
          setChatRoomList([]);
          setUnreadMsgCount(0);
        }
      });

      socket.on("chat_messages", (data) => {
        // console.log("chat_messages", data);
      });

      socket.on("get_chat_room_history", (data) => {
        // console.log("get_chat_room_history", data);
        if (data.code === 1) {
          if (data.data !== null) {
            let newChat = [];
            newChat.push(
              ...data.data.new,
              ...data.data.old,
              ...data.data.update
            );
            newChat = newChat
              .filter((chat) => chat.is_delete !== 1)
              .sort((a, b) => a.created_at - b.created_at);
            setMessageList(newChat);
          }
          setTimeout(() => {
            var objDiv = $("#chatMessages");
            if (objDiv && objDiv[0])
              objDiv[0].scrollTop = objDiv[0].scrollHeight + 150;
          }, 200);
        } else {
          setMessageList([]);
        }
      });

      socket.on("get_chat_room_history_more", (response) => {
        // console.log("get_chat_room_history_more Return", response);
        const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
        if (!response.data.length) {
          setWasLastList(true);
          setCurrentChatList("");
        } else {
          if (response.code === 1) {
            setCurrPage(currPage + 1);
            setCurrentChatList($("#chatMessages").html());
            // let newMessage = messagedata;
            // if((response.data).length > 0) newMessage.push(response.data)
            if (response.data.length > 0 && messagedata !== null) {
              let newChat = [];
              newChat.push(...response.data);
              newChat = response.data
                .filter((chat) => chat.is_delete !== 1)
                .sort((a, b) => a.created_at - b.created_at);
              newChat.push(messagedata);
              setMessageList(newChat);
              $("#chatMessages").scrollTop(scrollHeight - prevHeight);
              setPrevHeight(scrollHeight);
            }
            // setMessageList(newMessage);
            //alert(scrollHeight + " : " + prevHeight);
            // $("#chatMessages").scrollTop(scrollHeight - prevHeight);
            // setPrevHeight(scrollHeight);
          } else {
            setMessageList([]);
          }
        }
      });
    }
    // return () => {
    //     if (socket != null) socket.close()
    // }
  }, [socket]);

  useEffect(() => {
    var index = 0;
    var imagefile = "claire_2.png";
    var updateMountImage = setInterval(function () {
      if (!mouthMove) clearInterval(updateMountImage);
      if (index > 16) {
        setMouthMove(false);
        clearInterval(updateMountImage);
      }
      if (mouthMove) {
        var imageElement = document.getElementById("clairebot_img");
        if (imagefile === "claire_2.png") {
          imagefile = "claire_3.png";
        } else {
          imagefile = "claire_2.png";
        }
        imageElement.src = "/assets/web/" + imagefile;
        index = index + 1;
      }
    }, 500);
  }, [mouthMove]);

  const getSenderMessage = (data) => {
    var mainData = JSON.parse(data);
    $("#chatMessages").append(
      "<div style=' width: 100%; margin: 0 0 15px; display: flex; flex-flow: column; align-items: flex-end;'><div  className='message-box'  style='padding: 6px 10px;border-radius: 6px 0 6px 0; position: relative;background: #eaeaea; border: 2px solid #eaeaea; color: #6c6c6c; font-size: 12px;'>" +
        mainData?.message +
        "</div></div>"
    );
  };

  const getReceiverMessage = (data) => {
    var mainData = JSON.parse(data);
    $("#chatMessages").append(
      '<div style=" width: 100%; margin: 0 0 18px; display: flex; flex-flow: column; align-items: flex-end;"><div style="font-size: 12px; margin: 0 0 10px; color: #4a4a4a; align-self: flex-start;">' +
        mainData?.display_user_name +
        '</div> <div style="padding: 6px 10px;border-radius: 6px 0 6px 0; position: relative;background: #eaeaea; border: 2px solid #eaeaea; color: #6c6c6c; font-size: 12px; background: #9c6278; border: 2px solid #9c6278; align-self: flex-start; color: #fff;">' +
        mainData?.message +
        "</div></div>"
    );
  };

  // React.useEffect(() => {
  //     getChatBoatQuestions("")
  //     onlineFriendList()
  //     if (user != null) {
  //         user.user_role === "teacher" && $("a.chat-desk-icon").show();
  //     }
  // }, []);

  // React.useEffect(() => {
  //     // // console.log("Create tokan method call")
  //     // fetchToken(isTokenFound)
  //     // onMessageListener().then(payload => {
  //     //     // console.log(payload);
  //     //   }).catch(err => // console.log('failed: ', err));
  //     alertHomeworkOnlineClass();
  // }, []);

  const alertHomeworkOnlineClass = async () => {
    const result = await limit_home_work_list();
    if (result.code === 1) setHomeworknotice(result.data);
  };

  const changeIsDisplayMethod = (value) => {
    setIsDisplayChat(value);
    if (!value && socket) socket.close();
  };

  const getChatBoatQuestions = async (parent_id, id) => {
    setClaireParentId(parent_id);
    setClaireId(id);
    const user = JSON.parse(localStorage.getItem("user"));

    let post_body = {
      id: id,
    };
    if (id === "") {
      delete post_body.id;
    }

    const response = await get_bot_questions(post_body);
    // // console.log("boat question list ======> ", response)
    if (response.code === 1) {
      // Set the claire window back button
      if (response.data.is_last === 1 || response.data.parent_id === 0) {
        setShowClaireBack(false);
      } else {
        setShowClaireBack(true);
      }
      setBoatQuestionList(response.data);
    }

    if (parent_id !== "" && id !== undefined) {
      const response_save = await saveUserBotData(parent_id, id);
      if (response_save.code !== 1) {
        alert("Issue encountered while updating the bot user data!");
        return false;
      }
    }

    if (response.data.is_last === 1 && response.data.options.length === 0) {
      const response_set = await set_claire_bot_user_status({
        user_id: user.user_id,
        user_role: user.user_role,
      });
      if (response_set.code === 1) {
        setShowClaireClose(true);
      }
    }

    const update_response = await get_claire_bot_user_status({
      user_id: user.user_id,
      user_role: user.user_role,
    });
    if (update_response.code === 1) {
      const res_data = update_response.data;
      setShowClaireClose(res_data);
    }
  };

  const saveUserBotData = async (current_question_id, selected_option_id) => {
    // const user = JSON.parse(localStorage.getItem('user'));
    let post_data = {
      user_id: user.user_id,
      user_type: user.user_role,
      current_question_id: current_question_id,
      selected_option_id: selected_option_id,
    };
    const response_save = await save_user_bot_data(post_data);
    return response_save;
  };

  const onClickChatMinimize = () => {
    // // console.log("onCLick chat close");
    $("a.chat-desk-icon").show();
    $(".chat-with-friend").removeClass("chat-with-desk-friend-active");
  };

  const onClickChatMaximize = () => {
    // // console.log("onCLick chat Maximize");
    $(this).hide();
    $(".chat-with-friend").toggleClass("chat-with-desk-friend-active");
  };

  const onClickClariaBot = (event) => {
    // $('.clarie > #fee1').addClass('feeling-active');
    event.stopImmediatePropagation();
  };

  const onCLickClariaBotClose = async (event) => {
    // console.log("onCLickClariaBotClose");
    // const user = JSON.parse(localStorage.getItem('user'))

    $(".clarie").removeClass("clarie-active");
    $("a.chat-desk-icon").show();
    $(".clarie > .feeling-modal").removeClass("feeling-active");
    getChatBoatQuestions("");
    const response_set = await set_claire_bot_user_status({
      user_id: user.user_id,
      user_role: user.user_role,
    });
    // console.log("response_set", response_set)
    if (response_set.code === 1) {
      setShowClaireClose(true);
    }
    event.stopPropagation();
  };

  const onCLickClariaBotBack = async (event) => {
    const user = JSON.parse(localStorage.getItem("user"));

    // Set previous option window data for claire
    getChatBoatQuestions("", claireParentId);

    // Clear the previous selection
    const response = await clear_claire_bot_user_data({
      user_id: user.user_id,
      parent_id: claireParentId,
      id: claireId,
    });
    event.stopImmediatePropagation();
  };

  const showClaireBotWinClose = async (event) => {
    // const user = JSON.parse(localStorage.getItem('user'));
    setBotVideoLink("");
    const response_set = await set_claire_bot_user_status({
      user_id: user.user_id,
      user_role: user.user_role,
    });
    if (response_set.code === 1) {
      setShowClaireClose(true);
      await onlineFriendList({ reset: true });
    }
    event.stopImmediatePropagation();
  };
  // $('#good').on('click', function (event) {
  //     $('.clarie > #fee2').addClass('feeling-active');
  //     $('.clarie > #fee1').removeClass('feeling-active');
  //     event.stopImmediatePropagation();
  // });

  const onlineFriendList = async (data = null) => {
    // console.log('OnlineFriendList', user)

    const post_body = {
      count: data.count ? data.count : false,
      user_id: `${user.user_id}`,
      user_role: user.user_role,
      chat_room_id: data.chat_room_id ? data.chat_room_id : null,
      reset: data && data.reset ? data.reset : false,
      action: data.action,
    };
    await socket.emit("get_chat_room_list", post_body);
    // const user = JSON.parse(localStorage.getItem('user'))
    // const post_body = {
    //     user_id: `${user.user_id}`,
    //     user_type: user.user_role
    // }
    // const response = await get_chat_room_list_test(post_body)
    // // // console.log('onlineFriendList', response)
    // if (response.code === 1) {
    //     // // console.log("Chat Count Check",response.data)
    //     let count = 0;
    //     if(response?.data?.length){
    //         response.data.forEach( item => {
    //         count = count + item?.unread_count
    //       });
    //     }
    //     await setUnreadMsgCount(count);
    //     await setChatRoomList(response.data)
    // } else if (response.code == "2") {
    //     await setChatRoomList([])
    //     await setUnreadMsgCount(0);
    // }
  };

  const callChatUnreadEmit = async (chatRoomId) => {
    // var msgSend = '{"receiver_id": "'+user.user_id+'","chat_room_id":"' + chatRoomId + '"}'
    // // // console.log("msgRead", msgSend)
    // await socket.emit('read_message', msgSend);
    // // await onlineFriendList();
  };

  const getChatRoomDetails = async (data) => {
    // // console.log("getChatRoomDetails", chatRoomId);
    setCurrPage(1);
    setCurrentChatList("");
    setPrevHeight(0);
    const post_body = {
      user_id: user.user_id,
      chat_room_id: data.chat_room_id,
      page: 1,
      limit: chatLimit,
      reset: typeof data.reset !== "undefined" ? data.reset : false,
    };
    // console.log('getChatRoomDetails', post_body);
    await socket.emit("get_chat_room_history", post_body);
    // const response = await get_chat_room_history_test(post_body)
    // // // console.log('get_chat_room_history_test', response);
    // if (response.code === 1) {
    //     setMessageList(response.data)
    //     setTimeout(() => {
    //         var objDiv = $("#chatMessages");
    //         objDiv[0].scrollTop = objDiv[0].scrollHeight + 150;
    //     }, 200);
    // } else {
    //     setMessageList([])
    // }
  };

  const sendMessage = () => {
    // const user = JSON.parse(localStorage.getItem('user'))
    var messageData = message;
    if (messageData.trim() !== "") {
      // setMessage("");
      // var msgSend = '{"school_id":' + user.school_id + ',"sender_id":' + user.user_id + ',"sender_type":"' + user.user_role + '","receiver_id":"' + chatroomuser.user_details.user_id + '","receiver_type":"' + chatroomuser.user_details.user_role + '","message":"' + messageData.trim() + '"}'

      var sendObj = {
        school_id: user.school_id,
        sender_id: user.user_id,
        sender_type: user.user_role,
        receiver_id: chatroomuser.user_details.user_id,
        receiver_type: chatroomuser.user_details.user_role,
        message: messageData.trim(),
        chat_room_id: chatroomuser.chat_room_id,
      };
      socket.emit("send_message", JSON.stringify(sendObj));
      // $('#message').val('');
      $("#message").focus();
    }
  };

  const acceptRequest = (data) => {
    var msgSend = { receiver_id: data.user_id, user_role: data.user_role };
    socket.emit("chat_list_refresh", msgSend);
    onlineFriendList({ reset: true });
  };

  const hideChatbox = () => {
    $(".fa-minus").closest(".chatbox").toggleClass("chatbox-min");
    setShowEmojiPicker(false);
  };

  const onEmojiClick = (e, emojiObject) => {
    const cursor = textareaRef.current.selectionStart;
    const text =
      message.slice(0, cursor) + emojiObject.emoji + message.slice(cursor);
    setMessage(text);
    textareaRef.current.focus();
    checkMessageCount(e, text);
  };

  const sendMessage_report_new = async () => {
    var report_subject = $("#report_message_subject_1").val();
    var report_description = $("#reportDescription_1").val();

    // // console.log(selectedChat)
    let userRoomData = JSON.parse(localStorage.getItem("chatRoom"));
    // console.log('userRoomData',userRoomData)
    let post_body = {
      school_id: user.school_id,
      division_id: user.division_id,
      class_id: user.class_id,
      report_type: "message",
      report_type_id: selectedChat.id,
      report_description: report_description,
      report_subject: report_subject,
      report_to_user_id: userRoomData?.user_details?.user_id,
      report_from_user_id: user.user_id,
      chat_message: selectedChat.message,
      chat_room_id: chatroomuser.chat_room_id,
    };
    // console.log("sendMessage_report_new", post_body)
    // const response = await add_chat_report(post_body)
    // // console.log("Call Response body",response)
    await socket.emit("report_chat", post_body);
    // await getChatRoomDetails(chatroomuser.chat_room_id);
    // if (response.code === 1) {
    //     getChatRoomDetails(userRoomData.chat_room_id)
    //     $('#ReportMessage_Modal_1').hide();
    //     $('.modal-backdrop').hide()
    //     $("#reportDescription").value = "";
    //     Swal.fire({
    //         icon: 'success',
    //         title: 'Successful',
    //         text: response.message,
    //     })
    // }
  };

  const checkMessageCount = (event, message_str) => {
    var word_count = 200;
    var character_count = 1200;
    var message_val = "";

    // Check character length
    if (message_str.length > character_count) {
      setMessage(message_str.substring(0, character_count));
      return;
    }

    setMessage(message_str);
    var words = $.trim(message_str)
      .split(" ")
      .filter(function (word) {
        return $.trim(word).length > 0;
      });
    var numberOfLineBreaks = (message_str.match(/\n/g) || []).length;
    var checkLength = words.length + numberOfLineBreaks;

    if (checkLength > word_count) {
      /*var allowedText = "";
            const indicies = [];
            [...message_str].forEach((char, i) => {
                if (char === '\n') indicies.push(i);
            });*/
      /*[...message_str].forEach((char, i) => {
                alert(char + " : " + EmojiPattern.test(char));
            });*/

      message_str = message_str.replace(/\n/g, " ");
      var messageWords = $.trim(message_str)
        .split(" ")
        .filter(function (word) {
          return $.trim(word).length > 0;
        });
      var messageText = messageWords.splice(0, word_count).join(" ");

      /*for (let i = 0; i < indicies.length; i++) {
                if (indicies[i] <= messageText.length - 1) {
                    //messageText = messageText.substring(0, indicies[i]) + "\n" + messageText.substring(indicies[i] + 1);
                    allowedText += messageText.substring(indicies[i - 1], indicies[i]) + "\n";
                }
            }
            allowedText = allowedText + messageText.substring(indicies[indicies.length - 1]);*/

      setMessage(messageText);
      return false;
    }
  };

  const geeks = (event) => {
    // if (event.keyCode === 13 && event.shiftKey) {
    //     // console.log("kjdfkjg dfg dfjg   swift + enter")
    // }
    if (event.keyCode === 13 && !event.shiftKey) {
      setShowEmojiPicker(false);
      sendMessage();
    }
  };

  const setVideoDetails = (video_url, video_msg) => {
    setShowClaireBack(false);
    setBotVideoLink(video_url);
    setBotVideoMessage(video_msg);
  };

  const setClaireBotUserStatus = async () => {
    const update_response = await get_claire_bot_user_status({
      user_id: user.user_id,
      user_role: user.user_role,
    });

    if (update_response.code === 1) {
      const res_data = update_response.data;
      if (res_data) {
        $(".clarie").removeClass("clarie-active");
        $("a.chat-desk-icon").show();
      } else {
        $(".clarie").addClass("clarie-active");
      }
    }
  };

  const onScroll = async (chat_room_id) => {
    if (listInnerRef.current && messagedata.length > 9) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop === 0) {
        // alert(scrollTop + " : " + clientHeight + " : " + scrollHeight);
        // This will be triggered after hitting the last element.
        // API call should be made here while implementing pagination.const response = await get_chat_room_history(post_body)

        const chatRoomId = chat_room_id;
        const post_body = {
          user_id: user.user_id,
          chat_room_id: chatRoomId,
          page: currPage + 1,
          limit: chatLimit,
          reset: true,
        };
        //alert(JSON.stringify(post_body))
        // const response = await get_chat_room_history_test(post_body)
        await socket.emit("get_chat_room_history_more", post_body);
        // if (!response.data.length) {
        //     setWasLastList(true);
        //     setCurrentChatList("");
        //     return;
        // } else {
        //     if (response.code === 1) {
        //         setCurrPage(currPage + 1);
        //         setCurrentChatList($("#chatMessages").html());
        //         setMessageList(response.data);
        //         //alert(scrollHeight + " : " + prevHeight);
        //         $("#chatMessages").scrollTop(scrollHeight - prevHeight);
        //         setPrevHeight(scrollHeight);
        //     } else {
        //         setMessageList([])
        //     }
        // }
      }
    }
  };

  const claireA11yDialog = useRef();

  useEffect(() => {
    const claireDialogElement = claireRef.current;
    console.log("claire effect", claireDialogElement);
    if (
      claireDialogElement !== undefined &&
      claireA11yDialog.current === undefined
    ) {
      claireA11yDialog.current = new A11yDialog(claireDialogElement);
    }
    if (
      claireDialogElement === undefined &&
      claireA11yDialog.current !== undefined
    ) {
      claireA11yDialog.current.destroy();
    }
  }, [claireRef.current, claireA11yDialog.current]);

  return (
    <div className="App">
      <audio ref={audioPlayer} src={NotificationSound} />

      <React.Fragment>
        {/* <Router basename={"/cybersmarties/website/build"}> */}
        <Routes>
          <Route path="/2223test" element={<Test />} />
          <Route
            path="/"
            element={
              userdata ? (
                <Home
                  homeworknotice={homeworknotice}
                  chatHandle={changeIsDisplayMethod}
                  socketData={acceptRequest}
                />
              ) : (
                <Login chatHandle={changeIsDisplayMethod} />
              )
            }
          />
          <Route
            path="/home"
            element={
              <Home
                homeworknotice={homeworknotice}
                chatHandle={changeIsDisplayMethod}
                socketData={acceptRequest}
              />
            }
          />
          <Route
            path="/school-profile"
            element={
              <School_Profile
                socketData={acceptRequest}
                homeworknotice={homeworknotice}
              />
            }
          />
          <Route
            path="/my-class"
            element={
              <My_Class
                socketData={acceptRequest}
                homeworknotice={homeworknotice}
              />
            }
          />
          <Route
            path="/my-class-teacher"
            element={<My_Class_Teacher socketData={acceptRequest} />}
          />
          <Route
            path="/my-homework"
            element={<My_Homework socketData={acceptRequest} />}
          />
          <Route
            path="/my-homework-teacher"
            element={<My_Homework_Teacher socketData={acceptRequest} />}
          />
          <Route path="/faq" element={<Faq socketData={acceptRequest} />} />

          <Route
            path="/add-classmate"
            element={
              <Add_Classmate socketData={acceptRequest} type="n" key="1" />
            }
          />
          <Route
            path="/add-classmate-search"
            element={
              <Add_Classmate socketData={acceptRequest} type="s" key="2" />
            }
          />

          <Route
            path="/my-profile"
            element={<My_Profile socketData={acceptRequest} />}
          />
          {/* <Route path="/message" element={<My_Messages />} /> */}
          <Route
            path="/funezone"
            element={<Fun_Zone socketData={acceptRequest} />}
          />
          <Route
            path="/funzone-details"
            element={<Fun_zone_details socketData={acceptRequest} />}
          />
          {/* <Route path="/chat" element={<Chat />} /> */}
          <Route
            path="/terms-condition"
            element={<Term_Condition socketData={acceptRequest} />}
          />
          <Route path="/about" element={<About socketData={acceptRequest} />} />
          <Route
            path="/contact"
            element={<Countact_us socketData={acceptRequest} />}
          />
          <Route
            path="/Privacy"
            element={<Privacy socketData={acceptRequest} />}
          />
          <Route
            path="/comment/:post_id"
            element={<Comment socketData={acceptRequest} />}
          />
          <Route
            path="/post/:post_id"
            element={<Post socketData={acceptRequest} />}
          />
          <Route
            path="/notification"
            element={<Notification socketData={acceptRequest} />}
          />
          <Route
            path="/homeworkDetails"
            element={<Homework_Details socketData={acceptRequest} />}
          />
          <Route
            path="/result-details-student"
            element={<ResultDetails socketData={acceptRequest} />}
          />
          <Route
            path="/generatedReports"
            element={<Generated_Reports socketData={acceptRequest} />}
          />
          <Route
            path="/otherUserProfile"
            element={<Friend_Profile socketData={acceptRequest} />}
          />

          <Route
            path="/add-home-work/:id"
            element={<AddHomeWork socketData={acceptRequest} />}
          />
          <Route
            path="/add-online-class/:id"
            element={<AddOnlineClass socketData={acceptRequest} />}
          />
          <Route
            path="/edit-home-work/:id"
            element={<AddHomeWork socketData={acceptRequest} />}
          />
          <Route
            path="/add-announcement/:id"
            element={<AddAnnouncement socketData={acceptRequest} />}
          />
          <Route
            path="/edit-announcement/:id"
            element={<AddAnnouncement socketData={acceptRequest} />}
          />
          <Route
            path="/home-work-details-teacher"
            element={<HomeWorkDetailsTeacher socketData={acceptRequest} />}
          />
          <Route
            path="/home-work-submit-details"
            element={<SubmitWorkDetails socketData={acceptRequest} />}
          />
          <Route
            path="/home-work-submit-remarks"
            element={<RemarksSubmitWork socketData={acceptRequest} />}
          />
          <Route
            path="/reportAction"
            element={<ReportListing socketData={acceptRequest} />}
          />
          <Route
            path="/tutorial"
            element={<Tutorial socketData={acceptRequest} />}
          />
          <Route
            path="/tutorial-details"
            element={<Tutorial_details socketData={acceptRequest} />}
          />
        </Routes>
        {/* </Router> */}
      </React.Fragment>
      {!isDisplayChat ? (
        <></>
      ) : (
        <>
          <a
            href="#"
            title="Chat List"
            role="button"
            onClick={() => onClickChatMaximize()}
            className="chat-desk-icon"
            style={{ display: "none" }}
          >
            <img
              alt="Chat"
              aria-label="Chat"
              src={process.env.PUBLIC_URL + "/" + "assets/web/chat-icon.png"}
            />
            {unreadMsgCount ? (
              <div className="chat-desk-icon_dot">{unreadMsgCount}</div>
            ) : null}
          </a>
          <div className="chat-with-friend">
            <div
              onKeyUp={(e) => {
                if (e.key === "Enter" || e.key === " ") onClickChatMinimize();
              }}
              onClick={() => onClickChatMinimize()}
              className="chat-head"
              tabIndex={0}
            >
              <h3>
                <TextToSpeech
                  text={`${t("Chat with Friends")} (${chatroomlist.length})`}
                />
              </h3>
            </div>
            <div className="chat-body">
              {chatroomlist.map((item, i) => (
                <>
                  {item.user_details ? (
                    <div className="chat-list-box" key={i}>
                      <a
                        title={`Chat with ${
                          item?.user_details?.display_user_name != ""
                            ? item?.user_details?.display_user_name
                            : item?.user_details?.username
                        } who is ${
                          item?.user_details?.chat_status === "online"
                            ? "online"
                            : "offline"
                        }`}
                        href="#"
                        onClick={() => {
                          $(".fa-minus")
                            .closest(".chatbox")
                            .removeClass("chatbox-min");
                          setChatRoomUser(item);
                          $(".chatbox").show();
                          localStorage.setItem(
                            "chatRoom",
                            JSON.stringify(item)
                          );
                          getChatRoomDetails({
                            chat_room_id: item.chat_room_id,
                          });
                          // callChatUnreadEmit(item.chat_room_id);
                          // onlineFriendList({ reset: ((item?.unread_count>0) ? true : null), chat_room_id: item.chat_room_id });
                          onlineFriendList({
                            reset: false,
                            chat_room_id: item.chat_room_id,
                            action: "sub",
                          });
                        }}
                        className="chat-bubble row d-flex align-items-center"
                      >
                        <img
                          alt={`Profile image of ${
                            item?.user_details?.display_user_name != ""
                              ? item?.user_details?.display_user_name
                              : item?.user_details?.username
                          }`}
                          src={
                            item?.user_details?.user_profile_full_path != null
                              ? `${item?.user_details?.user_profile_full_path}`
                              : `${env.PRO_IMG}pro-img.png`
                          }
                        />
                        <div className="text-left">
                          <p className="clear-style">
                            <TextToSpeech
                              text={`${
                                item?.user_details?.display_user_name != ""
                                  ? item?.user_details?.display_user_name
                                  : item?.user_details?.username
                              }`}
                            />
                            <br />
                            <span className="size-12">
                              <TextToSpeech
                                text={
                                  item?.last_message_details
                                    ? timeConvert(
                                        item?.last_message_details?.created_at
                                      )
                                    : null
                                }
                              />
                            </span>
                          </p>
                        </div>
                        {/* {chatroomuser?.user_details?.display_user_name != "" ? chatroomuser?.user_details?.display_user_name : chatroomuser?.user_details?.username} */}
                        <p className="mb-0 w-50 text-right ">
                          {item?.unread_count < 0 ||
                          item?.unread_count == "0" ? (
                            <></>
                          ) : (
                            <>
                              <span
                                className="bg-danger text-white m-1"
                                style={{
                                  display: "inline-block",
                                  width: "20px",
                                  height: "20px",
                                  "border-radius": "50%",
                                  "text-align": "center",
                                  "line-height": "20px",
                                  "font-size": "10px",
                                }}
                              >
                                <TextToSpeech text={item?.unread_count} />
                              </span>
                            </>
                          )}

                          {item?.user_details?.chat_status === "online" ? (
                            <>
                              <span
                                className="bg-success"
                                aria-label="Online"
                                style={{
                                  display: "inline-block",
                                  width: "10px",
                                  height: "10px",
                                  "border-radius": "50%",
                                  "text-align": "center",
                                  "line-height": "20px",
                                  "font-size": "8px",
                                  position: "relative",
                                  top: "2px",
                                }}
                              ></span>
                            </>
                          ) : (
                            <>
                              <span
                                className="bg-dark"
                                aria-label="Offline"
                                style={{
                                  display: "inline-block",
                                  width: "10px",
                                  height: "10px",
                                  "border-radius": "50%",
                                  "text-align": "center",
                                  "line-height": "20px",
                                  "font-size": "8px",
                                  position: "relative",
                                  top: "2px",
                                }}
                              ></span>
                            </>
                          )}
                        </p>
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ))}
            </div>
          </div>

          {chatroomuser && (
            <div className="chatbox">
              <div className="chatbox-top ">
                <div className="chatbox-avatar">
                  <img
                    alt={
                      chatroomuser?.user_details?.display_user_name != ""
                        ? chatroomuser?.user_details?.display_user_name +
                          "Avatar"
                        : chatroomuser?.user_details?.username + "Avatar"
                    }
                    src={
                      chatroomuser.user_details.user_profile_full_path != null
                        ? chatroomuser.user_details.user_profile_full_path
                        : "assets/web/pro-img.png"
                    }
                  />
                </div>
                <div className="chat-partner-name">
                  <span className="status donot-disturb"></span>
                  <TextToSpeech
                    text={
                      chatroomuser?.user_details?.display_user_name != ""
                        ? chatroomuser?.user_details?.display_user_name
                        : chatroomuser?.user_details?.username
                    }
                  />
                </div>
                <div className="chatbox-icons">
                  <a
                    href="#"
                    title="Hide Chat Box"
                    role="button"
                    onClick={() => hideChatbox()}
                  >
                    <i className="fa fa-minus"></i>
                  </a>
                  <a
                    href="#"
                    title="Remove Chat Box"
                    role="button"
                    onClick={() => {
                      localStorage.removeItem("chatRoom");
                      setChatRoomUser();
                      setMessage("");
                      setCurrentChatList("");
                      setPrevHeight(0);
                    }}
                  >
                    <i className="fa fa-times"></i>
                  </a>
                </div>
              </div>
              <div
                className="chat-messages"
                id="chatMessages"
                onScroll={() => onScroll(chatroomuser.chat_room_id)}
                ref={listInnerRef}
              >
                {messagedata.map((item, i) => {
                  return Number(item.sender_id) ===
                    Number(userdata?.user_id) ? (
                    <div className="message-box-holder" key={i}>
                      <div className="message-box">
                        <p>
                          <TextToSpeech text={item?.message} />
                        </p>
                      </div>
                      <br />
                      <p style={{ "font-size": "10px" }}>
                        <TextToSpeech text={timeConvert(item?.created_at)} />
                      </p>
                    </div>
                  ) : (
                    <div className="message-box-holder" key={i}>
                      <div className="message-sender">
                        <TextToSpeech
                          text={
                            chatroomuser?.user_details?.display_user_name != ""
                              ? chatroomuser?.user_details?.display_user_name
                              : chatroomuser?.user_details?.username
                          }
                        />
                      </div>
                      <div className="d-flex w-100">
                        <div className="message-box message-partner">
                          <p>
                            <TextToSpeech text={item?.message} />
                          </p>
                        </div>
                        {userdata.user_role === "student" ? (
                          <a
                            className="ml-1  mr-1"
                            title={"Report message: " + item?.message}
                            role="button"
                            onClick={() => {
                              setSelectedChat({
                                id: item.id,
                                message: item.message,
                              });
                              selectedChatId = item.id;
                            }}
                            style={{ height: "fit-content" }}
                            href="#"
                            data-target="#ReportMessage_Modal_1"
                            data-toggle="modal"
                          >
                            {" "}
                            <img
                              alt={"Report message: " + item?.message}
                              style={{ width: 20, height: 20, color: "red" }}
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/home/course/report.svg"
                              }
                            />
                          </a>
                        ) : (
                          <></>
                        )}
                      </div>
                      <p
                        className="w-100 text-left"
                        style={{ "font-size": "10px" }}
                      >
                        <TextToSpeech text={timeConvert(item?.updated_at)} />
                      </p>
                    </div>
                  );
                })}
                <div
                  dangerouslySetInnerHTML={{ __html: currentChatList }}
                  style={{ width: "100%" }}
                ></div>
              </div>

              {showEmojiPicker && (
                <div className="EmojiPicker__Wrapper">
                  <Picker
                    onEmojiClick={onEmojiClick}
                    previewConfig={{ defaultCaption: "", showPreview: false }}
                  />
                </div>
              )}
              <div className="chat-input-holder">
                <textarea
                  ref={textareaRef}
                  rows="1"
                  className="chat-input"
                  id="message"
                  // onkeypress={e => {
                  // if (e.key === 'Enter') {
                  //     setShowEmojiPicker(false)
                  //     sendMessage()
                  // }
                  // }
                  // }
                  value={message}
                  onChange={(e) => checkMessageCount(e, e.target.value)}
                  onKeyDown={geeks}
                  aria-describedby="Chat Input Box"
                ></textarea>
                {/* <input type="submit" value="Send" className="message-send" /> */}
                <button
                  className="message-send mr-1 pr-1 pl-1"
                  onClick={toggleEmojiPicker}
                >
                  <img
                    alt="Select Emoji"
                    style={{ width: 20, height: 20 }}
                    src={process.env.PUBLIC_URL + "/" + "assets/web/emoji.png"}
                  />
                </button>
                <button
                  className="message-send"
                  type="button"
                  onClick={() => {
                    setShowEmojiPicker(false);
                    sendMessage();
                  }}
                >
                  <TextToSpeech text={t("send reply")} />
                </button>
              </div>
            </div>
          )}

          {userdata.user_role === "student" && (
            <div
              className="dialog-container"
              role="dialog"
              aria-hidden="true"
              id="claire-bot"
              ref={claireRef}
              onKeyDown={(e) => {
                if (e.key === "Escape") onCLickClariaBotClose(e);
              }}
            >
              <div
                id="fee1"
                className="dialog-content text-left"
                style={{ minHeight: "auto" }}
                role="document"
              >
                {showClaireBack ? (
                  <button
                    type="button"
                    aria-label="Claire bot back button"
                    className="m-back"
                    onClick={(e) => onCLickClariaBotBack(e)}
                    style={{ right: showClaireClose ? "36px" : "-10px" }}
                  ></button>
                ) : (
                  <></>
                )}
                {showClaireClose ? (
                  <button
                    data-a11y-dialog-hide
                    type="button"
                    aria-label="Claire bot close button"
                    className="m-close"
                    onClick={(e) => onCLickClariaBotClose(e)}
                  ></button>
                ) : (
                  <></>
                )}
                <h3>
                  <TextToSpeech
                    text={t(
                      boatQuestionList.option_heading_text === null
                        ? boatQuestionList.display_text
                        : boatQuestionList.option_heading_text
                    )}
                  />
                </h3>
                <div
                  className="feel-list"
                  onClick={() => {
                    setMouthMove(true);
                  }}
                >
                  {boatQuestionList.options &&
                    boatQuestionList.options.map((item, i) =>
                      item.video_link === "" ? (
                        <div key={i}>
                          <a
                            href="#"
                            title={`Claire bot choice: ${item.display_text}`}
                            onClick={() =>
                              getChatBoatQuestions(
                                item.parent_id,
                                item.id,
                                item
                              )
                            }
                            className="good my-2"
                          >
                            <TextToSpeech text={t(item.display_text)} />
                          </a>
                        </div>
                      ) : (
                        <div key={i}>
                          <a
                            href="#"
                            data-target="#boatVideoModal"
                            title={`Claire bot video choice: ${item.display_text}`}
                            onClick={() =>
                              setVideoDetails(
                                item.video_link,
                                boatQuestionList.display_text
                              )
                            }
                            data-toggle="modal"
                            className="good my-2"
                          >
                            <TextToSpeech text={t(item.display_text)} />
                          </a>
                        </div>
                      )
                    )}
                </div>
              </div>
              <img
                id="clairebot_img"
                alt="Claire bot"
                className="chatbot"
                src={`${
                  process.env.PUBLIC_URL + "/" + env.FEELINGS_PNG
                }claire_2.png`}
                style={{ width: "96px", height: "139px" }}
              />
            </div>
          )}

          <div
            id="ReportMessage_Modal_1"
            className="modal fade"
            role="dialog"
            tabindex="-1"
            aria-labelledby="ReportMessage_ModalLabel_1"
            aria-hidden="true"
          >
            <div
              role="document"
              className="modal-dialog modal-dialog-centered modal-sm modal-custom"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    data-dismiss="modal"
                  ></button>
                  <h4
                    id="ReportMessage_ModalLabel_1"
                    className="modal-title w-100"
                  >
                    <TextToSpeech text={t("Report")} />
                  </h4>
                </div>
                <div className="modal-body">
                  <div className="modal-text h-auto pb-0">
                    <div className="report-modal-section">
                      <div className="report-list request-list sendmessagemodal">
                        <form role="form" aria-label="Report this message">
                          <div className="form-group">
                            <div className="report-message-dropdown ">
                              <label
                                htmlFor="report_message_subject_1"
                                className="hidden"
                              >
                                <TextToSpeech
                                  text={t("Report choose options")}
                                />
                              </label>
                              <select
                                className="form-control reportMessage"
                                id="report_message_subject_1"
                                name="report"
                              >
                                <option value={0} role="option" disabled>
                                  <TextToSpeech text={t("Select Subject")} />
                                </option>
                                {report_subjectList.map((value, i) => (
                                  <option
                                    role="option"
                                    key={i}
                                    value={value.name}
                                  >
                                    <TextToSpeech text={value.name} />
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="form-group">
                            <textarea
                              aria-label="Report description input box"
                              id="reportDescription_1"
                              className="message report-input"
                              placeholder="Enter Description *"
                            ></textarea>
                          </div>
                          <div className="form-group mt-3">
                            <button
                              type="button"
                              aria-label="Send report button"
                              className="btn Delete ml-2"
                              data-dismiss="modal"
                              onClick={() => {
                                sendMessage_report_new();
                              }}
                            >
                              <TextToSpeech text={t("Send Report")} />
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            id="boatVideoModal"
            className="modal fade"
            role="dialog"
            tabindex="-1"
            aria-label="Claire Video"
            aria-hidden="true"
            data-backdrop="static"
            data-keyboard="false"
          >
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div
                  className="modal-header"
                  style={{ display: "block", "text-align": "center" }}
                >
                  <button
                    type="button"
                    aria-label="Close claire bot"
                    onClick={(e) => showClaireBotWinClose(e)}
                    className="close"
                    data-dismiss="modal"
                  ></button>
                  {botVideoMessage.length && (
                    <h4 id="boatVideoModalLabel" className="modal-title">
                      <TextToSpeech text={botVideoMessage} />
                    </h4>
                  )}
                </div>
                <div className="modal-body">
                  <div
                    className="row align-item-center justify-content-center my-5"
                    aria-label="Claire bot video"
                    dangerouslySetInnerHTML={{ __html: botVideoLink }}
                  ></div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn"
                    data-dismiss="modal"
                    aria-label="Claire bot video close"
                    onClick={(e) => showClaireBotWinClose(e)}
                  >
                    <TextToSpeech text={t("Close")} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default App;
