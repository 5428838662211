import React, { useEffect, useState } from "react";
import Home_Header from "../../inc/home-header/Home_Header";
import Modal from "../../inc/modal/Modal";
import Left_Panel from "../left-panel/Left_Panel";
import { fun_zone_list } from "../../../services/user_apis";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import $ from "jquery";
import { useTranslation } from "react-i18next";
import TextToSpeech from "../../../services/textToSpeech";
import { Tabs, TabList, Tab, TabPanel } from "react-aria-components";
import "./FunZone.css";

const Fun_Zone = ({ socketData }) => {
  const { t } = useTranslation();
  const [fun_zone, setFun_zone] = useState([]);
  const [type, setType] = useState("game");
  let navigate = useNavigate();
  let location = useLocation();
  // console.log("location  ====> ", location)
  useEffect(() => {
    if (location.state) {
      setType(location.state.key);
      $(".nav-link").removeClass("active");
      $("." + location.state.activeTab).addClass("active");
    }
    const fun_zone_list2 = async () => {
      try {
        const response = await fun_zone_list(
          location.state ? location.state.key : "game"
        );
        // console.log(response, "yash s te")
        if (response.code === 1) {
          setFun_zone(response.data);
        } else {
          setFun_zone([]);
        }
      } catch (e) {
        navigate("/");
      }
    };
    fun_zone_list2();
  }, []);

  const fun_zone_list1 = async (value) => {
    setType(value);
    const fun_zone_list_wrap = async () => {
      const data = await fun_zone_list(value);
      if (data.code === 1) {
        setFun_zone(data.data);
      } else {
        setFun_zone([]);
      }
    };
    fun_zone_list_wrap();
  };

  return (
    <React.Fragment>
      <div className="content">
        <div className="left-portion">
          <Left_Panel />
        </div>
        <div className="middle-portion">
          <Home_Header socketData={socketData} />
          <div id="funzone" className="container-fluid">
            <div className="row">
              <div className="col">
                <Tabs
                  selectedKey={type}
                  onSelectionChange={(key) => fun_zone_list1(key)}
                >
                  <TabList aria-label="Funzone activities">
                    <Tab id="game">
                        <TextToSpeech text={t("Games")} />
                    </Tab>
                    <Tab id="cartoon">
                        <TextToSpeech text={t("Cartoons")} />
                    </Tab>
                    <Tab id="well_being">
                        <TextToSpeech text={t("Wellbeing")} />
                    </Tab>
                    <Tab id="meme">
                        <TextToSpeech text={t("Memes")} />
                    </Tab>
                    <Tab id="video">
                        <TextToSpeech text={t("Videos")} />
                    </Tab>
                  </TabList>
                  <TabPanel id="game">
                    {type === "game" && (
                      <div className="row mt-3">
                        {fun_zone.length > 0 ? (
                          fun_zone.map((value, i) => (
                            <div
                              className={
                                type !== "meme"
                                  ? "col-md-6"
                                  : "col-md-4 justify-content-center"
                              }
                              key={i}
                              role="tabpanel"
                            >
                              <div className="game">
                                <div className="box">
                                  {type !== "meme" ? (
                                    <>
                                      <img
                                        alt={"Fun Zone Image"}
                                        src={
                                          value.fun_zone_cover_image_full_path
                                        }
                                      />
                                      <div className="text">
                                        <h6>
                                          <TextToSpeech
                                            text={t(value.fun_zone_title)}
                                          />
                                        </h6>
                                        <p>
                                          <TextToSpeech
                                            text={t(value.fun_zone_description)}
                                          />
                                        </p>
                                        <Link
                                          to="/funzone-details"
                                          state={{ funzonedata: value }}
                                          title={t("Play")}
                                          className="play web"
                                        >
                                          <TextToSpeech text={t("Play")} />
                                        </Link>
                                      </div>
                                    </>
                                  ) : (
                                    <div className="mx-auto">
                                      {/* <Link to="/funzone-details" state={{ funzonedata: value }}><h6 className="text"><center>{value.fun_zone_title}</center></h6></Link> */}
                                      <Link
                                        to="/funzone-details"
                                        title={t("Fun Zone Image")}
                                        state={{ funzonedata: value }}
                                      >
                                        <img
                                          alt={t("Fun Zone Image")}
                                          src={
                                            value.fun_zone_cover_image_full_path
                                          }
                                        />
                                      </Link>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="col-md-12">
                            <center>
                              <h4>
                                <TextToSpeech text={t("Coming Soon")} />
                              </h4>
                            </center>
                          </div>
                        )}
                      </div>
                    )}
                  </TabPanel>
                  <TabPanel id="cartoon">
                    {type === "cartoon" && (
                      <div className="row mt-3">
                        {fun_zone.length > 0 ? (
                          fun_zone.map((value, i) => (
                            <div
                              className={
                                type !== "meme"
                                  ? "col-md-6"
                                  : "col-md-4 justify-content-center"
                              }
                              key={i}
                              role="tabpanel"
                            >
                              <div className="game">
                                <div className="box">
                                  {type !== "meme" ? (
                                    <>
                                      <img
                                        alt={"Fun Zone Image"}
                                        src={
                                          value.fun_zone_cover_image_full_path
                                        }
                                      />
                                      <div className="text">
                                        <h6>
                                          <TextToSpeech
                                            text={t(value.fun_zone_title)}
                                          />
                                        </h6>
                                        <p>
                                          <TextToSpeech
                                            text={t(value.fun_zone_description)}
                                          />
                                        </p>
                                        <Link
                                          to="/funzone-details"
                                          state={{ funzonedata: value }}
                                          title={t("Play")}
                                          className="play web"
                                        >
                                          <TextToSpeech text={t("Play")} />
                                        </Link>
                                      </div>
                                    </>
                                  ) : (
                                    <div className="mx-auto">
                                      {/* <Link to="/funzone-details" state={{ funzonedata: value }}><h6 className="text"><center>{value.fun_zone_title}</center></h6></Link> */}
                                      <Link
                                        to="/funzone-details"
                                        title={t("Fun Zone Image")}
                                        state={{ funzonedata: value }}
                                      >
                                        <img
                                          alt={t("Fun Zone Image")}
                                          src={
                                            value.fun_zone_cover_image_full_path
                                          }
                                        />
                                      </Link>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="col-md-12">
                            <center>
                              <h4>
                                <TextToSpeech text={t("Coming Soon")} />
                              </h4>
                            </center>
                          </div>
                        )}
                      </div>
                    )}
                  </TabPanel>
                  <TabPanel id="well_being">
                    {type === "well_being" && (
                      <div className="row mt-3">
                        {fun_zone.length > 0 ? (
                          fun_zone.map((value, i) => (
                            <div
                              className={
                                type !== "meme"
                                  ? "col-md-6"
                                  : "col-md-4 justify-content-center"
                              }
                              key={i}
                              role="tabpanel"
                            >
                              <div className="game">
                                <div className="box">
                                  {type !== "meme" ? (
                                    <>
                                      <img
                                        alt={"Fun Zone Image"}
                                        src={
                                          value.fun_zone_cover_image_full_path
                                        }
                                      />
                                      <div className="text">
                                        <h6>
                                          <TextToSpeech
                                            text={t(value.fun_zone_title)}
                                          />
                                        </h6>
                                        <p>
                                          <TextToSpeech
                                            text={t(value.fun_zone_description)}
                                          />
                                        </p>
                                        <Link
                                          to="/funzone-details"
                                          state={{ funzonedata: value }}
                                          title={t("Play")}
                                          className="play web"
                                        >
                                          <TextToSpeech text={t("Play")} />
                                        </Link>
                                      </div>
                                    </>
                                  ) : (
                                    <div className="mx-auto">
                                      {/* <Link to="/funzone-details" state={{ funzonedata: value }}><h6 className="text"><center>{value.fun_zone_title}</center></h6></Link> */}
                                      <Link
                                        to="/funzone-details"
                                        title={t("Fun Zone Image")}
                                        state={{ funzonedata: value }}
                                      >
                                        <img
                                          alt={t("Fun Zone Image")}
                                          src={
                                            value.fun_zone_cover_image_full_path
                                          }
                                        />
                                      </Link>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="col-md-12">
                            <center>
                              <h4>
                                <TextToSpeech text={t("Coming Soon")} />
                              </h4>
                            </center>
                          </div>
                        )}
                      </div>
                    )}
                  </TabPanel>
                  <TabPanel id="meme">
                    {type === "meme" && (
                      <div className="row mt-3">
                        {fun_zone.length > 0 ? (
                          fun_zone.map((value, i) => (
                            <div
                              className={
                                type !== "meme"
                                  ? "col-md-6"
                                  : "col-md-4 justify-content-center"
                              }
                              key={i}
                              role="tabpanel"
                            >
                              <div className="game">
                                <div className="box">
                                  {type !== "meme" ? (
                                    <>
                                      <img
                                        alt={"Fun Zone Image"}
                                        src={
                                          value.fun_zone_cover_image_full_path
                                        }
                                      />
                                      <div className="text">
                                        <h6>
                                          <TextToSpeech
                                            text={t(value.fun_zone_title)}
                                          />
                                        </h6>
                                        <p>
                                          <TextToSpeech
                                            text={t(value.fun_zone_description)}
                                          />
                                        </p>
                                        <Link
                                          to="/funzone-details"
                                          state={{ funzonedata: value }}
                                          title={t("Play")}
                                          className="play web"
                                        >
                                          <TextToSpeech text={t("Play")} />
                                        </Link>
                                      </div>
                                    </>
                                  ) : (
                                    <div className="mx-auto">
                                      {/* <Link to="/funzone-details" state={{ funzonedata: value }}><h6 className="text"><center>{value.fun_zone_title}</center></h6></Link> */}
                                      <Link
                                        to="/funzone-details"
                                        title={t("Fun Zone Image")}
                                        state={{ funzonedata: value }}
                                      >
                                        <img
                                          alt={t("Fun Zone Image")}
                                          src={
                                            value.fun_zone_cover_image_full_path
                                          }
                                        />
                                      </Link>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="col-md-12">
                            <center>
                              <h4>
                                <TextToSpeech text={t("Coming Soon")} />
                              </h4>
                            </center>
                          </div>
                        )}
                      </div>
                    )}
                  </TabPanel>
                  <TabPanel id="video">
                    {type === "video" && (
                      <div className="row mt-3">
                        {fun_zone.length > 0 ? (
                          fun_zone.map((value, i) => (
                            <div
                              className={
                                type !== "meme"
                                  ? "col-md-6"
                                  : "col-md-4 justify-content-center"
                              }
                              key={i}
                              role="tabpanel"
                            >
                              <div className="game">
                                <div className="box">
                                  {type !== "meme" ? (
                                    <>
                                      <img
                                        alt={"Fun Zone Image"}
                                        src={
                                          value.fun_zone_cover_image_full_path
                                        }
                                      />
                                      <div className="text">
                                        <h6>
                                          <TextToSpeech
                                            text={t(value.fun_zone_title)}
                                          />
                                        </h6>
                                        <p>
                                          <TextToSpeech
                                            text={t(value.fun_zone_description)}
                                          />
                                        </p>
                                        <Link
                                          to="/funzone-details"
                                          state={{ funzonedata: value }}
                                          title={t("Play")}
                                          className="play web"
                                        >
                                          <TextToSpeech text={t("Play")} />
                                        </Link>
                                      </div>
                                    </>
                                  ) : (
                                    <div className="mx-auto">
                                      {/* <Link to="/funzone-details" state={{ funzonedata: value }}><h6 className="text"><center>{value.fun_zone_title}</center></h6></Link> */}
                                      <Link
                                        to="/funzone-details"
                                        title={t("Fun Zone Image")}
                                        state={{ funzonedata: value }}
                                      >
                                        <img
                                          alt={t("Fun Zone Image")}
                                          src={
                                            value.fun_zone_cover_image_full_path
                                          }
                                        />
                                      </Link>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="col-md-12">
                            <center>
                              <h4>
                                <TextToSpeech text={t("Coming Soon")} />
                              </h4>
                            </center>
                          </div>
                        )}
                      </div>
                    )}
                  </TabPanel>
                </Tabs>
              </div>
            </div>

            {/* <div className="row">
                <div className="col-md-12">
                  <ul role="tablist" className="nav nav-tabs funzone-tab">
                    <li role="presentation" className="nav-item">
                      {/* <a className="nav-link active" data-toggle="tab" href="#games" >Games</a> *}
                      {/* <Link toclassName="nav-link active" data-toggle="tab" onClick={fun_zone_list1("game")} >Games</Link> *}
                      <a
                        className="nav-link gameTab active"
                        href="#"
                        onClick={() => fun_zone_list1("game")}
                        data-toggle="tab"
                        value="game"
                      >
                        <TextToSpeech text={t("Games")} />
                      </a>
                    </li>
                    <li role="presentation" className="nav-item">
                      {/* <Link className="nav-link active" data-toggle="tab" onClick={fun_zone_list1("cartoon")} >Cartoon</Link> *}
                      <a
                        className="nav-link cartoonTab"
                        href="#"
                        onClick={() => fun_zone_list1("cartoon")}
                        data-toggle="tab"
                        value="cartoon"
                      >
                        <TextToSpeech text={t("Cartoons")} />
                      </a>
                    </li>
                    <li role="presentation" className="nav-item">
                      {/* <Link className="nav-link active" data-toggle="tab" onClick={fun_zone_list1("well_being")} >Well being</Link> *}
                      <a
                        className="nav-link wellBeingTab"
                        href="#"
                        onClick={() => fun_zone_list1("well_being")}
                        data-toggle="tab"
                        value="well_being"
                      >
                        <TextToSpeech text={t("Wellbeing")} />
                      </a>
                    </li>
                    <li role="presentation" className="nav-item">
                      {/* <Link className="nav-link" data-toggle="tab" value="meme">Memes</Link> *}
                      <a
                        className="nav-link memesTab"
                        href="#"
                        onClick={() => fun_zone_list1("meme")}
                        data-toggle="tab"
                        value="meme"
                      >
                        <TextToSpeech text={t("Memes")} />
                      </a>
                    </li>
                    <li role="presentation" className="nav-item">
                      {/* <Link className="nav-link" data-toggle="tab" value="video">videos</Link> *}
                      <a
                        className="nav-link videoTab"
                        href="#"
                        onClick={() => fun_zone_list1("video")}
                        data-toggle="tab"
                        value="video"
                      >
                        <TextToSpeech text={t("Videos")} />
                      </a>
                    </li>
                    {/* <li role="presentation" className="nav-item" onClick={() => fun_zone_list1("coding")}>
                                        <a className="nav-link codingTab" data-toggle="tab" value="coding"><TextToSpeech text={t('Coding')} /></a>
                                    </li> *}
                  </ul>
                  <div className="tab-content">
                    <div className="tab-pane active" id="games">
                      <div className="row mt-3">
                        {fun_zone.length > 0 ? (
                          fun_zone.map((value, i) => (
                            <div
                              className={
                                type !== "meme"
                                  ? "col-md-6"
                                  : "col-md-4 justify-content-center"
                              }
                              key={i}
                              role="tabpanel"
                            >
                              <div className="game">
                                <div className="box">
                                  {type !== "meme" ? (
                                    <>
                                      <img
                                        alt={"Fun Zone Image"}
                                        src={
                                          value.fun_zone_cover_image_full_path
                                        }
                                      />
                                      <div className="text">
                                        <h6>
                                          <TextToSpeech
                                            text={t(value.fun_zone_title)}
                                          />
                                        </h6>
                                        <p>
                                          <TextToSpeech
                                            text={t(value.fun_zone_description)}
                                          />
                                        </p>
                                        <Link
                                          to="/funzone-details"
                                          state={{ funzonedata: value }}
                                          title={t("Play")}
                                          className="play web"
                                        >
                                          <TextToSpeech text={t("Play")} />
                                        </Link>
                                      </div>
                                    </>
                                  ) : (
                                    <div className="mx-auto">
                                      {/* <Link to="/funzone-details" state={{ funzonedata: value }}><h6 className="text"><center>{value.fun_zone_title}</center></h6></Link> /}
                                      <Link
                                        to="/funzone-details"
                                        title={t("Fun Zone Image")}
                                        state={{ funzonedata: value }}
                                      >
                                        <img
                                          alt={t("Fun Zone Image")}
                                          src={
                                            value.fun_zone_cover_image_full_path
                                          }
                                        />
                                      </Link>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="col-md-12">
                            <center>
                              <h4>
                                <TextToSpeech text={t("Coming Soon")} />
                              </h4>
                            </center>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
          </div>
        </div>
      </div>
      <Modal />
    </React.Fragment>
  );
};

export default Fun_Zone;
