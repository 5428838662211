import React from 'react'
import { CircularProgress } from '@mui/material';
// import { Button } from '@material-ui/core';
import {Button} from '@mui/material';
import Home_Header from '../../inc/home-header/Home_Header'
import Modal from '../../inc/modal/Modal'
import Left_Panel from '../left-panel/Left_Panel'
import '../report/Report.css'
import { report_list, update_report } from '../../../services/user_apis';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2'
import $ from 'jquery'
import TextToSpeech from '../../../services/textToSpeech';

export default function ReportListing() {
    const { t } = useTranslation();
    const user = JSON.parse(localStorage.getItem('user'))
    const [ReportStatus, setReportStatus] = React.useState("");
    const [ReportList, setReportList] = React.useState([]);
    const [reportData, setreportData] = React.useState([]);
    const [limit, setLimit] = React.useState(10);
    const [circular, setCircular] = React.useState(true);

    const showMessage = (msg) => {
        Swal.fire({
            title: t('Cybersmarties'),
            text: t(msg),
            confirmButtonText: t('Okay')
        })
    }

    React.useEffect(() => {
        reportList()
    }, [limit])

    const reportList = async () => {
        const response = await report_list({ user_id: user.user_id, limit: limit })
        if (response.code === 1) {
            let filterReportData = response.data.filter((item) => item.report_status != "dismiss")
            setReportList(filterReportData)
            $("input[name='example1']:checked").prop('checked', false)
        }
        setCircular(false);
    }

    const updateReport = async (data, status) => {
        const post_body = {
            report_id: data.report_id,
            report_status: status,
            action_taken_by_user_id: user.user_id,
            action_taken_by: user.user_role,
            report_hours: document.getElementById("hoursSelection").value
        }
        if (status === "dismiss" || status === "unfreeze") {
            delete post_body.report_hours
        }

        const response = await update_report(post_body);
        if (response.code === 1) {
            reportList()
            showMessage(response.message)
        }
    }

    const filterReport = () => {
        let status = $("input[name='example1']:checked").val()
        // console.log(status)
        let filterReportData = ReportList.filter((item) => item.report_status === status)
        setReportList(filterReportData)
    }

    const updateLimit = () => {
        setCircular(true);
        setLimit(limit + 10)
    }

    const pagignation = () => {
        if (circular) {
            return <CircularProgress />
        }
        if (!circular) {
            if (ReportList.length < limit) {
                return <div className="no-more-activities"><TextToSpeech text={t('No more activities found.')} /></div>
            }
            return <Button variant="outlined" size="medium" aria-label={t("See More")} onClick={updateLimit}><TextToSpeech text={t('See More')} /></Button>
        }
    }

    return (
        <React.Fragment>
            <div className="content">
                <div className="left-portion">
                    <Left_Panel />
                </div>
                <div className="middle-portion">
                    <Home_Header />
                    <div className="container-fluid">
                        <section className="post-section pt-4">
                            <div className="container">
                                <div className="row ">
                                    <div className="col-lg-12">
                                        <div className="my-school" role="tabpanel" data-message={t("Report")}>
                                            <div className="report-filter text-right" data-target="#Filter_Modal" data-toggle="modal"><img src="assets/images/filter.png" alt={t("Filter")} /></div>
                                            <div className="students-pro">
                                                <img src="assets/images/home/course/report.svg" alt={t("Report")} />
                                                <h2><TextToSpeech text={t('Reports')} /></h2>
                                                <p><TextToSpeech text={t('This page lists all of the reports your users have sent in regarding inappropriate content, system abuse, spam, and so forth.')} /> </p>
                                            </div>
                                            <div className="school-section mt-3 " role="tablist">
                                                <div className="row pt-4 ">
                                                    {ReportList && ReportList.map((item, i) => (
                                                        <div className="col-md-6" key={i} role="presentation">
                                                            {/* {console.log('itmem', item)} */}
                                                            <div className="academic-box">
                                                                <div className="academic-header">
                                                                    <div className="academic-profile">
                                                                        <img alt={t("Avatar")} src={item?.to_avatar_full_path != "" ? item.to_avatar_full_path : "assets/images/s-3.png"} />
                                                                    </div>
                                                                    <div className="academic-title text-left">
                                                                        <div className="comment-right">
                                                                            <div className="more">
                                                                                <div className="more-option text-start">
                                                                                    { 
                                                                                    <div className="">
                                                                                        <a title={t('Delete Report')} href='javaScript:' onClick={() => {
                                                                                            setReportStatus("dismiss")
                                                                                            updateReport(item, "dismiss")
                                                                                        }}><TextToSpeech text={t('Delete Report')} /></a>
                                                                                    </div> }
                                                                                    <div className="">
                                                                                        <a title={t('Freeze Reported Student')} href='javaScript:' onClick={() => {
                                                                                            setreportData(item)
                                                                                            setReportStatus("freeze")
                                                                                        }} data-toggle="modal" data-target="#Freeze_Modal"><TextToSpeech text={t('Freeze Reported Student')} /></a>
                                                                                    </div>
                                                                                    <div className="">
                                                                                        <a title={t('Freeze Reporter')} href='javaScript:' onClick={() => {
                                                                                            setreportData(item)
                                                                                            setReportStatus("freezereporter")
                                                                                        }} data-toggle="modal" data-target="#Freeze_Modal" ><TextToSpeech text={t('Freeze Reporter')} /></a>
                                                                                    </div>
                                                                                    <div className="">
                                                                                        <a title={t('Unfreeze Student')} href='javaScript:' onClick={() => {
                                                                                            setReportStatus("unfreeze")
                                                                                            updateReport(item, "unfreeze")
                                                                                        }} ><TextToSpeech text={t('Unfreeze Student')} /></a>
                                                                                    </div>
                                                                                </div>
                                                                                <a href="javaScript:" className="circle-nav" title={t("Circle navigation")}><i className="fas fa-ellipsis-h"></i></a>
                                                                            </div>
                                                                        </div>
                                                                        <h3><TextToSpeech text={item?.to_display_user_name != "" ? item?.to_display_user_name : item?.to_username} /></h3>
                                                                        <span><TextToSpeech text={t(item.created_at)} /></span>
                                                                    </div>
                                                                </div>
                                                                <div className="academic-body text-left">
                                                                    {item?.reported_from_role == "monitoring_admin" ? 
                                                                    <>
                                                                    <label className="posted"><TextToSpeech text={`${t('Reported by')}: <span>${t('Monitoring Team')}</span>`} /></label>
                                                                    <br />
                                                                    <label className="posted"><TextToSpeech text={`${t('Reported Username')}: <span>${item.to_username}</span>`} /></label>
                                                                    </>
                                                                    :
                                                                    item?.reported_from_role == "monitoring_staff" ?
                                                                    <>
                                                                    <label className="posted"><TextToSpeech text={`${t('Reported by')}: <span>${t('Monitoring Staff')}</span>`} /></label>
                                                                    <br />
                                                                    <label className="posted"><TextToSpeech text={`${t('Reported Username')}: <span>${item.to_username}</span>`} /></label>
                                                                    </>
                                                                    :
                                                                    <>
                                                                    <label className="posted"><TextToSpeech text={`${t('Reported by')}: <span>${item?.from_display_user_name != "" ? item.from_display_user_name : item.from_username}</span>`} /></label>
                                                                    <br />
                                                                    <label className="posted"><TextToSpeech text={`${t("Reporter's Username")}: <span>${item.from_username}</span>`} /></label>
                                                                    <br />
                                                                    <label className="posted"><TextToSpeech text={`${t('Reported Username')}: <span>${item.to_username}</span>`} /></label>
                                                                    </>                                                                    
                                                                    }
                                                                    <br/>
                                                                    {item.receiver_username ? <><label className="posted"><TextToSpeech text={`${t('Receiver Username')}: <span>${item?.receiver_username}</span>`} /></label><br/></> : ''}

                                                                    {item.sender_username ? <><label className="posted"><TextToSpeech text={`${t('Sender Username')}: <span>${item?.sender_username}</span>`} /></label><br/></> : ''}
                                                                    
                                                                    {item.report_status ? <label className="posted"><TextToSpeech text={`${t('Report Status')}: <span>${t(item?.report_status)}</span>`} /></label> : <label><TextToSpeech text={`<label className="posted">${t('Report Status')}: <span>${t(item?.action_status)}</span>`} /></label>}
                                                                    <br/>
                                                                    <label className="posted"><TextToSpeech text={`${t('Reason for Reporting')}: <span>${item?.report_subject}</span>`} /></label>
                                                                    <br/>
                                                                    {item.report_description ? <><label className="posted"><TextToSpeech text={`${t('Description')}: <span>${item?.report_description}</span>`} /></label><br/></> : ''}

                                                                    <label className="posted"><TextToSpeech text={`${t('Type of data')}: <span>${t(item?.report_type)}</span>`} /></label>
                                                                    <br/>
                                                                    <label className="posted"><TextToSpeech text={`${t('Reported Content')} : <span>${item?.reported_content}</span>`} /></label>
                                                                    {item?.report_type=="comment"
                                                                    ?
                                                                    <>
                                                                    <br/>
                                                                    <label className="posted"><TextToSpeech text={`${t('Post')} : <span>${item?.reported_comment_for_post}</span>`} /></label>
                                                                    </>
                                                                    :
                                                                    <></>
                                                                    }
                                                                    <br/>
                                                                    <label className="posted"><TextToSpeech text={`${t('Created')}: <span>${t(item?.created_at)}</span>`} /></label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                <center>{pagignation()}</center>
                                                <br />
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

                <div className="modal fade" id="Filter_Modal" tabIndex="-1" role="dialog" aria-labelledby="Filter_ModalTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal" role="document">
                        <div className="modal-content post-modal">
                            <div className="modal-body post-modal" role="tabpanel" data-message={t("Form for report actions")}>
                                <div className="create-post">
                                    <div className="post-title">
                                        <h3><TextToSpeech text={t('Filter')} /></h3>
                                    </div>
                                    <div className="report-form" role="form">
                                        <div className="form-group">
                                          { /*  <div className="custom-control custom-radio">
                                                <input type="radio" className="custom-control-input" value={"dismiss"} id="dismiss" name="example1" />
                                                <label className="custom-control-label" for="dismiss">Dismiss</label>
                                            </div>
                                           */}
                                           <div className="custom-control custom-radio">
                                                <input type="radio" className="custom-control-input" value={t("unfreeze")} aria-label={t('Unfreeze')} id="unfreeze" name="example1" />
                                                <label className="custom-control-label" for="unfreeze"><TextToSpeech text={t('Unfreeze')} /></label>
                                            </div>
                                            <div className="custom-control custom-radio">
                                                <input type="radio" className="custom-control-input" value={t("freeze")} aria-label={t('Freeze')} id="freeze" name="example1" />
                                                <label className="custom-control-label" for="freeze"><TextToSpeech text={t('Freeze')} /></label>
                                            </div>
                                            <div className="custom-control custom-radio">
                                                <input type="radio" className="custom-control-input" value={t("freezereporter")} aria-label={t('Freeze Reporter')} id="freezereporter" name="example1" />
                                                <label className="custom-control-label" for="freezereporter"><TextToSpeech text={t('Freeze Reporter')} /></label>
                                            </div>
                                        </div>
                                        <div className="form-group text-center">
                                            <a href="javaScript:" className="cybersmart-btn btn-block w-100" title={t("Apply changes")} onClick={() => filterReport()} data-dismiss="modal"><TextToSpeech text={t('Apply')} /></a>
                                            <a href="javaScript:" className="cybersmart-btn btn-block bg-danger w-100" title={t('Reset report')} onClick={() => {
                                                $("input[name='example1']:checked").prop('checked', false)
                                                reportList()
                                            }} data-dismiss="modal"><TextToSpeech text={t('Reset')} /></a>
                                            <a href="javaScript:" className=" btn-block cybersmart-btn text-dark bg-light w-100" title={t('Cancel')} data-dismiss="modal"><TextToSpeech text={t('Cancel')} /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="Freeze_Modal" tabIndex="-1" role="dialog" aria-labelledby="Freeze_ModalTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal" role="document">
                        <div className="modal-content post-modal">
                            <div className="modal-body post-modal">
                                <div className="create-post" role="form">
                                    <div className="post-title">
                                        <h3><TextToSpeech text={t('Unfreeze Time')} /></h3>
                                        <span><TextToSpeech text={t('Select unfreeze time below.')} /></span>
                                    </div>
                                    <div className="post-form report-form">

                                        <div className="form-group">
                                            <label for={t("Select Hours")} className="hidden">{t("Select Hours")}</label>
                                            <select className="form-control text-left" id='hoursSelection'>
                                                <option><TextToSpeech text={t('Select Hours')} /></option>
                                                {[...Array(9).keys()].slice(1).map((hourSelection, i) => {
                                                    return <option value={hourSelection} key={i}><TextToSpeech text={`${t(hourSelection)} ${t('hrs')}`} /></option>
                                                })}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <a href="javascript:" onClick={() => updateReport(reportData, ReportStatus)} className="cybersmart-btn btn-block" data-dismiss="modal" role="button" title={t('Next')}><TextToSpeech text={t('Next')} /></a>
                                            <a href="#" className="mt-3 text-white d-block text-center" data-dismiss="modal" title={t('Cancel')} role="button"><TextToSpeech text={t('Cancel')} /></a>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </React.Fragment>
    )
}
