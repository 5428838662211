import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import env from "react-dotenv";
import { Link, useNavigate } from "react-router-dom";
import Left_Panel_Modal from "./modal/Left_Panel_Modal";
import {
  avatar_list,
  update_user_detail,
  add_post,
  get_general_notifications,
} from "../../../services/user_apis";
import $ from "jquery";
import { useTranslation } from "react-i18next";
import TextToSpeech from "../../../services/textToSpeech";

const Left_Panel = () => {
  const { t } = useTranslation();

  const [show, setShow] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [userData, setUserData] = useState();
  const [generalNotificationData, setGeneralNotificationData] = React.useState(
    []
  );
  const [avatarData, setAvatarData] = React.useState([]);

  React.useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("user")));
    //avatarList()
    generalNotificationList();
    const interval = setInterval(function () {
      $(".jsBlink").toggle();
    }, 1000);
    setTimeout(() => {
      clearInterval(interval);
      $(".jsBlink").show();
    }, 4000);
  }, []);
  const onClickClariaBot = (event) => {
    $(".clarie").addClass("clarie-active");
    $(".clarie > #fee1").addClass("feeling-active");
    $(".chat-with-friend").removeClass("chat-with-desk-friend-active");
    $(".chat-desk-icon").hide();
    $(".chatbox").hide();
    // event.stopImmediatePropagation();
  };

  const speak = () => {
    let speak = localStorage.getItem("speak");
    return speak === "true" ? true : false;
  };

  const setSpeaker = () => {
    let speak = localStorage.getItem("speak");
    if (speak === "true") {
      localStorage.setItem("speak", false);
    } else {
      localStorage.setItem("speak", true);
    }
  };

  const avatarList = async () => {
    let post_body = {
      type: user.user_role,
    };
    const response = await avatar_list(post_body);
    if (response.code === 1) {
      setAvatarData(response.data);
    }
  };

  const generalNotificationList = async () => {
    let post_body = {
      user_role: user.user_role,
      classes: user.classes,
    };
    const response = await get_general_notifications(post_body);
    if (response.code === 1) {
      setGeneralNotificationData(response.data);
    }
  };

  const updateUserProfile = async (avatar_id) => {
    const post_body = {
      avatar_id: avatar_id,
    };

    const response = await update_user_detail(post_body);
    if (response.code === 1) {
      // console.log("update user data ", response)
      setUserData(response.data);
      localStorage.setItem("user", JSON.stringify(response.data));
      // const postData = {
      //     class_id: response.data.class_id,
      //     division_id: response.data.division_id,
      //     permission: response.data.user_role,
      //     post_category: 'avatar',
      //     post_description: `<img src=${response.data.avatar_full_path} alt=${response.data.display_user_name} />`,
      //     school_id: response.data.school_id,
      // }
      // await add_post(postData);
      // isUpdate(true)
      navigate(0);
    }
  };

  const loadAvatar = () => {
    avatarList();
  };

  return (
    <React.Fragment>
      <div className="left-panel text-left">
        <div className="logo-main">
          <a href="../home">
            <img
              alt={t("Cybersmarties Logo")}
              src={process.env.PUBLIC_URL + "/assets/images/CSLogo.png"}
            />
          </a>
        </div>
        <div className="profile">
          <div className="p-image">
            {/* <img src={`${env.PRO_IMG}pro-img.png`} /> */}
            <img
              alt={`${
                user?.display_user_name !== ""
                  ? user?.display_user_name
                  : user.username
              } ${t("Profile image")}}`}
              src={userData && user.avatar_full_path}
            />
          </div>
          <div className="p-title" role="tabpanel">
            <p>
              <TextToSpeech
                text={
                  user?.display_user_name !== ""
                    ? user?.display_user_name
                    : user.username
                }
              />
            </p>
            <p>
              <Link to="/my-profile">
                <TextToSpeech text={t("Go to Profile")} />
              </Link>
            </p>
          </div>
        </div>
        <div className="nav1">
          <ul role="tablist">
            <li role="presentation">
              <Link className="active" to="/home">
                <img
                  alt=""
                  src={`${
                    process.env.PUBLIC_URL + "/" + env.HOME_RUN_PNG
                  }home-run.png`}
                />{" "}
                <TextToSpeech text={t("Home")} />
              </Link>
            </li>
            <li role="presentation">
              <Link to="/school-profile">
                <img
                  alt=""
                  src={`${
                    process.env.PUBLIC_URL + "/" + env.SCHOOL_PNG
                  }school.png`}
                />{" "}
                <TextToSpeech text={t("My School")} />
              </Link>
            </li>
            <li role="presentation">
              <Link
                to={
                  user.user_role === "student"
                    ? "/my-class"
                    : "/my-class-teacher"
                }
              >
                <img
                  alt=""
                  src={`${
                    process.env.PUBLIC_URL + "/" + env.CLASS_PNG
                  }class.png`}
                />{" "}
                <TextToSpeech text={t("My Class")} />
              </Link>
            </li>
            {user.user_role === "teacher" && (
              <li role="presentation">
                <Link to="/tutorial">
                  <img
                    alt=""
                    src={`${
                      process.env.PUBLIC_URL + "/" + env.TEAM_PNG
                    }training.png`}
                  />{" "}
                  <TextToSpeech text={t("Training")} />
                </Link>
              </li>
            )}
            <li role="presentation">
              <Link
                to={
                  user.user_role === "student"
                    ? "/my-homework"
                    : "/my-homework-teacher"
                }
              >
                <img
                  alt=""
                  src={`${
                    process.env.PUBLIC_URL + "/" + env.HOMEWORK_PNG
                  }homework.png`}
                />{" "}
                <TextToSpeech text={t("Academic")} />
              </Link>
            </li>
            {user.user_role === "student" && (
              <li role="presentation">
                <Link to="/add-classmate">
                  <img
                    alt=""
                    src={`${
                      process.env.PUBLIC_URL + "/" + env.TEAM_PNG
                    }team-1.png`}
                  />{" "}
                  <TextToSpeech text={t("Add your Friend")} />
                </Link>
              </li>
            )}
            <li role="presentation">
              <Link onClick={() => setSpeaker()}>
                <img
                  alt=""
                  src={`${
                    process.env.PUBLIC_URL + "/" + env.HOMEWORK_PNG
                  }text-to-speech_${speak() ? "false" : "true"}.png`}
                />{" "}
                <TextToSpeech text={t("Text to Speech")} />
              </Link>
            </li>
          </ul>
        </div>
        <hr />
        <div className="nav1">
          <ul role="tablist">
            {/* {user.user_role === "student" && <li><a className="active" href="add-friend.php"><img src={`${process.env.PUBLIC_URL + "/" + env.MEMBER_PNG}member.png`} /> 40 Member</a></li>} */}
            {/* {user.user_role === "student" && <li><a href="#"><img src={`${process.env.PUBLIC_URL + "/" + env.MEMBERSHIP_PNG}membership.png`} /> 21 Friendships</a></li>} */}

            {user.user_role === "student" && (
              <li role="presentation">
                <Link
                  to="/funezone"
                  state={{ activeTab: "gameTab", key: "game" }}
                >
                  <img
                    alt=""
                    src={`${
                      process.env.PUBLIC_URL + "/" + env.PARTY_PNG
                    }party.png`}
                  />{" "}
                  <TextToSpeech text={t("Fun Zone")} />
                </Link>
                {generalNotificationData &&
                  generalNotificationData?.funzone?.total > 0 && (
                    <div class="new_notify jsBlink">
                      <p className="badge-text">
                        <TextToSpeech text={t("New")} />
                      </p>
                    </div>
                  )}
              </li>
            )}
            <li role="presentation">
              <a
                href="#"
                data-target="#Avtar_Modal"
                data-toggle="modal"
                onClick={loadAvatar}
              >
                <img
                  alt=""
                  src={`${
                    process.env.PUBLIC_URL + "/" + env.CHANGE_AVATAR_PNG
                  }chnage-avatar.png`}
                />{" "}
                <TextToSpeech text={t("Change Avatar")} />
              </a>
              {generalNotificationData &&
                generalNotificationData?.avatar?.total > 0 && (
                  <div className="new_notify jsBlink">
                    <p className="badge-text">
                      <TextToSpeech text={t("New")} />
                    </p>
                  </div>
                )}
            </li>
            {user.user_role === "teacher" && (
              <li role="presentation">
                <Link to="/reportAction">
                  <img
                    alt={t("Reports")}
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/home/course/report.svg"
                    }
                  />{" "}
                  <TextToSpeech text={t("Reports")} />
                </Link>
              </li>
            )}
            {user.user_role === "student" && (
              <li role="presentation">
                <a
                  href="javascript:"
                  onClick={onClickClariaBot}
                  className="chat-clarie"
                  type="button"
                  data-a11y-dialog-show="claire-bot"
                >
                  <img
                    alt=""
                    src={`${
                      process.env.PUBLIC_URL + "/" + env.CLARIE_SMALL_PNG
                    }claire_small.png`}
                  />{" "}
                  <TextToSpeech text={t("Chat with Claire")} />
                </a>
              </li>
            )}
          </ul>
        </div>
        <hr />
        <div className="nav1">
          <ul role="tablist">
            <li role="presentation">
              <Link to="/faq">
                <TextToSpeech text={t("FAQ")} />
              </Link>
            </li>
            <li role="presentation">
              <Link to="/about">
                <TextToSpeech text={t("About Us")} />
              </Link>
            </li>
            {/* <li role="presentation"><Link title={t('Contact Us')} to="/contact">{t('Contact Us')}</Link></li> */}
            <li role="presentation">
              <Link to="/terms-condition">
                <TextToSpeech text={t("Terms & Conditions")} />
              </Link>
            </li>
            <li role="presentation">
              <Link to="/Privacy">
                <TextToSpeech text={t("Privacy Policy")} />
              </Link>
            </li>
          </ul>
        </div>
        <div className="garda" data-message={t("Global partners")}>
          <div className="g-title">
            <p>
              <TextToSpeech text={t("Global partners of")} />:
            </p>
          </div>
          <div className="g-image">
            <img
              alt={t("End Violence Against Children")}
              src={`${
                process.env.PUBLIC_URL + "/" + env.BITMAP_PNG
              }bitmap-copy-2.png`}
            />
            <img
              alt={t("Unicef")}
              src={`${
                process.env.PUBLIC_URL + "/" + env.BITMAP_THREE_PNG
              }bitmap-copy-3.png`}
            />
          </div>
        </div>
      </div>
      <div className="mobile-nav">
        <nav className="navbar nav-bar fixed-bottom" role="tablist">
          <a className="navbar-link" href="../my-homework" role="presentation">
            <img
              alt=""
              src={`${
                process.env.PUBLIC_URL + "/" + env.HOMEWORK_PNG
              }homework.png`}
              width="30"
              height="30"
            />
            <div>
              <TextToSpeech text={t("Homework")} />
            </div>
          </a>
          <a className="navbar-link" href="../my-class" role="presentation">
            <img
              alt=""
              src={`${process.env.PUBLIC_URL + "/" + env.CLASS_PNG}class.png`}
              width="30"
              height="30"
            />
            <div>
              <TextToSpeech text={t("Myclass")} />{" "}
            </div>
          </a>
          <a className="navbar-link" href="../my-profile" role="presentation">
            <img
              alt=""
              src={userData && user.avatar_full_path}
              width="30"
              height="30"
            />
            <div>
              <TextToSpeech text={t("Myclass")} />{" "}
            </div>
          </a>
          <a
            className="navbar-link"
            href="../school-profile"
            role="presentation"
          >
            <img
              alt=""
              src={`${process.env.PUBLIC_URL + "/" + env.SCHOOL_PNG}school.png`}
              width="30"
              height="30"
            />
            <div>
              <TextToSpeech text={t("My School")} />{" "}
            </div>
          </a>
          <a className="navbar-link" href="../funezone" role="presentation">
            <img
              alt=""
              src={`${process.env.PUBLIC_URL + "/" + env.PARTY_PNG}party.png`}
              width="30"
              height="30"
            />
            <div>
              <TextToSpeech text={t("Fun Zone")} />{" "}
            </div>
          </a>
        </nav>
      </div>

      <div className="mobile-menu">
        <div className="mobile-logo">
          <a title={t("Cybersmarties")} href="../home" className="m-logo">
            <img alt={t("Cybersmarties")} src={`assets/images/CSLogo.png`} />
          </a>
          <a href="#" className="mb-close">
            <img alt="" src="assets/web/close.svg" />{" "}
            <TextToSpeech text={t("Close")} />
          </a>
        </div>
        <div className="nav1">
          <ul role="tablist">
            <li role="presentation">
              <a className="active" href="home.php">
                <img
                  alt=""
                  src={`${
                    process.env.PUBLIC_URL + "/" + env.HOME_RUN_PNG
                  }home-run.png`}
                />{" "}
                <TextToSpeech text={t("Home")} />
              </a>
            </li>
            <li role="presentation">
              <a href="school-profile.php">
                <img
                  alt=""
                  src={`${
                    process.env.PUBLIC_URL + "/" + env.SCHOOL_PNG
                  }school.png`}
                />{" "}
                <TextToSpeech text={t("My School")} />
              </a>
            </li>
            <li role="presentation">
              <a href="my-className.php">
                <img
                  alt=""
                  src={`${
                    process.env.PUBLIC_URL + "/" + env.CLASS_PNG
                  }className.png`}
                />{" "}
                <TextToSpeech text={t("My Class")} />
              </a>
            </li>
            <li role="presentation">
              <a href="#">
                <img
                  alt=""
                  src={`${
                    process.env.PUBLIC_URL + "/" + env.HOMEWORK_PNG
                  }homework.png`}
                />{" "}
                <TextToSpeech text={t("Academic")} />
              </a>
            </li>
            <li role="presentation">
              <a href="add-classmate.php">
                <img
                  alt=""
                  src={`${
                    process.env.PUBLIC_URL + "/" + env.TEAM_PNG
                  }team-1.png`}
                />{" "}
                <TextToSpeech text={t("Add your Friend")} />
              </a>
            </li>
          </ul>
        </div>
        <hr />
        <div className="nav1">
          <ul role="tablist">
            {user.user_role === "teacher" && (
              <li role="presentation">
                <a className="active" href="add-friend.php">
                  <img
                    alt=""
                    src={`${
                      process.env.PUBLIC_URL + "/" + env.MEMBER_PNG
                    }member.png`}
                  />{" "}
                  <TextToSpeech text={t("40 Member")} />
                </a>
              </li>
            )}
            <li role="presentation">
              <a href="#">
                <img
                  alt=""
                  src={`${
                    process.env.PUBLIC_URL + "/" + env.MEMBERSHIP_PNG
                  }membership.png`}
                />{" "}
                <TextToSpeech text={t("21 Friendships")} />
              </a>
            </li>
            <li role="presentation">
              <a href="#">
                <img
                  alt=""
                  src={`${process.env.PUBLIC_URL + "/" + env.POST_PNG}post.png`}
                />{" "}
                <TextToSpeech text={t("14 Posts")} />
              </a>
            </li>
            <li role="presentation">
              <a href="#">
                <img
                  alt=""
                  src={`${
                    process.env.PUBLIC_URL + "/" + env.PHOTO_PNG
                  }photo.png`}
                />{" "}
                <TextToSpeech text={t("102 Photos")} />
              </a>
            </li>
            <li role="presentation">
              <a
                href="javascript:"
                onClick={onClickClariaBot}
                className="chat-clarie"
              >
                <img
                  alt=""
                  src={`${
                    process.env.PUBLIC_URL + "/" + env.CLARIE_PNG
                  }clarie.png`}
                />{" "}
                <TextToSpeech text={t("Chat with Claire")} />
              </a>
            </li>
          </ul>
        </div>
        <hr />
        <div className="nav1">
          <ul role="tablist">
            <li role="presentation">
              <Link
                to="/funezone"
                state={{ activeTab: "gameTab", key: "game" }}
              >
                <img
                  alt=""
                  src={`${
                    process.env.PUBLIC_URL + "/" + env.PARTY_PNG
                  }party.png`}
                />{" "}
                <TextToSpeech text={t("Fun Zone")} />
              </Link>
            </li>

            <li role="presentation">
              <a href="#" data-target="#ChangeAvtar_Modal" data-toggle="modal">
                <img
                  alt=""
                  src={`${
                    process.env.PUBLIC_URL + "/" + env.CHANGE_AVATAR_PNG
                  }chnage-avatar.png`}
                />{" "}
                <TextToSpeech text={t("Change Avatar")} />
              </a>
            </li>
          </ul>
        </div>
        <div className="garda">
          <div className="g-title">
            <p>
              <TextToSpeech
                text={t("In collaboration with  UNICEF-End Violence")}
              />
              :
            </p>
          </div>
          <div className="g-image">
            <img
              alt={t("End Violence Against Children")}
              src={`${
                process.env.PUBLIC_URL + "/" + env.BITMAP_PNG
              }bitmap-copy-2.png`}
            />
            <img
              alt={t("Unicef Logo")}
              src={`${
                process.env.PUBLIC_URL + "/" + env.BITMAP_THREE_PNG
              }bitmap-copy-3.png`}
            />
          </div>
        </div>
      </div>

      <div
        id="Avtar_Modal"
        className="modal fade"
        role="dialog"
        tabIndex="-1"
        aria-labelledby="Avtar_ModalLabel"
        aria-hidden="true"
        data-message={t("Choose a new avatar")}
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                aria-label="Close"
                data-dismiss="modal"
              ></button>
              <h4 id="Avtar_ModalLabel" className="modal-title w-100">
                <TextToSpeech text={t("Choose a new avatar")} />
              </h4>
            </div>
            <div className="modal-body">
              <div className="avtar-list">
                {avatarData &&
                  avatarData.map((item, index) => (
                    <div className="text-radio" key={index}>
                      <label for={t("Update Avatar")} className="hidden">
                        {t("Update Avatar")}
                      </label>
                      <input
                        id={`leftavtar${index}`}
                        value={`${item.avatar_id}`}
                        name="leftavtar"
                        type="radio"
                        data-dismiss="modal"
                        onChange={(e) => updateUserProfile(e.target.value)}
                      />
                      <label for={`leftavtar${index}`}>
                        <img
                          alt={t("Selected Avatar")}
                          src={item?.avatar_full_path}
                        />
                        <span>
                          <TextToSpeech text={t("Selected Avatar")} />
                        </span>
                      </label>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <script>
        document.getElementsByClassName('left-panel')[0].style.minHeight =
        window.innerHeight + 'px'
      </script>
    </React.Fragment>
  );
};

export default Left_Panel;
