import React, { useEffect } from "react";
import {
  get_user_details,
  update_user_detail,
  user_interest_list,
  update_user_interest_details,
  add_friend,
  add_lecture,
  add_report,
  avatar_list,
  update_division_detail,
  update_school_detail,
  notification_change_status,
} from "../../../services/user_apis";
import { CircularProgress } from "@mui/material";
import $ from "jquery";
import Swal from "sweetalert2";
import {
  uploadCoverImage,
  uploadCoverImageUser,
  uploadCoverSchoolImage,
} from "../../../services/imageUpload";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TextToSpeech from "../../../services/textToSpeech";

const showMessage = (msg) => {
  Swal.fire({
    title: "Cybersmarties",
    text: msg,
    confirmButtonText: "Okay",
  });
};

const Modal = ({
  changeAvatar,
  socketData,
  isUpdate,
  onPrimaryButtonClick,
  isAcceptRequest = false,
  friend_reason_list,
  send_friend_request_data,
  classDetails,
  report_type,
  report_data,
  boatVideoUrl,
  instructrionData,
  onlineClassdata,
  divisionDetails,
  schoolDetails,
  uploadImagetype,
  editProfile,
  reloadInterests,
}) => {
  const { t } = useTranslation();
  const [circular, setcircular] = React.useState(false);
  const [user, setUser] = React.useState([]);
  const [userName, setUserName] = React.useState("");
  const [aboutMe, setAboutMe] = React.useState("");
  const [sportList, setSportList] = React.useState("");
  const [musicList, setMusicList] = React.useState("");
  const [disable, setDisable] = React.useState(true);
  const [classTitle, setClassTitle] = React.useState("");
  const [instruction, setInstruction] = React.useState("");
  const [classLink, setClassLink] = React.useState("");
  const [classDate, setClassDate] = React.useState("");
  const [startTime, setStartTime] = React.useState("");
  const [endtime, setEndTime] = React.useState("");
  const [avatarData, setAvatarData] = React.useState([]);
  const [uploadFilePath, setUploadFilePath] = React.useState([]);
  const [coverimage, setCoverimage] = React.useState("");
  const navigate = useNavigate();

  let report_subjectList = [
    { id: 1, name: t("It was mean to me") },
    { id: 2, name: t("He/She was making fun of me") },
    { id: 3, name: t("It was spreading lies about me") },
    { id: 4, name: t("It said bad things about me") },
    { id: 5, name: t("It used bad language") },
    { id: 6, name: t("I didn't like the question being asked") },
    { id: 7, name: t("It made me feel uncomfortable") },
    { id: 8, name: t("It was looking for personal information") },
    { id: 9, name: t("Other") },
  ];

  React.useEffect(() => {
    if (editProfile) {
      userDeatils();
      //userSportInterestList()
      //userMusicInterestList()
      //avatarList()
      if (window.location.pathname.split("/").pop() == "my-profile") {
        userSportInterestList();
        userMusicInterestList();
      }
      $("#arrival_date").attr("min", new Date().toISOString().split("T")[0]);
    }
  }, [editProfile]);

  React.useEffect(() => {
    if (changeAvatar) avatarList();
  }, [changeAvatar]);

  const userDeatils = async () => {
    const response = await get_user_details();
    if (response.code === 1) {
      setUser(response.data);
      setUserName(response.data?.display_user_name);
      setAboutMe(response.data?.user_about_me);
    }
  };

  const updateUserDeatils = async () => {
    setcircular(true);
    let selectedInterest = [];
    let othersports = [];
    $('input[name="sport"]:checked').each(function (index, elem) {
      selectedInterest.push($(elem).val());
    });
    $('input[name="music"]:checked').each(function (index, elem) {
      selectedInterest.push($(elem).val());
    });
    $('input[name="othersports"]').each(function (index, elem) {
      $(elem).val() != "" &&
        (othersports = $(elem)
          .val()
          .split(",")
          .flatMap(
            (item) =>
              (item = {
                interest_name: item.trim(),
                interest_category: "sport",
              })
          ));
    });
    $('input[name="othersports"]').val("");

    const post_body = {
      display_user_name: userName,
      user_about_me: aboutMe,
    };
    const response = await update_user_detail(post_body);
    setcircular(false);
    // console.log("update profile data", response)
    if (response.code === 1) {
      setUser(response.data);
      localStorage.setItem("user", JSON.stringify(response.data));
    }

    const interest_body = {
      interest_list: selectedInterest,
      other_interest_list: othersports,
    };

    const interestResponse = await update_user_interest_details(interest_body);
    // console.log("update profile data", interestResponse)
    if (interestResponse.code === 1) {
      userSportInterestList();
      userMusicInterestList();
      isUpdate(true);
    }
    reloadInterests();
  };

  const userSportInterestList = async () => {
    const post_body = {
      interest_category: "sport",
    };
    const response = await user_interest_list(post_body);
    if (response.code === 1) {
      setSportList(response.data);
    }
  };
  const userMusicInterestList = async () => {
    const post_body = {
      interest_category: "music",
    };
    const response = await user_interest_list(post_body);
    if (response.code === 1) {
      setMusicList(response.data);
    }
  };

  const avatarList = async () => {
    let userdata = JSON.parse(localStorage.getItem("user"));
    let post_body = {
      type: userdata.user_role,
    };
    const response = await avatar_list(post_body);
    if (response.code === 1) {
      setAvatarData(response.data);
      // console.log("avtar listing api  =====> ", response)
    }
  };

  const changeCheckbox = (category, e, index) => {
    if (category === "music") {
      let musicdata = Object.assign([], musicList);
      let data = Object.assign({}, musicdata[index]);
      data["is_selected"] = data["is_selected"] === 0 ? 1 : 0;
      musicdata[index] = data;
      setMusicList(musicdata);
    } else {
      let sportData = Object.assign([], sportList);
      let data = Object.assign({}, sportData[index]);
      data["is_selected"] = data["is_selected"] === 0 ? 1 : 0;
      sportData[index] = data;
      setSportList(sportData);
    }
  };

  const addfriend = async () => {
    var friend_reason_id = $("input[name='friendReason']:checked").val();
    const post_body = {
      friend_to_user_id: send_friend_request_data.user_id,
      friend_reason_id: parseInt(friend_reason_id),
    };
    const response = await add_friend(post_body);
    $("input[name='friendReason']:checked").prop("checked", false);
    setDisable(true);
    if (response.code === 1) {
      isUpdate(true);
    } else {
      isUpdate(false);
    }
  };

  function convertDurationtoSeconds(duration) {
    const [hours, minutes] = duration.split(":");
    return Number(hours) * 60 * 60 + Number(minutes) * 60;
  }

  const handleCreateClass = async () => {
    let user = JSON.parse(localStorage.getItem("user"));
    let SECONDS =
      convertDurationtoSeconds(endtime) - convertDurationtoSeconds(startTime);
    let duration = new Date(SECONDS * 1000).toISOString().substring(11, 16);
    const post_body = {
      lecture_duration: duration,
      lecture_subject_name: classTitle,
      class_id: classDetails.class_id,
      division_id: classDetails.division_id,
      lecture_teacher_name:
        user?.display_user_name != "" ? user?.display_user_name : user.username,
      lecture_date: classDate,
      lecture_time: startTime,
      lecture_datetime: new Date(),
      lecture_link: classLink,
      lecture_instruction: instruction,
    };
    // console.log("send data  ==> ", post_body)
    const response = await add_lecture(post_body);
    // console.log("create online class   ===> ", response)
    if (response.code === 1) {
      isUpdate(true);
    }
  };

  const sendMessage_report = async () => {
    var report_subject = $("#report_message_subject").val();
    var report_description = $("#reportDescription").val();
    let post_body = {
      school_id: "",
      division_id: "",
      class_id: "",
      report_type: report_type,
      report_type_id:
        report_type === "post" ? report_data?.post_id : report_data?.comment_id,
      report_description: report_description ? report_description : "",
      report_subject: report_subject,
      report_to_user_id:
        report_type === "post" ? report_data?.user_id : report_data.to_user_id,
      report_from_user_id: user.user_id,
    };
    // if (report_description != "") {
    const response = await add_report(post_body);
    if (response.code === 1) {
      navigate(0);
      $("#reportDescription").value = "";
      isUpdate(true);
      Swal.fire({
        icon: "success",
        title: t("SuccessFull"),
        text: t(response.message),
      });
    }
    // } else {
    //     showMessage("Please Enter Description")
    // }
  };

  const updateUserProfile = async (avatar_id) => {
    const post_body = {
      avatar_id: avatar_id,
    };

    const response = await update_user_detail(post_body);
    if (response.code === 1) {
      // console.log("update user data ", response)
      setUser(response.data);
      localStorage.setItem("user", JSON.stringify(response.data));
      isUpdate(true);
    }
  };

  window.Buffer = window.Buffer || require("buffer").Buffer;

  const changeHandler = (event) => {
    let tmpFile = event.target.files[0];
    // console.log("event data  ====> ", event.target, "===== ", event.target.files[0])
    uploadCoverImage(
      tmpFile,
      uploadImagetype === "cover" ? "cover" : "logo",
      (callbackData) => {
        var fileType = "image";
        var tempData = { media: callbackData, file_type: fileType };
        setUploadFilePath(tempData);
      }
    );
  };

  const changeTeacherCover = (event) => {
    let tmpFile = event.target.files[0];
    // console.log("changeTeacherCover")
    // console.log(schoolDetails)
    if (typeof schoolDetails === "undefined") {
      uploadCoverImageUser(
        tmpFile,
        uploadImagetype === "cover" ? "cover" : "logo",
        (callbackData) => {
          var fileType = "image";
          var tempData = { media: callbackData, file_type: fileType };
          setUploadFilePath(tempData);
        }
      );
    } else {
      uploadCoverImage(
        tmpFile,
        uploadImagetype === "cover" ? "cover" : "logo",
        (callbackData) => {
          var fileType = "image";
          var tempData = { media: callbackData, file_type: fileType };
          setUploadFilePath(tempData);
        }
      );
    }
  };

  const changeSchoolCover = (event) => {
    let tmpFile = event.target.files[0];
    uploadCoverSchoolImage(
      tmpFile,
      uploadImagetype === "cover" ? "cover" : "logo",
      (callbackData) => {
        var fileType = "image";
        var tempData = { media: callbackData, file_type: fileType };
        setUploadFilePath(tempData);
      }
    );
  };

  const changeImage = async () => {
    let post_body = {
      division_id: divisionDetails.division_id,
      division_cover_image: uploadFilePath.media,
      division_logo_image: uploadFilePath.media,
    };
    if (uploadImagetype === "cover") {
      delete post_body.division_logo_image;
    } else {
      delete post_body.division_cover_image;
    }
    // console.log("upload post_body", post_body)
    const response = await update_division_detail(post_body);
    // console.log("update cover imafe =====> ", response)
    if (response.code === 1) {
      isUpdate(true);
    }
  };

  const teacherCoverImage = async () => {
    const post_body = {
      cover_image: uploadFilePath.media,
    };

    const response = await update_user_detail(post_body);
    // console.log(response);
    if (response.code === 1) {
      // console.log("update user data ", response)
      setUser(response.data);
      localStorage.setItem("user", JSON.stringify(response.data));
      isUpdate(true);
    }
  };

  const changeSchoolImage = async () => {
    let post_body = {
      school_id: schoolDetails.school_id,
      school_cover_image: uploadFilePath.media,
      school_logo_image: uploadFilePath.media,
    };
    if (uploadImagetype === "cover") {
      delete post_body.school_logo_image;
    } else {
      delete post_body.school_cover_image;
    }
    // console.log("upload post_body", post_body)
    const response = await update_school_detail(post_body);
    // console.log("update cover imafe =====> ", response)
    if (response.code === 1) {
      isUpdate(true);
    }
  };

  const studentCoverImage = async (coverimage) => {
    const post_body = {
      cover_image: coverimage,
    };

    const response = await update_user_detail(post_body);
    // console.log(response);
    if (response.code === 1) {
      // console.log("update user data ", response)
      setUser(response.data);
      localStorage.setItem("user", JSON.stringify(response.data));
      isUpdate(true);
    }
  };

  const displayUsername = send_friend_request_data?.display_user_name
    ? send_friend_request_data?.display_user_name
    : send_friend_request_data?.sender_detail?.display_user_name
    ? send_friend_request_data?.sender_detail?.display_user_name
    : send_friend_request_data?.username;
  const avatar =
    send_friend_request_data != undefined &&
    send_friend_request_data.avatar_full_path != null
      ? send_friend_request_data.avatar_full_path
      : send_friend_request_data?.sender_detail?.user_profile_full_path
      ? send_friend_request_data?.sender_detail?.user_profile_full_path
      : "assets/web/profile-1.png";

  const acceptFriendRequest = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    var friend_reason_id = $("input[name='friendReason']:checked").val();
    const post_body = {
      from_user_id: send_friend_request_data?.data?.from_user_id,
      friend_status: "accepted",
      notification_id: send_friend_request_data?.notification_id,
      school_id: user.school_id,
      friend_reason_id: parseInt(friend_reason_id),
    };

    const response = await notification_change_status(post_body);
    socketData({
      user_id: send_friend_request_data?.data?.from_user_id,
      user_role: "student",
    });
    $("input[name='friendReason']:checked").prop("checked", false);
    onPrimaryButtonClick();
  };
  return (
    <React.Fragment>
      {circular && <CircularProgress className="text-center" />}
      <div
        id="myModal"
        className="modal fade"
        role="dialog"
        tabIndex="-1"
        aria-label="Cheer Me Up"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              {/* <label id="myModalLabel" className="sr-only">
                {t("Cheer me up")}
              </label> */}
              <button
                type="button"
                role="button"
                onClick={() => isUpdate(false)}
                className="close"
                data-dismiss="modal"
              ></button>
              <h4 className="modal-title">
                <TextToSpeech
                  text={t("It’s normal to have an argument sometimes.")}
                />
                <TextToSpeech
                  text={t(
                    "Don’t let it get you down. Lets watch something to cheer you up."
                  )}
                />
              </h4>
            </div>
            <div className="modal-body">
              <div
                className="video-wraper"
                dangerouslySetInnerHTML={{ __html: boatVideoUrl }}
              />
            </div>
            <div className="modal-footer">
              {/* <label htmlFor={t("Close")} className="hidden">
                {t("Close")}
              </label> */}
              <button
                type="button"
                role="button"
                className="btn"
                data-dismiss="modal"
              >
                <TextToSpeech text={t("Close")} />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        id="ChangeAvtar_Modal"
        className="modal fade"
        role="dialog"
        tabIndex="-1"
        aria-labelledby="ChangeAvtar_ModalLabel"
        aria-hidden="true"
      >
        <div
          role="document"
          className="modal-dialog modal-dialog-centered modal-lg"
        >
          {/* {avatarList()} */}
          <div className="modal-content">
            <div className="modal-header">
              {/* <label htmlFor={t("Close")} className="hidden">
                {t("Close")}
              </label> */}
              <button
                type="button"
                role="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
              <h4 id="ChangeAvtar_ModalLabel" className="modal-title w-100">
                <TextToSpeech text={t("Choose a new avatar")} />
              </h4>
            </div>
            <div className="modal-body">
              <div className="avtar-list" role="tablist">
                {avatarData &&
                  avatarData.map((item, index) => (
                    <div
                      className="text-radio"
                      key={index}
                      tabIndex={index + 1}
                      role="tab"
                      aria-label={`${t("Choose avatar")} ${index + 1}`}
                    >
                      <input
                        id={`avtar${index}`}
                        value={`${item.avatar_id}`}
                        name="avtar"
                        type="radio"
                        data-dismiss="modal"
                        role="button"
                        onChange={(e) => updateUserProfile(e.target.value)}
                      />
                      <label htmlFor={`avtar${index}`}>
                        <img
                          alt={`${t("Choose avatar")} ${index + 1}`}
                          src={item?.avatar_full_path}
                        />
                        <span>
                          <TextToSpeech text={t("Selected Avatar")} />
                        </span>
                      </label>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="BGcover_Modal"
        className="modal fade"
        role="dialog"
        tabIndex="-1"
        aria-labelledby="BGcover_ModalLabel"
        aria-hidden="true"
      >
        <div
          role="document"
          className="modal-dialog modal-dialog-centered modal-lg"
        >
          <div className="modal-content">
            <div className="modal-header">
              {/* <label for={t("Upload new cover image")} className="hidden">
                {t("Upload new cover image")}
              </label> */}
              <button
                type="button"
                role="button"
                className="close"
                aria-label="Close"
                data-dismiss="modal"
              ></button>
              <h4 id="BGcover_ModalLabel" className="modal-title w-100">
                <TextToSpeech text={t("Upload new cover image")} />
              </h4>
            </div>
            <div className="modal-body">
              <div className="tab-content">
                <div className="tab-pane active" id="all">
                  {user.user_role === "teacher" ? (
                    <>
                      <div className="modal-feel">
                        <div className="form-group w-100">
                          <div className="upload-view-box">
                            <label className="upload-image">
                              <img
                                alt={t("Upload Image")}
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/add.png"
                                }
                                className="addimg"
                              />
                              <h5>
                                <img
                                  alt={t("Upload Image")}
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/u-img.png"
                                  }
                                  className="up"
                                />{" "}
                                <TextToSpeech text={t("Upload Image")} />
                              </h5>
                              <input
                                type="file"
                                name=""
                                id="coverImage"
                                onChange={changeTeacherCover}
                                accept="image/*"
                              />
                            </label>
                            <div className="upload-view">
                              {uploadFilePath.media}
                            </div>
                          </div>
                        </div>
                      </div>
                      <label htmlFor="upload" className="hidden">
                        {t("Update Teach Cover Image")}
                      </label>
                      <button
                        type="button"
                        role="button"
                        className="btn Send"
                        data-dismiss="modal"
                        onClick={() => teacherCoverImage()}
                      >
                        <TextToSpeech text={t("Update")} />
                      </button>
                    </>
                  ) : (
                    <></>
                  )}

                  {user.user_role === "student" ? (
                    <fieldset className="profilebg-list avtar-list">
                      <legend className="sr-only">
                        Upload new cover image
                      </legend>
                      <div className="text-radio">
                        <input
                          id="bg1_BGcover_Modal"
                          className="sr-only"
                          name="avtar"
                          type="radio"
                          onClick={() => studentCoverImage("cover1.png")}
                        />
                        <label htmlFor="bg1_BGcover_Modal">
                          <img alt="" src="assets/web/cover.png" />
                          <span>
                            <TextToSpeech text={`${t("Changed Cover")} 1`} />
                          </span>
                        </label>
                      </div>
                      <div className="text-radio">
                        <input
                          id="bg2_BGcover_Modal"
                          className="sr-only"
                          name="avtar"
                          type="radio"
                          onClick={() => studentCoverImage("cover2.png")}
                        />
                        <label htmlFor="bg2_BGcover_Modal">
                          <img alt="" src="assets/web/cover2.png" />
                          <span>
                            <TextToSpeech text={`${t("Changed Cover")} 2`} />
                          </span>
                        </label>
                      </div>
                      <div className="text-radio">
                        <input
                          id="bg3_BGcover_Modal"
                          className="sr-only"
                          name="avtar"
                          type="radio"
                          onClick={() => studentCoverImage("cover3.png")}
                        />
                        <label htmlFor="bg3_BGcover_Modal">
                          <img alt="" src="assets/web/cover3.png" />
                          <span>
                            <TextToSpeech text={`${t("Changed Cover")} 3`} />
                          </span>
                        </label>
                      </div>
                      <div className="text-radio">
                        <input
                          id="bg4_BGcover_Modal"
                          className="sr-only"
                          name="avtar"
                          type="radio"
                          onClick={() => studentCoverImage("cover4.png")}
                        />
                        <label htmlFor="bg4_BGcover_Modal">
                          <img alt="" src="assets/web/cover4.png" />
                          <span>
                            <TextToSpeech text={`${t("Changed Cover")} 4`} />
                          </span>
                        </label>
                      </div>
                      <div className="text-radio">
                        <input
                          id="bg5_BGcover_Modal"
                          className="sr-only"
                          name="avtar"
                          type="radio"
                          onClick={() => studentCoverImage("cover5.png")}
                        />
                        <label htmlFor="bg5_BGcover_Modal">
                          <img alt="" src="assets/web/cover5.png" />
                          <span>
                            <TextToSpeech text={`${t("Changed Cover")} 5`} />
                          </span>
                        </label>
                      </div>
                      <div className="text-radio">
                        <input
                          id="bg6_BGcover_Modal"
                          className="sr-only"
                          name="avtar"
                          type="radio"
                          onClick={() => studentCoverImage("cover6.png")}
                        />
                        <label htmlFor="bg6_BGcover_Modal">
                          <img alt="" src="assets/web/cover6.png" />
                          <span>
                            <TextToSpeech text={`${t("Changed Cover")} 6`} />
                          </span>
                        </label>
                      </div>
                      <div className="text-radio">
                        <input
                          id="bg7_BGcover_Modal"
                          className="sr-only"
                          name="avtar"
                          type="radio"
                          onClick={() => studentCoverImage("cover7.png")}
                        />
                        <label htmlFor="bg7_BGcover_Modal">
                          <img alt="" src="assets/web/cover7.png" />
                          <span>
                            <TextToSpeech text={`${t("Changed Cover")} 7`} />
                          </span>
                        </label>
                      </div>
                      <div className="text-radio">
                        <input
                          id="bg8_BGcover_Modal"
                          className="sr-only"
                          name="avtar"
                          type="radio"
                          onClick={() => studentCoverImage("cover8.png")}
                        />
                        <label htmlFor="bg8_BGcover_Modal">
                          <img alt="" src="assets/web/cover8.png" />
                          <span>
                            <TextToSpeech text={`${t("Changed Cover")} 8`} />
                          </span>
                        </label>
                      </div>
                      <div className="text-radio">
                        <input
                          id="bg9_BGcover_Modal"
                          className="sr-only"
                          name="avtar"
                          type="radio"
                          onClick={() => studentCoverImage("cover9.png")}
                        />
                        <label htmlFor="bg9_BGcover_Modal">
                          <img alt="" src="assets/web/cover9.png" />
                          <span>
                            <TextToSpeech text={`${t("Changed Cover")} 9`} />
                          </span>
                        </label>
                      </div>
                      <div className="text-radio">
                        <input
                          id="bg10_BGcover_Modal"
                          className="sr-only"
                          name="avtar"
                          type="radio"
                          onClick={() => studentCoverImage("cover10.png")}
                        />
                        <label htmlFor="bg10_BGcover_Modal">
                          <img alt="" src="assets/web/cover10.png" />
                          <span>
                            <TextToSpeech text={`${t("Changed Cover")} 10`} />
                          </span>
                        </label>
                      </div>
                      <div className="text-radio">
                        <input
                          id="bg11_BGcover_Modal"
                          className="sr-only"
                          name="avtar"
                          type="radio"
                          onClick={() => studentCoverImage("cover11.png")}
                        />
                        <label htmlFor="bg11_BGcover_Modal">
                          <img alt="" src="assets/web/cover11.png" />
                          <span>
                            <TextToSpeech text={`${t("Changed Cover")} 11`} />
                          </span>
                        </label>
                      </div>
                      <div className="text-radio">
                        <input
                          id="bg12_BGcover_Modal"
                          className="sr-only"
                          name="avtar"
                          type="radio"
                          onClick={() => studentCoverImage("cover12.png")}
                        />
                        <label htmlFor="bg12_BGcover_Modal">
                          <img alt="" src="assets/web/cover12.png" />
                          <span>
                            <TextToSpeech text={`${t("Changed Cover")} 12`} />
                          </span>
                        </label>
                      </div>
                      <div className="text-radio">
                        <input
                          id="bg13_BGcover_Modal"
                          className="sr-only"
                          name="avtar"
                          type="radio"
                          onClick={() => studentCoverImage("cover13.png")}
                        />
                        <label htmlFor="bg13_BGcover_Modal">
                          <img alt="" src="assets/web/cover13.png" />
                          <span>
                            <TextToSpeech text={`${t("Changed Cover")} 13`} />
                          </span>
                        </label>
                      </div>
                      <div className="text-radio">
                        <input
                          id="bg14_BGcover_Modal"
                          className="sr-only"
                          name="avtar"
                          type="radio"
                          onClick={() => studentCoverImage("cover14.png")}
                        />
                        <label htmlFor="bg14_BGcover_Modal">
                          <img alt="" src="assets/web/cover14.png" />
                          <span>
                            <TextToSpeech text={`${t("Changed Cover")} 14`} />
                          </span>
                        </label>
                      </div>
                      <div className="text-radio">
                        <input
                          id="bg15_BGcover_Modal"
                          className="sr-only"
                          name="avtar"
                          type="radio"
                          onClick={() => studentCoverImage("cover15.png")}
                        />
                        <label htmlFor="bg15_BGcover_Modal">
                          <img alt="" src="assets/web/cover15.png" />
                          <span>
                            <TextToSpeech text={`${t("Changed Cover")} 15`} />
                          </span>
                        </label>
                      </div>
                      <div className="text-radio">
                        <input
                          id="bg16_BGcover_Modal"
                          className="sr-only"
                          name="avtar"
                          type="radio"
                          onClick={() => studentCoverImage("cover16.png")}
                        />
                        <label htmlFor="bg16_BGcover_Modal">
                          <img alt="" src="assets/web/cover16.png" />
                          <span>
                            <TextToSpeech text={`${t("Changed Cover")} 16`} />
                          </span>
                        </label>
                      </div>
                      <div className="text-radio">
                        <input
                          id="bg17_BGcover_Modal"
                          className="sr-only"
                          name="avtar"
                          type="radio"
                          onClick={() => studentCoverImage("cover17.png")}
                        />
                        <label htmlFor="bg17_BGcover_Modal">
                          <img alt="" src="assets/web/cover17.png" />
                          <span>
                            <TextToSpeech text={`${t("Changed Cover")} 17`} />
                          </span>
                        </label>
                      </div>
                    </fieldset>
                  ) : (
                    <></>
                  )}
                </div>
                {/* <div className="tab-pane" id="nature">
                  <div className="profilebg-list avtar-list">
                    <div className="text-radio" tabIndex="1">
                      <input id="bgn1" name="avtar" type="radio" />
                      <label htmlFor="bgn1">
                        <img
                          alt={`${t("Selected Avatar")} 1`}
                          src="assets/web/cover.png"
                        />
                        <span>
                          <TextToSpeech text={`${t("Selected Avatar")} 1`} />
                        </span>
                      </label>
                    </div>
                    <div className="text-radio" tabIndex="2">
                      <input id="bgn2" name="avtar" type="radio" />
                      <label htmlFor="bgn2">
                        <img
                          alt={`${t("Selected Avatar")} 2`}
                          src="assets/web/cover2.png"
                        />
                        <span>
                          <TextToSpeech text={`${t("Selected Avatar")} 2`} />
                        </span>
                      </label>
                    </div>
                  </div>
                </div> */}
                {/* <div className="tab-pane" id="education">
                  <div className="profilebg-list avtar-list">
                    <div className="text-radio" tabIndex="3">
                      <input id="ebg1" name="avtar" type="radio" />
                      <label htmlFor="ebg1">
                        <img
                          alt={`${t("Selected Avatar")} 3`}
                          src="assets/web/cover.png"
                        />
                        <span>
                          <TextToSpeech text={`${t("Selected Avatar")} 3`} />
                        </span>
                      </label>
                    </div>
                  </div>
                </div> */}
                {/* <div className="tab-pane" id="illustration">
                  <div className="profilebg-list avtar-list">
                    <div className="text-radio" tabIndex="4">
                      <input id="ibg1" name="avtar" type="radio" />
                      <label htmlFor="ibg1">
                        <img
                          alt={`${t("Selected Avatar")} 4`}
                          src="assets/web/cover.png"
                        />
                        <span>
                          <TextToSpeech text={`${t("Selected Avatar")} 4`} />
                        </span>
                      </label>
                    </div>
                    <div className="text-radio" tabIndex="5">
                      <input id="ibg2" name="avtar" type="radio" />
                      <label htmlFor="ibg2">
                        <img
                          alt={`${t("Selected Avatar")} 5`}
                          src="assets/web/cover2.png"
                        />
                        <span>
                          <TextToSpeech text={`${t("Selected Avatar")} 5`} />
                        </span>
                      </label>
                    </div>
                    <div className="text-radio" tabIndex="6">
                      <input id="ibg3" name="avtar" type="radio" />
                      <label htmlFor="ibg3">
                        <img
                          alt={`${t("Selected Avatar")} 6`}
                          src="assets/web/cover3.png"
                        />
                        <span>
                          <TextToSpeech text={`${t("Selected Avatar")} 6`} />
                        </span>
                      </label>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="change_schoolCoverImage"
        className="modal fade"
        role="dialog"
        tabIndex="-1"
        aria-labelledby="schoolCoverImage_ModalLabel"
        aria-hidden="true"
      >
        <div
          role="document"
          className="modal-dialog modal-dialog-centered modal-lg"
        >
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
              <h4
                id="schoolCoverImage_ModalLabel"
                className="modal-title w-100"
              >
                <TextToSpeech text={t("Upload school new cover image")} />
              </h4>
            </div>
            <div className="modal-body">
              <div className="tab-content">
                <div className="tab-pane active" id="all">
                  {user.user_role === "teacher" ? (
                    <>
                      <div className="modal-feel">
                        <div className="form-group w-100">
                          <div
                            className="upload-view-box"
                            aria-label={t("Upload School Cover Image")}
                          >
                            <label className="upload-image">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/add.png"
                                }
                                className="addimg"
                              />
                              <h5>
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/u-img.png"
                                  }
                                  className="up"
                                />{" "}
                                <TextToSpeech text={t("Upload Image")} />
                              </h5>
                              <input
                                type="file"
                                name=""
                                id="coverImage"
                                onChange={changeSchoolCover}
                                accept="image/*"
                              />
                            </label>
                            <div className="upload-view">
                              {uploadFilePath.media}
                            </div>
                          </div>
                        </div>
                      </div>
                      <label htmlFor="updateImage" className="hidden">
                        {t("Upload School Cover Image")}
                      </label>
                      <button
                        aria-label={t("Upload School Cover Image")}
                        type="button"
                        className="btn Send"
                        role="button"
                        data-dismiss="modal"
                        onClick={() => changeSchoolImage()}
                      >
                        <TextToSpeech text={t("Update")} />
                      </button>
                    </>
                  ) : (
                    <></>
                  )}

                  {user.user_role === "student" ? (
                    <fieldset
                      className="profilebg-list avtar-list"
                      role="tablist"
                    >
                      <legend className="sr-only">
                        Upload new school cover image
                      </legend>
                      <div className="text-radio" role="presentation">
                        <input
                          id="bg1_schoolCoverImage_Modal"
                          className="sr-only"
                          name="avtar"
                          type="radio"
                          onClick={() => studentCoverImage("cover1.png")}
                        />
                        <label htmlFor="bg1_schoolCoverImage_Modal">
                          <img alt="" src="assets/web/cover.png" />
                          <span>
                            <TextToSpeech text={t("Changed Cover")} />
                          </span>
                        </label>
                      </div>
                      <div className="text-radio" role="presentation">
                        <input
                          id="bg2_schoolCoverImage_Modal"
                          className="sr-only"
                          name="avtar"
                          type="radio"
                          onClick={() => studentCoverImage("cover2.png")}
                        />
                        <label htmlFor="bg2_schoolCoverImage_Modal">
                          <img alt="" src="assets/web/cover2.png" />
                          <span>
                            <TextToSpeech text={t("Changed Cover")} />
                          </span>
                        </label>
                      </div>
                      <div className="text-radio" role="presentation">
                        <input
                          id="bg3_schoolCoverImage_Modal"
                          className="sr-only"
                          name="avtar"
                          type="radio"
                          onClick={() => studentCoverImage("cover3.png")}
                        />
                        <label htmlFor="bg3_schoolCoverImage_Modal">
                          <img alt="" src="assets/web/cover3.png" />
                          <span>
                            <TextToSpeech text={t("Changed Cover")} />
                          </span>
                        </label>
                      </div>
                    </fieldset>
                  ) : (
                    <></>
                  )}
                </div>
                {/* <div className="tab-pane" id="nature" role="tablist">
                  <div className="profilebg-list avtar-list">
                    <div className="text-radio" role="presentation">
                      <input
                        id="bgn1"
                        aria-label={t("Selected Avatar")}
                        
                        name="avtar"
                        type="radio"
                      />
                      <label htmlFor="bgn1">
                        <img
                          alt={t("Selected Avatar")}
                          src="assets/web/cover.png"
                        />
                        <span>
                          <TextToSpeech text={t("Selected Avatar")} />
                        </span>
                      </label>
                    </div>
                    <div className="text-radio" role="presentation">
                      <input
                        id="bgn2"
                        tabIndex="2"
                        aria-label={t("Selected Avatar")}
                        name="avtar"
                        type="radio"
                      />
                      <label htmlFor="bgn2">
                        <img
                          alt={t("Selected Avatar")}
                          src="assets/web/cover2.png"
                        />
                        <span>
                          <TextToSpeech text={t("Selected Avatar")} />
                        </span>
                      </label>
                    </div>
                  </div>
                </div> */}
                {/* <div className="tab-pane" id="education" role="tablist">
                  <div className="profilebg-list avtar-list">
                    <div className="text-radio" role="presentation">
                      <input
                        id="ebg1"
                        name="avtar"
                        type="radio"
                        aria-label={t("Selected Avatar")}
                      />
                      <label htmlFor="ebg1">
                        <img
                          alt={t("Selected Avatar")}
                          src="assets/web/cover.png"
                        />
                        <span>
                          <TextToSpeech text={t("Selected Avatar")} />
                        </span>
                      </label>
                    </div>
                  </div>
                </div> */}
                {/* <div className="tab-pane" id="illustration" role="tablist">
                  <div className="profilebg-list avtar-list">
                    <div className="text-radio" role="presentation">
                      <input
                        id="ibg1"
                        name="avtar"
                        type="radio"
                        aria-label={t("Selected Avatar")}
                      />
                      <label htmlFor="ibg1">
                        <img
                          alt={t("Selected Avatar")}
                          src="assets/web/cover.png"
                        />
                        <span>
                          <TextToSpeech text={t("Selected Avatar")} />
                        </span>
                      </label>
                    </div>
                    <div className="text-radio" role="presentation">
                      <input
                        id="ibg2"
                        name="avtar"
                        type="radio"
                        aria-label={t("Selected Avatar")}
                      />
                      <label htmlFor="ibg2">
                        <img
                          alt={t("Selected Avatar")}
                          src="assets/web/cover2.png"
                        />
                        <span>
                          <TextToSpeech text={t("Selected Avatar")} />
                        </span>
                      </label>
                    </div>
                    <div className="text-radio" role="presentation">
                      <input
                        id="ibg3"
                        name="avtar"
                        type="radio"
                        aria-label={t("Selected Avatar")}
                      />
                      <label htmlFor="ibg3">
                        <img
                          alt={t("Selected Avatar")}
                          src="assets/web/cover3.png"
                        />
                        <span>
                          <TextToSpeech text={t("Selected Avatar")} />
                        </span>
                      </label>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="change_schoolAvatarImage"
        className="modal fade"
        role="dialog"
        tabIndex="-1"
        aria-labelledby="change_schoolAvatarImage_ModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              {/* <label htmlFor={t("Close")} className="hidden">
                {t("Close")}
              </label> */}
              <button
                type="button"
                aria-label="Close"
                className="close"
                data-dismiss="modal"
              ></button>
              <h4
                id="change_schoolAvatarImage_ModalLabel"
                className="modal-title w-100"
              >
                <TextToSpeech text={t("Upload school logo image")} />
              </h4>
            </div>
            <div className="modal-body">
              {/* <div className="change-bg-tab">
                                <ul className="nav nav-tabs">
                                    <li><a href="#all" data-toggle="tab" className="active">All</a></li>
                                    <li><a href="#nature" data-toggle="tab" > Nature</a></li>
                                    <li><a href="#education" data-toggle="tab" > Education</a></li>
                                    <li><a href="#illustration" data-toggle="tab" > Illustrations</a></li>
                                </ul>
                            </div> */}
              <div className="tab-content">
                <div className="tab-pane active" id="all">
                  {user.user_role === "teacher" ? (
                    <>
                      <div className="modal-feel">
                        <div className="form-group w-100">
                          <div className="upload-view-box">
                            <label className="upload-image">
                              <img
                                alt={t("Change School Cover")}
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/add.png"
                                }
                                className="addimg"
                              />
                              <h5>
                                <img
                                  alt={t("Change School Cover")}
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/u-img.png"
                                  }
                                  className="up"
                                />{" "}
                                <TextToSpeech text={t("Upload Image")} />
                              </h5>
                              <input
                                type="file"
                                name=""
                                id="coverImage"
                                onChange={changeSchoolCover}
                                accept="image/*"
                              />
                            </label>
                            <div className="upload-view">
                              {uploadFilePath.media}
                            </div>
                          </div>
                        </div>
                      </div>
                      <label
                        htmlFor={t("ChangeSchoolCover")}
                        className="hidden"
                      >
                        {t("Update School Cover")}
                      </label>
                      <button
                        type="button"
                        className="btn Send"
                        data-dismiss="modal"
                        onClick={() => changeSchoolImage()}
                      >
                        <TextToSpeech text={t("Update")} />
                      </button>
                    </>
                  ) : (
                    <></>
                  )}

                  {user.user_role === "student" ? (
                    <fieldset className="profilebg-list avtar-list">
                      <legend className="sr-only">
                        Upload school logo image
                      </legend>
                      <div className="text-radio">
                        <input
                          id="bg1"
                          aria-label={t("Changed Cover")}
                          name="avtar"
                          type="radio"
                          onClick={() => studentCoverImage("cover1.png")}
                        />
                        <label htmlFor="bg1">
                          <img alt="" src="assets/web/cover.png" />
                          <span>
                            <TextToSpeech text={t("Changed Cover")} />
                          </span>
                        </label>
                      </div>
                      <div className="text-radio">
                        <input
                          id="bg2"
                          aria-label={t("Changed Cover")}
                          name="avtar"
                          type="radio"
                          onClick={() => studentCoverImage("cover2.png")}
                        />
                        <label htmlFor="bg2">
                          <img alt="" src="assets/web/cover2.png" />
                          <span>
                            <TextToSpeech text={t("Changed Cover")} />
                          </span>
                        </label>
                      </div>
                      <div className="text-radio">
                        <input
                          id="bg3"
                          aria-label={t("Changed Cover")}
                          name="avtar"
                          type="radio"
                          onClick={() => studentCoverImage("cover3.png")}
                        />
                        <label htmlFor="bg3">
                          <img alt="" src="assets/web/cover3.png" />
                          <span>
                            <TextToSpeech text={t("Changed Cover")} />
                          </span>
                        </label>
                      </div>
                    </fieldset>
                  ) : (
                    <></>
                  )}
                </div>
                {/* <div className="tab-pane" id="nature">
                  <fieldset className="profilebg-list avtar-list">
                    <div className="text-radio">
                      <input
                        id="bgn1"
                        name="avtar"
                        aria-label={t("Selected Avatar")}
                        type="radio"
                      />
                      <label htmlFor="bgn1">
                        <img
                          alt={t("Selected Avatar")}
                          src="assets/web/cover.png"
                        />
                        <span>
                          <TextToSpeech text={t("Selected Avatar")} />
                        </span>
                      </label>
                    </div>
                    <div className="text-radio">
                      <input
                        id="bgn2"
                        name="avtar"
                        aria-label={t("Selected Avatar")}
                        type="radio"
                      />
                      <label htmlFor="bgn2">
                        <img alt="" src="assets/web/cover2.png" />
                        <span>
                          <TextToSpeech text={t("Selected Avatar")} />
                        </span>
                      </label>
                    </div>
                  </fieldset>
                </div>
                <div className="tab-pane" id="education">
                  <div className="profilebg-list avtar-list">
                    <div className="text-radio">
                      <input
                        id="ebg1"
                        name="avtar"
                        aria-label={t("Selected Avatar")}
                        type="radio"
                      />
                      <label htmlFor="ebg1">
                        <img alt="" src="assets/web/cover.png" />
                        <span>
                          <TextToSpeech text={t("Selected Avatar")} />
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="tab-pane" id="illustration">
                  <div className="profilebg-list avtar-list">
                    <div className="text-radio">
                      <input
                        id="ibg1"
                        name="avtar"
                        aria-label={t("Selected Avatar")}
                        type="radio"
                      />
                      <label htmlFor="ibg1">
                        <img alt="" src="assets/web/cover.png" />
                        <span>
                          <TextToSpeech text={t("Selected Avatar")} />
                        </span>
                      </label>
                    </div>
                    <div className="text-radio">
                      <input
                        id="ibg2"
                        name="avtar"
                        aria-label={t("Selected Avatar")}
                        type="radio"
                      />
                      <label htmlFor="ibg2">
                        <img alt="" src="assets/web/cover2.png" />
                        <span>
                          <TextToSpeech text={t("Selected Avatar")} />
                        </span>
                      </label>
                    </div>
                    <div className="text-radio">
                      <input
                        id="ibg3"
                        name="avtar"
                        aria-label={t("Selected Avatar")}
                        type="radio"
                      />
                      <label htmlFor="ibg3">
                        <img alt="" src="assets/web/cover3.png" />
                        <span>
                          <TextToSpeech text={t("Selected Avatar")} />
                        </span>
                      </label>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="ADDdetails_Modal"
        className="modal fade"
        role="dialog"
        tabIndex="-1"
        aria-labelledby="ADDdetails_ModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-custom">
          <div className="modal-content">
            <div className="modal-header">
              {/* <label htmlFor={t("Close")} className="hidden">
                <TextToSpeech text={t("Close")} />
              </label> */}
              <button
                type="button"
                className="close"
                role="button"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
              <h4 id="ADDdetails_ModalLabel" className="modal-title w-100">
                <TextToSpeech text={t("Add Details about yourself")} />
              </h4>
            </div>
            <div className="modal-body">
              <div className="modal-text">
                <div className="form-group">
                  <label
                    htmlFor="nickname__ADDdetails_Modal"
                    className="form-label"
                  >
                    <TextToSpeech text={t("Enter your Nickname")} />
                  </label>
                  <input
                    id="nickname__ADDdetails_Modal"
                    className="subject"
                    type="text"
                    placeholder="Enter Your Nicename"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label
                    htmlFor="aboutme__ADDdetails_Modal"
                    className="form-label"
                  >
                    <TextToSpeech
                      text={t("Tell us something about yourself")}
                    />
                  </label>
                  <textarea
                    id="aboutme__ADDdetails_Modal"
                    aria-label={t("Tell us something about yourself")}
                    className="message"
                    placeholder="Tell us something about yourself"
                    value={aboutMe}
                    onChange={(e) => setAboutMe(e.target.value)}
                  ></textarea>
                </div>
                {/* <div className="form-group">
                  
                </div> */}
                <fieldset className="form-group">
                  <legend className="label text-left">
                    <TextToSpeech text={t("Choose your favourite sports")} />:
                  </legend>
                  <div className="check-list" role="tablist">
                    {sportList?.length > 0 &&
                      sportList.map((item, index) => {
                        return (
                          <div
                            className="items"
                            key={index}
                            role="presentation"
                          >
                            <label>
                              <input
                                type="checkbox"
                                name={item?.interest_category}
                                value={item?.interest_id}
                                onChange={(e) =>
                                  changeCheckbox(
                                    item?.interest_category,
                                    e.target.checked,
                                    index
                                  )
                                }
                                checked={item?.is_selected === 1 ? true : false}
                              />
                              <TextToSpeech text={` ${item?.interest_name}`} />
                            </label>
                          </div>
                        );
                      })}
                  </div>
                </fieldset>
                {/* <div className="form-group">
                                    <label className="form-label">Other Sports (if any)</label>
                                    <input className="subject" name='othersports' type='text'
                                        placeholder="Enter your sports if not available in the above list" />
                                </div> */}
                {/* <div className="form-group">
                  <label>
                    <TextToSpeech
                      text={t("Choose your favourite music type")}
                    />
                    :
                  </label>
                </div> */}
                <fieldset className="form-group">
                  <legend className="label text-left">
                    <TextToSpeech
                      text={t("Choose your favourite music type")}
                    />
                    :
                  </legend>
                  <div className="check-list" role="tablist">
                    {musicList?.length > 0 &&
                      musicList.map((item, index) => (
                        <div className="items" key={index} role="presentation">
                          <label>
                            <input
                              type="checkbox"
                              name={item?.interest_category}
                              value={item?.interest_id}
                              onChange={(e) =>
                                changeCheckbox(
                                  item?.interest_category,
                                  e.target.checked,
                                  index
                                )
                              }
                              checked={item?.is_selected === 1 ? true : false}
                            />
                            <TextToSpeech text={` ${item?.interest_name}`} />
                          </label>
                        </div>
                      ))}
                  </div>
                </fieldset>
              </div>
            </div>
            <div className="modal-footer">
              {/* <label htmlFor="update" className="hidden">
                {t("Update Profile")}
              </label> */}
              <button
                type="button"
                // aria-label={t("Update Profile")}
                onClick={() => updateUserDeatils()}
                className="btn Send"
                data-dismiss="modal"
              >
                <TextToSpeech text={t("Update")} />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        id="messageDelete"
        className="modal fade"
        role="dialog"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              {/* <label htmlFor={t("Close")} className="hidden">
                <TextToSpeech text={t("Close")} />
              </label> */}
              <button
                type="button"
                aria-label={t("Close Button")}
                className="close"
                data-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body h-auto">
              <div className="modal-image">
                <div>
                  <div className=" remove">
                    <img
                      alt={t("Default Profile Image")}
                      src="assets/web/profile-1.png"
                    />
                    <img
                      alt={t("Default Profile Images")}
                      src="assets/web/user-profiles.png"
                    />
                  </div>
                  <br />
                  <br />
                </div>
                <div>
                  <div className=" text">
                    <TextToSpeech
                      text={`${t(
                        "Are you sure you want to delete this conversation"
                      )} <br />${t("with ‘Little Angerls fc3’ ?")}`}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              {/* <label htmlFor={t("Cancel")} className="hidden">
                {t("Cancel")}
              </label> */}
              <button type="button" className="btn Cancel" data-dismiss="modal">
                <TextToSpeech text={t("Cancel")} />
              </button>
              {/* <label htmlFor={t("Delete Messages")} className="hidden">
                {t("Delete Messages")}
              </label> */}
              <button type="button" className="btn Delete" data-dismiss="modal">
                <TextToSpeech text={t("Delete Messages")} />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        id="Report_Modal"
        className="modal fade"
        role="dialog"
        tabIndex="-1"
        aria-labelledby="Report_ModalLabel"
        aria-hidden="true"
      >
        <div
          role="document"
          className="modal-dialog modal-dialog-centered modal-lg"
        >
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
              <h4 id="Report_ModalLabel" className="modal-title w-100">
                <TextToSpeech text={t("Report")} />
              </h4>
            </div>
            <div className="modal-body h-auto">
              <div className="modal-text pb-0">
                <div className="report-modal-section">
                  <div className="report-list">
                    <div className="form-group">
                      {/* <label>
                        <TextToSpeech
                          text={t("Why do you not like this message?")}
                        />
                      </label> */}
                    </div>
                    <div className="form-group">
                      <div className="items">
                        <label>
                          {" "}
                          <input type="radio" />
                          <TextToSpeech text={t("It was mean to me")} />
                        </label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="items">
                        <label>
                          <input type="radio" />
                          <TextToSpeech
                            text={t("He/She was making fun of me")}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="items">
                        <label>
                          <input type="radio" />
                          <TextToSpeech
                            text={t("It was spreading lies about me")}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="items">
                        <label>
                          <input type="radio" />
                          <TextToSpeech
                            text={t("It said bad things about me")}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="items">
                        <label>
                          <input type="radio" />
                          <TextToSpeech text={t("It used bad language")} />
                        </label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="items">
                        <label>
                          <input type="radio" />
                          <TextToSpeech
                            text={t("I didn't like the question being asked")}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="items">
                        <label>
                          <input type="radio" />
                          <TextToSpeech
                            text={t("It made me feel uncomfortable")}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="items">
                        <label>
                          <input type="radio" />
                          <TextToSpeech
                            text={t("It was looking for personal information")}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="items">
                        <label>
                          <input type="radio" />
                          <TextToSpeech text={t("Other")} />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="report-profile">
                    <img
                      alt={t("Report profile image")}
                      src="assets/web/profile-1.png"
                    />
                    <img
                      alt={t("Report profile image")}
                      src="assets/web/user-profiles9.png"
                    />
                  </div>
                </div>
                <div className="report-approprite">
                  <img alt={t("Blub hand")} src="assets/web/blub-hand.svg" />
                  <TextToSpeech
                    text={t(
                      "We will report this message and approriate actions will be taken"
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-start ml-4">
              {/* <label htmlFor={t("Report")} className="hidden">
                {t("Report")}
              </label> */}
              <button
                type="button"
                className="btn Delete ml-2"
                data-dismiss="modal"
              >
                <TextToSpeech text={t("Report")} />{" "}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        id="blockUserModal"
        className="modal fade"
        role="dialog"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div
          role="document"
          className="modal-dialog modal-dialog-centered modal-lg modal-custom"
        >
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body h-auto">
              <div className="modal-image">
                <div>
                  <div className=" remove">
                    <img
                      alt={t("Profile image")}
                      src="assets/web/profile-1.png"
                    />
                    <img
                      alt={t("Profile image")}
                      src="assets/web/user-profiles9.png"
                    />
                  </div>
                  <br />
                  <br />
                </div>
                <div>
                  <div className=" text">
                    <TextToSpeech
                      text={`${t(
                        "Are you sure you want to delete this conversation"
                      )} <br />${t("with ‘Little Angerls fc3’ ?")}`}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              {/* <label htmlFor={t("Cancel")} className="hidden">
                {t("Cancel")}
              </label> */}
              <button type="button" className="btn Cancel" data-dismiss="modal">
                <TextToSpeech text={t("Cancel")} />
              </button>
              {/* <label htmlFor={t("Block User")} className="hidden">
                {t("Block User")}
              </label> */}
              <button type="button" className="btn Delete" data-dismiss="modal">
                <TextToSpeech text={t("Block User")} />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        id="friend-RequestModal"
        className="modal fade"
        role="dialog"
        tabIndex="-1"
        aria-labelledby="friend-RequestModalLabel"
        aria-hidden="true"
      >
        <div
          role="document"
          className="modal-dialog modal-dialog-centered modal-lg modal-custom"
        >
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                onClick={() => {
                  $("input[name='friendReason']:checked").prop(
                    "checked",
                    false
                  );
                  setDisable(true);
                }}
                className="close"
                aria-label="Close"
                data-dismiss="modal"
              ></button>
              <h4 id="friend-RequestModalLabel" className="modal-title w-100">
                <TextToSpeech text={t("Why would you like to be friends?")} />
              </h4>
            </div>
            <div className="modal-body">
              <div className="modal-text pb-0">
                <div className="report-modal-section">
                  <div className="report-list request-list">
                    <div className="form-group" role="tablist">
                      {friend_reason_list != undefined &&
                        friend_reason_list.map((item, index) => (
                          <div
                            className="items"
                            key={index}
                            role="presentation"
                          >
                            <label>
                              <input
                                type="radio"
                                className="mx-1"
                                name="friendReason"
                                value={item.friend_reason_id}
                                onChange={(e) => setDisable(false)}
                              />
                              <TextToSpeech
                                text={t(item.friend_reason_message)}
                              />
                            </label>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="report-profile request-profile p-pic">
                    <img alt={t("Avatar")} src={avatar} />
                    <div>
                      <img alt={t("Arrow")} src="assets/web/arrow1.png" />
                      <TextToSpeech
                        text={`${
                          isAcceptRequest ? (
                            <p>
                              {t("You are accepting a friend request from")}{" "}
                              {displayUsername}
                            </p>
                          ) : (
                            <p>
                              {t("You are sending a friend request to")}{" "}
                              {displayUsername}
                            </p>
                          )
                        }`}
                      />
                    </div>
                  </div>
                </div>
                <div className="report-approprite request-approprite">
                  <img alt={t("Blub hand")} src="assets/web/blub-hand.svg" />
                  <TextToSpeech
                    text={`${
                      isAcceptRequest
                        ? `${t(
                            "You are accepting a friend request from"
                          )} ${displayUsername} ${t(
                            "with the reason you are selecting above. If feeling down, this will cheer up"
                          )} ${displayUsername}`
                        : `${t(
                            "We will send your friend request to"
                          )} ${displayUsername} ${t(
                            "with the reason you are selecting above. If feeling down, this will cheer up"
                          )} ${displayUsername}`
                    }`}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-start ml-4">
              {/* <label htmlFor={t("AddFriend")} className="hidden">
                {t("Accept or Add Friend")}
              </label> */}
              <button
                type="button"
                onClick={() => {
                  isAcceptRequest ? acceptFriendRequest() : addfriend();
                }}
                className="btn Delete ml-2"
                data-dismiss="modal"
                disabled={disable}
              >
                <TextToSpeech
                  text={`${isAcceptRequest ? t("Accept") : t("Send Request")}`}
                />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        id="ADDClass_Modal"
        className="modal fade"
        role="dialog"
        tabIndex="-1"
        aria-labelledby="ADDClass_ModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-custom">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                role="button "
                className="close"
                aria-label="Close"
                data-dismiss="modal"
              ></button>
              <h4 id="ADDClass_ModalLabel" className="modal-title w-100">
                <TextToSpeech text={t("Create Class")} />
              </h4>
            </div>
            <div className="modal-body">
              <div className="modal-text">
                <div className="row">
                  <div className="col-lg-8">
                    <div className="form-group">
                      <label
                        htmlFor="classTitle_AddClassModal"
                        className="form-label"
                      >
                        <TextToSpeech text={t("Title of the Class")} /> *
                      </label>
                      <input
                        id="classTitle_AddClassModal"
                        required
                        className="subject"
                        type="text"
                        placeholder={t("Subject")}
                        value={classTitle}
                        onChange={(e) => setClassTitle(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label
                        htmlFor="instruction_AddClassModal"
                        className="form-label"
                      >
                        <TextToSpeech text={t("Instruction")} />
                      </label>
                      <textarea
                        id="instruction_AddClassModal"
                        required
                        className="message"
                        placeholder={t("Enter your message")}
                        value={instruction}
                        onChange={(e) => setInstruction(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label
                        htmlFor="classDate_AddClassModal"
                        className="form-label"
                      >
                        <TextToSpeech text={t("Date of Class")} /> *
                      </label>
                      <input
                        id="classDate_AddClassModal"
                        required
                        type="date"
                        value={classDate}
                        className="w-100"
                        onChange={(e) => setClassDate(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label
                        htmlFor="startTime_AddClassModal"
                        className="form-label"
                      >
                        <TextToSpeech text={t("Start Time")} /> *
                      </label>
                      <input
                        required
                        type="time"
                        id="startTime_AddClassModal"
                        name="appt"
                        className="w-100"
                        onChange={(event) => setStartTime(event.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label
                        htmlFor="endTime_AddClassModal"
                        className="form-label"
                      >
                        <TextToSpeech text={t("End Time")} /> *
                      </label>
                      <input
                        required
                        type="time"
                        id="endTime_AddClassModal"
                        name="appt"
                        className="w-100"
                        onChange={(event) => setEndTime(event.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label
                        htmlFor="link_AddClassModal"
                        className="form-label"
                      >
                        <TextToSpeech text={t("Meeting Link")} /> *
                      </label>
                      <input
                        required
                        type="text"
                        id="link_AddClassModal"
                        value={classLink}
                        placeholder="Enter your sports if not available in the above list"
                        className="w-100"
                        onChange={(e) => setClassLink(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                id="createClassBtn_AddClassModal"
                type="button"
                onClick={() => handleCreateClass()}
                className="btn Send"
                data-dismiss="modal"
              >
                <TextToSpeech text={t("Create Class")} />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        id="feel_Modal"
        className="modal fade"
        role="dialog"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-feel-custom">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                aria-label="Close"
                data-dismiss="modal"
              ></button>
              <div className="modal-title feel-sad w-100 text-left">
                <h4 id="feel_ModalLabel">
                  <TextToSpeech text={t("We are sorry you are feeling sad.")} />
                </h4>
                <h5 className="text-white text-left">
                  <TextToSpeech
                    text={t(
                      "Everyone feels sad sometimes but look at the nice words one of your friends said about you."
                    )}
                  />
                </h5>
              </div>
            </div>
            <div className="modal-body modal-feel-section">
              <div className="modal-feel">
                <div className="feel-pro">
                  <img
                    alt={t("Profile image")}
                    src="assets/web/profile-1.png"
                  />
                </div>
                <div className="feelings-text">
                  <h4>
                    <TextToSpeech text={t("Danielle says")} />:
                  </h4>
                  <h5>
                    <TextToSpeech text={t("“You have a good heart”")} />
                  </h5>
                </div>
                <div className="feel-arrow">
                  <img
                    alt={t("Group copy")}
                    src="assets/web/group-14-copy-2.png"
                  />
                </div>
              </div>
              {/* <label htmlFor={t("Close")} className="hidden">
                {t("Close")}
              </label> */}
              <button type="button" className="btn Send" data-dismiss="modal">
                <TextToSpeech text={t("Close")} />
              </button>
              <div className="feel-sad-img">
                <img alt={t("Model feel")} src="assets/web/modal-feel.svg" />
              </div>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>

      {/* <div
        id="MessageSent_Modal"
        className="modal fade"
        role="dialog"
        tabIndex="-1"
        aria-labelledby="MessageSent_ModalLabel"
        aria-hidden="true"
      >
        <div
          role="document"
          className="modal-dialog modal-dialog-centered modal-lg modal-custom"
        >
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                aria-label="Close"
                data-dismiss="modal"
              ></button>
              <h4 id="MessageSent_ModalLabel" className="modal-title w-100">
                <TextToSpeech text={t("Send Message")} />
              </h4>
            </div>
            <div className="modal-body">
              <div className="modal-text h-auto pb-0">
                <div className="report-modal-section">
                  <div className="report-list request-list sendmessagemodal">
                    <form aria-description={t("Send message this user")}>
                      <div className="form-group">
                        <label
                          htmlFor="subject_MessageSent_Modal"
                          className="sr-only"
                        >
                          {t("Subject")}
                        </label>
                        <input
                          id="subject_MessageSent_Modal"
                          className="w-100"
                          type="text"
                          placeholder={t("Subject")}
                        />
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="message_MessageSent_Modal"
                          className="sr-only"
                        >
                          {t("Message")}
                        </label>
                        <textarea
                          id="message_MessageSent_Modal"
                          className="message"
                          placeholder={`${t("Enter your message")} *`}
                        ></textarea>
                      </div>
                      <div className="form-group mt-3">
                        {/* <label for={t("Send Message")} className="sr-only">{t("Send Message")}</label> /}
                        <button
                          type="button"
                          className="btn Delete ml-2"
                          data-dismiss="modal"
                        >
                          {t("Send Message")}
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className="report-profile request-profile">
                    <img
                      alt={t("Profile image")}
                      src="assets/web/profile-1.png"
                    />
                    <div>
                      <img alt={t("Arrow")} src="assets/web/arrow1.png" />
                      <p>
                        <TextToSpeech
                          text={t("You are sending a message to Chris Martin")}
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div
        id="ReportMessage_Modal"
        className="modal fade"
        role="dialog"
        tabIndex="-1"
        aria-labelledby="ReportMessage_ModalLabel"
        aria-hidden="true"
      >
        <div
          role="document"
          className="modal-dialog modal-dialog-centered modal-sm modal-custom"
        >
          <div className="modal-content">
            <div className="modal-header">
              <button
                id="test123#"
                type="button"
                className="close"
                aria-label="Close"
                data-dismiss="modal"
              ></button>
              <h4 id="ReportMessage_ModalLabel" className="modal-title w-100">
                {t("Report")}{" "}
              </h4>
            </div>
            <div className="modal-body">
              <div className="modal-text h-auto pb-0">
                <div className="report-modal-section">
                  <div className="report-list request-list sendmessagemodal">
                    <form aria-description={t("Report this message")}>
                      <div className="form-group">
                        <div className="report-message-dropdown ">
                          <label
                            htmlFor="report_message_subject__ReportMessage_Modal"
                            className="sr-only"
                          >
                            {t("Report subject")}
                          </label>
                          <select
                            className="form-control reportMessage"
                            id="report_message_subject__ReportMessage_Modal"
                          >
                            <option value={0} disabled>
                              <TextToSpeech text={t("Select Subject")} />
                            </option>
                            {report_subjectList.map((value, index) => (
                              <option key={index} value={t(value.name)}>
                                <TextToSpeech text={t(value.name)} />
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="reportDescription_ReportMessage_Modal"
                          className="hidden"
                        >
                          {t("Report description")}
                        </label>
                        <textarea
                          id="reportDescription_ReportMessage_Modal"
                          className="message report-input"
                          placeholder={`${t("Enter Description")} *`}
                        ></textarea>
                      </div>
                      <div className="form-group mt-3">
                        {/* <label for={t("Send report")} className="hidden">{t("Send report")}</label> */}
                        <button
                          type="button"
                          className="btn Delete ml-2"
                          id="ReportButton"
                          data-dismiss="modal"
                          onClick={() => sendMessage_report()}
                        >
                          <TextToSpeech text={t("Send Report")} />
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="Instruction_Modal"
        className="modal fade"
        role="dialog"
        tabIndex="-1"
        aria-labelledby="Instruction_ModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-feel-custom">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                aria-label="Close"
                data-dismiss="modal"
                onClick={() => isUpdate(true)}
              ></button>
              <div className="modal-title feel-sad w-100 text-left">
                <h4 id="Instruction_ModalLabel">
                  <TextToSpeech text={t("Instruction")} />
                </h4>
              </div>
            </div>
            <div className="modal-body modal-feel-section">
              <div className="modal-feel">
                {/* <h5 className="text-white text-left pl-4">
                  <TextToSpeech text={t(instructrionData)} />
                </h5> */}
                {/* <h5 className="text-white text-left pl-4">{instructrionData}</h5> */}
                {/* <div className="feel-pro">
                                    <img src="assets/web/profile-1.png" />
                                </div>
                                <div className="feelings-text">
                                    <h4>Danielle says:</h4>
                                    <h5>“You have a good heart”</h5>
                                </div>
                                <div className="feel-arrow">
                                    <img src="assets/web/group-14-copy-2.png" />
                                </div> */}
              </div>
              {onlineClassdata && (
                <div className="mt-3">
                  <div className="class-info pl-4" role="tabpanel">
                    <div className="key" style={{ color: "white" }}>
                      <TextToSpeech
                        text={`${t("Subject")} : ${t(
                          onlineClassdata.lecture_subject_name
                        )}`}
                      />
                    </div>
                  </div>
                  <div className="class-info pl-4" role="tabpanel">
                    <div className="key" style={{ color: "white" }}>
                      <TextToSpeech
                        text={`${t("Duration")} : ${t(
                          onlineClassdata.lecture_duration
                        )}`}
                      />
                    </div>
                  </div>
                  <div className="class-info pl-4" role="tabpanel">
                    <div className="key" style={{ color: "white" }}>
                      <TextToSpeech
                        text={`${t("Timings")} : ${t(
                          onlineClassdata.lecture_time
                        )}`}
                      />
                    </div>
                  </div>
                  <div className="class-info pl-4" role="tabpanel">
                    <div className="key" style={{ color: "white" }}>
                      <TextToSpeech
                        text={`${t("Lecture Date")} : ${t(
                          onlineClassdata.lecture_date
                        )}`}
                      />
                    </div>
                  </div>
                </div>
              )}
              {/* <label htmlFor={t("Close")} className="hidden">
                {t("Close")}
              </label> */}
              <button type="button" className="btn Send" data-dismiss="modal">
                <TextToSpeech text={t("Close")} />
              </button>
              {/* <div className="feel-sad-img">
                                <img src="assets/web/modal-feel.svg" />
                            </div> */}
            </div>
            {/* <div className="modal-footer">

                        </div> */}
          </div>
        </div>
      </div>

      <div
        id="change_coverImage"
        className="modal fade"
        role="dialog"
        tabIndex="-1"
        aria-labelledby="change_coverImage_ModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-feel-custom">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => isUpdate(true)}
              ></button>
              <div className="modal-title feel-sad w-100 text-left">
                <h4 id="change_coverImage_ModalLabel">
                  <TextToSpeech
                    text={`${t("Change Image")} ${uploadImagetype}`}
                  />
                </h4>
              </div>
            </div>
            <div className="modal-body modal-feel-section">
              <div className="modal-feel">
                {/* <h5 className="text-white text-left">{instructrionData}</h5> */}
                <div className="form-group w-100">
                  <div className="upload-view-box">
                    <label className="upload-image">
                      <img
                        alt={t("Add Image")}
                        src={process.env.PUBLIC_URL + "/assets/images/add.png"}
                        className="addimg"
                      />
                      <h5>
                        <img
                          alt={t("Choosen Image")}
                          src={
                            process.env.PUBLIC_URL + "/assets/images/u-img.png"
                          }
                          className="up"
                        />{" "}
                        <TextToSpeech text={t("Upload Image")} />
                      </h5>
                      <label
                        htmlFor="coverImage_changeCoverImageModal"
                        className="sr-only"
                      >
                        {t("Change Image")}
                      </label>
                      <input
                        type="file"
                        name="changeimage"
                        id="coverImage_changeCoverImageModal"
                        onChange={changeHandler}
                        accept="image/*"
                      />
                    </label>
                    <div className="upload-view">{uploadFilePath.media}</div>
                  </div>
                </div>
              </div>
              {/* <label htmlFor={t("UpdateImage")} className="hidden">
                {t("Update Image")}
              </label> */}
              <button
                type="button"
                className="btn Send"
                data-dismiss="modal"
                onClick={() => changeImage()}
              >
                <TextToSpeech text={t("Update")} />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        id="change_teacherCoverImage"
        className="modal fade"
        role="dialog"
        tabIndex="-1"
        aria-labelledby="change_teacherCoverImage_ModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-feel-custom">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => isUpdate(true)}
              ></button>
              <div className="modal-title feel-sad w-100 text-left">
                <h4 id="change_teacherCoverImage_ModalLabel">
                  <TextToSpeech
                    text={`${t("Change Image")} ${t(uploadImagetype)}`}
                  />
                </h4>
              </div>
            </div>
            <div className="modal-body modal-feel-section">
              <div className="modal-feel">
                {/* <h5 className="text-white text-left">{instructrionData}</h5> */}
                <div className="form-group w-100">
                  <div className="upload-view-box">
                    <label className="upload-image">
                      <img
                        alt={t("Add Image")}
                        src={process.env.PUBLIC_URL + "/assets/images/add.png"}
                        className="addimg"
                      />
                      <h5>
                        <img
                          alt={t("Choosen Image")}
                          src={
                            process.env.PUBLIC_URL + "/assets/images/u-img.png"
                          }
                          className="up"
                        />{" "}
                        <TextToSpeech text={t("Upload Image")} />
                      </h5>
                      <input
                        type="file"
                        name=""
                        id="coverImage"
                        onChange={changeTeacherCover}
                        accept="image/*"
                      />
                    </label>
                    <div className="upload-view">{uploadFilePath.media}</div>
                  </div>
                </div>
              </div>
              {/* <label htmlFor={t("Update Image")} className="hidden">
                {t("Update Image")}
              </label> */}
              <button
                type="button"
                className="btn Send"
                data-dismiss="modal"
                onClick={() => changeSchoolImage()}
              >
                <TextToSpeech text={t("Update")} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Modal;
