import moment from 'moment-timezone';
import 'moment/locale/hi';
import 'moment/locale/tr';
// import 'moment/locale/ar';
import GLOBAL_CONSTANT from '../config/global';


const changeTimeLang = (zoneTime) => {
  let languageSet = localStorage.getItem('language');
  if (typeof languageSet !== 'undefined') {
    return moment(zoneTime).locale(languageSet).local().fromNow();
  } else {
    return moment(zoneTime).local().fromNow()
  }
}

export function timeSince(date) {
    var seconds = Math.floor((new Date() - date) / 1000);
  
    var interval = seconds / 31536000;
  
    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
}

export function timeConvert(time){
  var zoneTime = moment.tz(time, GLOBAL_CONSTANT.TIME_ZONE);
  return changeTimeLang(zoneTime);
}