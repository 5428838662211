import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import env from "react-dotenv";
import { Link } from "react-router-dom";
import { Callbacks, data } from "jquery";
import TextToSpeech from "../../../services/textToSpeech";
import {
  like_dislike,
  share_post,
  add_comment_api,
  click_delete_api,
  delete_comment,
} from "../../../services/user_apis";
import { timeConvert } from "../../../Utils/TimeUtils";
import $ from "jquery";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

export default function PostListing(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem("user"));
  const [sharePostdata, setSharePostData] = React.useState([]);
  const [comment, setComment] = React.useState("");
  const [update, setUpdate] = React.useState(false);
  const [circular, setcircular] = React.useState(false);

  const renderLike = (value, index) => {
    var imgSrc = "";
    // var countData = "";

    const user = JSON.parse(localStorage.getItem("user"));

    if (value.is_liked === 1) {
      imgSrc = `${process.env.PUBLIC_URL + "/" + env.LIKE_PNG}dislike.png`;
    } else {
      imgSrc = `${process.env.PUBLIC_URL + "/" + env.LIKE_PNG}like.png`;
    }

    // if (value.like_count >= 1) {
    //     countData = "(" + value.like_count + ")";
    // } else {
    //     countData = "";
    // }

        return (
            <>
                <button 
                className="remove-style"
                    onClick={() => likeApiCall(value, index)}
                    style={{ display: "flex", "alignItems": "center" }}
                >
                    {" "}
                    <img alt={t("Like Button")} id={"imgLike" + value.post_id} src={imgSrc} />{" "}
                    <span
                        id={"aLike" + value.post_id}
                        // style={{ "marginBottom": "0" }}
                    >
                        <TextToSpeech text={t('Like')} />
                    </span>{" "}
                </button>
            </>
        );
    };

  const likeApiCall = (data, position) => {
    const post_body = {
      like_dislike_type: "post",
      like_dislike_type_id: data.post_id,
    };
    const response = like_dislike(post_body).then((response) => {
      // console.log(response);
      if (response.code === 1) {
        props.data[position].is_liked = response.data.is_liked;
        if (response.data.is_liked === 1) {
          document.getElementById("imgLike" + data.post_id).src =
            process.env.PUBLIC_URL + "/" + env.LIKE_PNG + "dislike.png";
        } else {
          document.getElementById("imgLike" + data.post_id).src =
            process.env.PUBLIC_URL + "/" + env.LIKE_PNG + "like.png";
        }
        // if (response.data.like_count >= 1) {
        //     document.getElementById("aLike" + data.post_id).innerHTML =
        //         "Like (" + response.data.like_count + ")";
        // } else {
        //     document.getElementById("aLike" + data.post_id).innerHTML =
        //         "Like ";
        // }
      }
    });
  };

    const sharePost = async () => {
        // console.log("shat post ", sharePostdata);
        // console.log("shareDescription", $("#shareDescription").val());
        // post_id(which you want to share post),post_description,user_id
        let post_body = {
            post_id: sharePostdata.post_id,
            post_description: $("#shareDescription").val(),
            user_id: user.user_id,
        };
        // console.log("post body  ===> ", post_body);
        const response = await share_post(post_body);
        if (response.code === 1) {
            $("#shareDescription").value = "";
            navigate(0);
        }
    };
    const deleteComment = async (data) => {
        Swal.fire({
            title: t("Are you sure?"),
            text: t("You won't be able to revert this!"),
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: t("Cancel"),
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("Yes, delete it!"),
        }).then((result) => {
            if (result.isConfirmed) {
                const post_body = {
                    comment_id: data?.comment_id,
                };
                const response = delete_comment(post_body).then((response) => {
                    if (response.code === 1) {
                        navigate(0);
                    }
                });
            }
        });
    };

  const likeApiCallForComment = (data, position) => {
    const post_body = {
      like_dislike_type: "comment",
      like_dislike_type_id: data.comment_id,
    };
    const response = like_dislike(post_body).then((response) => {
      // console.log(response);
      if (response.code === 1) {
        props.data[position].is_liked = response.data.is_liked;
        if (response.data.is_liked === 1) {
          document.getElementById("imgLike" + data.comment_id).src =
            process.env.PUBLIC_URL + "/" + env.LIKE_PNG + "dislike.png";
        } else {
          document.getElementById("imgLike" + data.comment_id).src =
            process.env.PUBLIC_URL + "/" + env.LIKE_PNG + "like.png";
        }
      }
    });
  };

  const CoomentrenderLike = (value, index) => {
    var imgSrc = "";

    if (value.is_liked === 1) {
      imgSrc = `${process.env.PUBLIC_URL + "/" + env.LIKE_PNG}dislike.png`;
    } else {
      imgSrc = `${process.env.PUBLIC_URL + "/" + env.LIKE_PNG}like.png`;
    }

    return (
      <>
        {
          <a
            onClick={() => likeApiCallForComment(value, index)}
            style={{ display: "flex", alignItems: "center" }}
          >
            {" "}
            <img
              alt={t("Like Button")}
              id={"imgLike" + value.comment_id}
              src={imgSrc}
            />{" "}
            <p id={"aLike" + value.post_id} style={{ marginBottom: "0" }}>
              <TextToSpeech text={t("Like")} />
            </p>{" "}
          </a>
        }
      </>
    );
  };

  const onClickComment = (postIndex) => {
    setComment("");
    $("#post-comment-" + postIndex).toggleClass("post-comment-active");
  };

    const onCLickDelete = (item, position) => {
        Swal.fire({
            title: t('Are you sure?'),
            text: t("You won't be able to revert this!"),
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: t("Cancel"),
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t('Yes, delete it!'),
        }).then((result) => {
            // console.log("Result", result);
            let post_body = { post_id: item.post_id };

      const response = click_delete_api(post_body).then((response) => {
        if (response.code === 1) {
          navigate(0);
        }
      });
    });
  };

  const handleAddComment = async (post_id, index) => {
    try {
      if (!circular) {
        setcircular(true);
        const post_body = {
          school_id: user.school_id,
          post_id: post_id,
          to_user_id: user.user_id,
          comment: comment,
        };
        $("#post-comment-" + index + " input").focus();
        const response = await add_comment_api(post_body);
        if (response) {
          setcircular(false);
          if (response.code === 1) {
            setUpdate(true);
            setComment("");
            $("#post-comment-" + index).removeClass("post-comment-active");
            props.data[index] = response.data;
          }
        } else {
          setcircular(false);
        }
      }
    } catch (e) {
      setcircular(false);
    }
  };

  const getPassDataOtherUser = (data) => {
    var dataPass = {
      user_id: data.user_id,
      username: data.username,
      display_user_name: data.display_user_name,
      user_about_me: data.user_about_me,
      avatar_full_path: data.avatar_full_path,
    };
    return dataPass;
  };

  return (
    <>
      <div
        role="dialog"
        id="share_Modal"
        className="modal fade"
        tabIndex="-1"
        aria-labelledby="share_ModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
          data-message={t("Share Post")}
        >
          <div className="modal-content">
            <div className="modal-header">
              {/* <label for={t("Share Post")} className="hidden">
                {t("Share Post")}
              </label> */}
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
              <h4 id="share_ModalLabel" className="modal-title w-100">
                <TextToSpeech text={t("Share Post")} />
              </h4>
            </div>
            <div className="modal-body h-auto">
              <div className="modal-text pb-0">
                <div className="report-modal-section">
                  <div className="form-group w-100">
                    <p className="text-light">
                      {t("Share this by reposting it with your own message.")}
                    </p>
                    <label
                      htmlFor="shareDescription"
                      className="sr-only"
                    >
                      {t("Share Post")}
                    </label>
                    <textarea
                      id="shareDescription"
                      className="message"
                      placeholder={t("Enter Your Message *")}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-center ml-4">
              {/* <label for={t("Share Post")} className="hidden">
                {t("Share Post")}
              </label> */}
              <button
                type="button"
                onClick={() => sharePost()}
                className="btn Delete ml-2"
                data-dismiss="modal"
              >
                <TextToSpeech text={t("Share")} />{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
      {(!update || update) &&
        props.data &&
        props.data.map((value, index) =>
          value.is_shared !== 1 ? (
            <div
              className="profile-info text-left"
              key={index}
              role="tabpanel"
            >
              <div className="profile-image">
                <div className="info-img">
                  <Link
                    to="/otherUserProfile"
                    state={{
                      otherUserData: getPassDataOtherUser(value),
                    }}
                    title={t(value?.display_user_name)}
                  >
                    <img
                      src={value.avatar_full_path}
                      alt={t(value?.display_user_name)}
                    />
                  </Link>
                </div>
                <div className="info-posts">
                  <p>
                    <TextToSpeech
                      text={`${
                        value?.display_user_name != ""
                          ? value?.display_user_name
                          : value.username
                      } ${
                        value.post_category === "avatar"
                          ? t("has changed profile avatar")
                          : t("posted a new update")
                      }`}
                    />
                  </p>
                  <p className="post-hrs">
                    <TextToSpeech text={timeConvert(value.created_at)} />
                    <i className="fas fa-user-friends"></i>
                  </p>
                </div>
              </div>
              <div className="profile-text text-left">
                <TextToSpeech text={value.post_description} />
              </div>
              {/* <p className="profile-text text-left">
                            {value.post_description}
                        </p> */}
              <hr />
              <div className="social">
                <div className="social-icon">{renderLike(value, index)}</div>
                <div className="social-icon">
                  <a
                   
                    href="javaScript:"
                    onClick={() => onClickComment(index)}
                  >
                    <img
                      alt=""
                      src={`${
                        process.env.PUBLIC_URL + "/" + env.COMMENT_PNG
                      }comment.png`}
                    />
                    <TextToSpeech text={t("Comment")} />{" "}
                    {value.comment_count >= 1
                      ? "(" + value.comment_count + ")"
                      : ""}
                  </a>
                </div>
                <div className="social-icon">
                  <a
                   
                    href="javaScript:"
                    onClick={() => setSharePostData(value)}
                    data-target="#share_Modal"
                    data-toggle="modal"
                  >
                    <img
                      alt=""
                      src={`${
                        process.env.PUBLIC_URL + "/" + env.SHARE_PNG
                      }share.png`}
                    />
                    <TextToSpeech text={t("Share")} />
                  </a>
                </div>

                {user.user_id === value.user_id ? (
                  <div className="social-icon">
                    <a
                      
                      onClick={() => onCLickDelete(value, index)}
                    >
                      <img
                        alt=""
                        width={20}
                        height={20}
                        src={
                          process.env.PUBLIC_URL +
                          "/" +
                          "assets/images/home/course/delete.png"
                        }
                      />
                      <TextToSpeech text={t("Delete")} />
                    </a>
                  </div>
                ) : null}

                {user.user_id != value.user_id && value.role === "student" ? (
                  <div className="social-icon">
                    <a
                      
                      href="javaScript:"
                      data-target="#ReportMessage_Modal"
                      data-toggle="modal"
                      onClick={() =>
                        props.callBackData({
                          reportdata: value,
                          type: "post",
                        })
                      }
                    >
                      <img
                        alt=""
                        width={20}
                        height={20}
                        src={
                          process.env.PUBLIC_URL +
                          "/" +
                          "assets/images/home/course/report.svg"
                        }
                      />
                      <TextToSpeech text={t("Report")} />
                    </a>
                  </div>
                ) : null}
              </div>
              {!location.pathname.includes("comment") && (
                <div className="Comments" data-message={t("Add Comment")}>
                  <div className="post-comment" id={"post-comment-" + index}>
                    <label htmlFor={"addcomment" + index} className="sr-only">
                      {t("Add Comment")}
                    </label>
                    <input
                      type="text"
                      placeholder={t("Write something here...")}
                      className="add-comment"
                      id={"addcomment" + index}
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    />
                    <button
                      data-message={t("Add Comment")}
                      className="post"
                      onClick={() => handleAddComment(value.post_id, index)}
                    >
                      <TextToSpeech text={t("Add Comment")} />
                    </button>
                  </div>
                  {value.comments &&
                    value.comments.slice(0, 5).map((item, commentindex) => (
                      <div
                        className="posted-coment mt-2"
                        key={commentindex}
                        data-message={t("Posted comments")}
                      >
                        <div className="p-left">
                          <div className="posted-profile">
                            <Link
                              to="/otherUserProfile"
                              state={{
                                otherUserData: getPassDataOtherUser(item),
                              }}
                              title={item?.display_user_nam}
                            >
                              <img
                                src={item.avatar_full_path}
                                alt={item?.display_user_name}
                              />
                            </Link>
                          </div>
                          <div className="posted-details">
                            <h5>
                              <TextToSpeech text={item.display_user_name} />
                            </h5>
                            {/* <p>{item.comment}</p> */}
                            <div
                              className="profile-text text-left"
                              data-message={`${t("Posted comment from")} ${t(
                                item.display_user_name
                              )}`}
                            >
                              <TextToSpeech text={item.comment} />
                            </div>
                          </div>
                        </div>

                        <div className="p-right">
                          <span>
                            <TextToSpeech text={timeConvert(item.created_at)} />
                          </span>
                          <div className="p-flow">
                            <div className="social-icon">
                              {CoomentrenderLike(item, commentindex)}
                            </div>
                            {user.user_id != item.from_user_id &&
                              item.role === "student" && (
                                <div className="social-icon">
                                  <a
                                    href="javaScript:"
                                    data-target="#ReportMessage_Modal"
                                    data-toggle="modal"
                                    onClick={() =>
                                      props.callBackData({
                                        reportdata: item,
                                        type: "comment",
                                      })
                                    }
                                  >
                                    {" "}
                                    <img
                                      alt=""
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/" +
                                        "assets/images/home/course/report.svg"
                                      }
                                    />
                                    <TextToSpeech text={t("Report")} />{" "}
                                  </a>
                                </div>
                              )}
                            {user.user_id == item.from_user_id && (
                              <div className="social-icon">
                                <a
                                  onClick={() => deleteComment(item)}
                                >
                                  <img
                                    alt=""
                                    width={20}
                                    height={15}
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/" +
                                      "assets/images/home/course/delete.png"
                                    }
                                  />
                                  <TextToSpeech text={t("Delete")} />
                                </a>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}

                  {value.comments.length > 5 ? (
                    <div className="key add-friend-section link-button  my-3">
                      <Link
                        to={`/comment/${value.post_id}`}
                        className="add-friend p-2 mx-2"
                      >
                        <TextToSpeech text={t("View All Comments")} />
                      </Link>
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          ) : (
            <div
              className="profile-info text-left"
              key={index}
              role="tabpanel"
              data-message={t("Share post")}
            >
              <div className="profile-image">
                <div className="info-img">
                  <Link
                    to="/otherUserProfile"
                    state={{
                      otherUserData: getPassDataOtherUser(value),
                    }}
                    title={value?.display_user_name}
                  >
                    <img alt={t("Share post")} src={value.avatar_full_path} />
                  </Link>
                </div>
                <div className="info-posts">
                  <p>
                    {value?.display_user_name != ""
                      ? value?.display_user_name
                      : value.username}{" "}
                    <span className="text-secondary">
                      <TextToSpeech text={t("shared")} />{" "}
                    </span>
                    <TextToSpeech
                      text={
                        value?.display_shared_user_name != ""
                          ? value?.display_shared_user_name
                          : value.shared_username
                      }
                    />
                    <span className="text-secondary">
                      <TextToSpeech text={t("'s post.")} />{" "}
                    </span>
                    <div
                      className="profile-text text-left"
                      data-message={t("Share post")}
                      dangerouslySetInnerHTML={{
                        __html: `${value.post_description}`,
                      }}
                    />
                  </p>
                  <p className="post-hrs">
                    <TextToSpeech text={timeConvert(value.created_at) + " "} />
                    <i className="fas fa-user-friends"></i>
                  </p>
                </div>
              </div>
              <p className="profile-text text-left">
                <div
                  className="profile-text text-left"
                  data-message={t("Share post description")}
                >
                  <TextToSpeech text={value.share_post_description} />
                </div>
              </p>
              <hr />
              <div className="social">
                <div className="social-icon">{renderLike(value, index)}</div>
                <div className="social-icon">
                  <a
                    href="javaScript:"
                    onClick={() => onClickComment(index)}
                  >
                    <img
                      alt=""
                      src={`${
                        process.env.PUBLIC_URL + "/" + env.COMMENT_PNG
                      }comment.png`}
                    />
                    <TextToSpeech text={t("Comment")} />{" "}
                    <TextToSpeech
                      text={
                        value.comment_count >= 1
                          ? "(" + value.comment_count + ")"
                          : ""
                      }
                    />
                  </a>
                </div>
                <div className="social-icon">
                  {/* <a href="javaScript:" onClick={() => setSharePostData(value)} data-target="#share_Modal" data-toggle="modal" >
                                <img
                                    src={`${process.env.PUBLIC_URL + "/" + env.SHARE_PNG}share.png`}
                                />
                                Share
                            </a> */}
                </div>
                {user.user_id != value.user_id ? (
                  <div className="social-icon">
                    <a
                      href="javaScript:"
                      data-target="#ReportMessage_Modal"
                      data-toggle="modal"
                      onClick={() =>
                        props.callBackData({
                          reportdata: value,
                          type: "post",
                        })
                      }
                    >
                      <img
                        alt=""
                        width={20}
                        height={20}
                        src={
                          process.env.PUBLIC_URL +
                          "/" +
                          "assets/images/home/course/report.svg"
                        }
                      />
                      <TextToSpeech text={t("Report")} />
                    </a>
                  </div>
                ) : null}
              </div>
              {!location.pathname.includes("comment") && (
                <div className="Comments" data-message={t("Add Comment")}>
                  <div className="post-comment" id={"post-comment-" + index}>
                    <label htmlFor={"addcomment" + index} className="sr-only">
                      {t("Add Comment")}
                    </label>
                    <input
                      type="text"
                      placeholder={t("Write something here...")}
                      className="add-comment"
                      id={"addcomment" + index}
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    />
                    <button
                      className="post"
                      onClick={() => handleAddComment(value.post_id, index)}
                    >
                      <TextToSpeech text={t("Add Comment")} />
                    </button>
                  </div>
                  {value.comments &&
                    value.comments.slice(0, 5).map((item, commentindex) => (
                      <div
                        className="posted-coment mt-1"
                        key={commentindex}
                        role="tabpanel"
                      >
                        <div className="p-left">
                          <div className="posted-profile">
                            <Link
                              to="/otherUserProfile"
                              state={{
                                otherUserData: getPassDataOtherUser(item),
                              }}
                              title={item?.display_user_name}
                            >
                              <img
                                src={item.avatar_full_path}
                                alt={item.display_user_name}
                              />
                            </Link>
                          </div>
                          <div className="posted-details" role="tabpanel">
                            <h5>
                              <TextToSpeech text={item.display_user_name} />
                            </h5>
                            <p>
                              <TextToSpeech text={item.comment} />
                            </p>
                          </div>
                        </div>

                        <div className="p-right">
                          <span>
                            <TextToSpeech text={timeConvert(item.created_at)} />
                          </span>
                          <div className="p-flow">
                            <div className="social-icon">
                              {CoomentrenderLike(item, commentindex)}
                            </div>
                            {user.user_id != item.from_user_id && (
                              <div className="social-icon">
                                <a
                                  href="javaScript:"
                                  data-target="#ReportMessage_Modal"
                                  data-toggle="modal"
                                  onClick={() =>
                                    props.callBackData({
                                      reportdata: item,
                                      type: "comment",
                                    })
                                  }
                                >
                                  {" "}
                                  <img
                                    alt=""
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/" +
                                      "assets/images/home/course/report.svg"
                                    }
                                  />
                                  <TextToSpeech text={t("Report")} />{" "}
                                </a>
                              </div>
                            )}
                            {user.user_id == item.from_user_id && (
                              <div className="social-icon">
                                <a
                                  onClick={() => deleteComment(item)}
                                >
                                  <img
                                    alt=""
                                    width={20}
                                    height={15}
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/" +
                                      "assets/images/home/course/delete.png"
                                    }
                                  />
                                  <TextToSpeech text={t("Delete")} />
                                </a>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}

                  {value.comments.length > 5 ? (
                    <div
                      className="key add-friend-section link-button  my-3"
                      role="tabpanel"
                    >
                      <Link
                        to={`/comment/${value.post_id}`}
                        className="add-friend p-2 mx-2"
                      >
                        <TextToSpeech text={t("View All Comments")} />
                      </Link>
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          )
        )}
    </>
  );
}
