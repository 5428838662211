import React, { useEffect } from 'react'
import env from 'react-dotenv'
import Home_Header from '../../inc/home-header/Home_Header'
import Modal from '../../inc/modal/Modal'
import Left_Panel from '../left-panel/Left_Panel'
import myclassbg from "./my-class-img/myclassbg.png"
import { CircularProgress } from '@mui/material';
// import { Button } from '@material-ui/core';
import {Button} from '@mui/material';
import { teacher_class_division_list } from '../../../services/user_apis'
import { post_list_api_for_class } from '../../../services/user_apis';
import { home_work_list } from '../../../services/user_apis';
import { user_list_api } from '../../../services/user_apis';
import { lecture_list_api } from '../../../services/user_apis';
import { division_details_api } from '../../../services/user_apis';
import { user_annoucement_api, postListCategoryWise, announcement_list, teacher_list, school_detail } from '../../../services/user_apis'
import { Link, useNavigate } from 'react-router-dom';
import PostListing from "../home/PostListing";
import { todayHomework } from "../../common";
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import TextToSpeech from '../../../services/textToSpeech'

const My_Class = ({ homeworknotice, socketData }) => {
    const { t } = useTranslation();
    let user = JSON.parse(localStorage.getItem('user'));
    const [ datalimit, setDatalimit ] = React.useState({ limit: 10, page: 1 });
    const [circular, setcircular] = React.useState(true)
    let [class_list, setClassList] = React.useState([])
    let [post, setpost] = React.useState([])
    let [homeworklist, sethomeworklist] = React.useState([])
    let [annoucement, setannoucement] = React.useState([])
    let [myfriends, setmyfriends] = React.useState([])
    let [lecture, setlecturelist] = React.useState([])
    let navigate = useNavigate();
    let [classDetails, setClassDetails] = React.useState({});
    let [cheermeup, setCheermeup] = React.useState([]);
    const [reportPostData, setReportPostData] = React.useState("")
    let [divisionDetails, setDivisionDetails] = React.useState(null)
    let [instructionData, setInstructionData] = React.useState("");
    let [schoolData, setschooldata] = React.useState([]);
    let [reportType, setReportType] = React.useState("");
    let [classTeacherList, setClassTeacherList] = React.useState([]);

    useEffect(() => {
        const user_list = async () => {
            try {
                const response = await user_list_api(user.class_id,user.division_id)
                if (response.code === 1) {
                    setmyfriends(response.data)
                    // console.log("Memebers in class", response.data)
                    // setcircular(false)
                }
            }
            catch (e) {
                navigate('/')
            }
        }
        user_list()
        announcement_listApi()
        school_details()
        classMember_list()
    }, [])

    const school_details = async () => {

        const school_data = await school_detail(user.school_id)
        if (school_data.code === 1) {
            setschooldata(school_data.data);
        } else {
            setschooldata("")
        }

    }

    const announcement_listApi = async () => {
        let post_body = {
            class_id: user.class_id,
            division_id: user.division_id,
            school_id: user.school_id
        }
        const response = await announcement_list(post_body)
        if (response.code === 1) {
            setannoucement(response.data)
        } else {
            setannoucement("")
        }
    }

    useEffect(() => {
        const class_list = async () => {
            if (user.user_role === "teacher") {
                const response = await teacher_class_division_list()
                if (response.code === 1) {
                    setClassList(response.data)
                    // setcircular(false)
                }
                setcircular(false)
            }
        }
        class_list()
    }, [])

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'))
        setClassDetails({ "class_name": user.class_name, "school_name": user.school_name, "division_name": user.division_name })
        getDivisionDetails(user.division_id)
        post_list_detail()
    }, [datalimit])

    const post_list_detail = async () => {
        var body = {
            ...{ "post_category": "class", "class_id": user.class_id, "school_id": user.school_id, "division_id": user.division_id },
            ...datalimit
        };
        try {
            const response = await postListCategoryWise(body)
            if (response.code === 1) {
                setpost(response.data)
            }
        }
        catch (e) {
            navigate('/')
        }
        setcircular(false)
    }

    const classMember_list = async (division_id) => {
        let post_body = {
            division_id: user.division_id
        }
        const response = await teacher_list(post_body)
        // console.log("teacher list  ===> ", response)
        if (response.code === 1) {
            setClassTeacherList(response.data)
        } else {
            setClassTeacherList([])
        }

    }

    const getDivisionDetails = async (division_id) => {
        // console.log("call division list data ")
        let post_body = {
            division_id: division_id
        }
        try {
            const response = await division_details_api(post_body)
            // console.log("Divisions Details api  ===> ", response)
            if (response.code === 1) {
                setDivisionDetails(response.data)
            }
        }
        catch (e) {
            navigate('/')
        }

    }

    const updateLimit = () => {
        setcircular(true);
        setDatalimit({...datalimit, "limit": Number(datalimit.limit) + 10 })
    }

    const pagignation = () => {
        if (circular) {
            return <CircularProgress />
        }
        if (!circular) {
            if (post.length < datalimit.limit) {
                return <div className="no-more-activities"><TextToSpeech text={t('No more activities found.')} /></div>
            }
            return <><label for={t('See More')} className="hidden">{t('See More')}</label><Button variant="outlined" size="medium" onClick={updateLimit}><TextToSpeech text={t('See More')} /></Button></>
        }
    }

    const getPassDataOtherUser = (data) => {
        var dataPass = { "user_id": data.user_id, "username": data.username, "display_user_name": data.display_user_name, "user_about_me": data.user_about_me, "avatar_full_path": data.avatar_full_path }
        return dataPass
    }

    return (
        <React.Fragment>

            <div className="content">
                <div className="left-portion">
                    <Left_Panel />
                </div>
                <div className="middle-portion">
                    <Home_Header socketData={socketData} />
                    <div className="container-fluid text-left">
                        <div className="row">
                            <div className="col-lg-8 pr-md-0">
                                <div className="main-content">
                                    <div className='my-profile school-profile myclass-profile'>
                                        <div className="p-banner" style={{ backgroundimage: `url(${(divisionDetails === null) ? myclassbg : divisionDetails.division_cover_image_full_path})` }}>
                                            <img alt={("Division Cover")} src={(divisionDetails === null) ? myclassbg : divisionDetails.division_cover_image_full_path} />
                                            {
                                                user.user_role === "teacher" && <div className="my-class-dropdown">
                                                    <label for={t("Division")} className="hidden">{t("Division")}</label>
                                                    <select className="form-control">
                                                        {class_list.map((value, i) => (

                                                            <option key={i}><TextToSpeech text={value.class_name} /></option>
                                                        ))}

                                                    </select>
                                                </div>
                                            }

                                        </div>
                                        <div className="p-icon">
                                            <div className="p-main-pic">
                                                <img alt={t("Division Logo")} src={(divisionDetails === null) ? `${env.FRIST_CLASS_SVG}first-class.svg` : divisionDetails.division_logo_image_full_path} />
                                            </div>
                                        </div>
                                        <div className="p-name pb-3">
                                        <TextToSpeech text={classDetails.class_name + " - " + classDetails.division_name} />
                                        </div>
                                        <div className="post-content">
                                            <ul role="tablist" className="nav nav-tabs">
                                                <li role="presentation"><a  href="#posts" data-toggle="tab" className="active"><TextToSpeech text={t('Posts')} /></a></li>
                                                <li role="presentation"><a  href="#info" data-toggle="tab"> <TextToSpeech text={t('Info')} /></a></li>
                                                <li role="presentation"><a  href="#announcements" data-toggle="tab" className="border-0" onClick={announcement_listApi}> <TextToSpeech text={t('Announcements')} /></a></li>
                                                <li role="presentation"><a  href="#teacher" data-toggle="tab"> <TextToSpeech text={t('Teachers')} /></a></li>
                                            </ul>
                                            {
                                                user.user_role === "teacher" && <div className="profile-tab-rightside">
                                                    <div className="createnewclass-btn">
                                                        <a  href="#" data-target="#ADDClass_Modal" data-toggle="modal"><TextToSpeech text={t('Create New lecture')} /></a>
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                    <div className="tab-content">
                                        <div className="tab-pane active" id="posts">
                                            <div className="profile-info">
                                                {
                                                    <PostListing data={post}
                                                        callBackData={(data) => {
                                                            setReportType(data.type)
                                                            setReportPostData(data.reportdata)
                                                        }} />
                                                }
                                                <br />
                                                <center>{pagignation()}</center>
                                            </div>
                                            <br />
                                            <br />
                                        </div>
                                        <div className="tab-pane" id="info">
                                            <div className="about-profile-info">
                                                <ul role="tablist">
                                                    <li role="presentation">
                                                        <p>{t('Name of Class')}: <b style={{ 'color': 'black' }}><TextToSpeech text={classDetails.class_name + " - " + classDetails.division_name} /></b></p>
                                                    </li>
                                                    <li role="presentation">
                                                        <p>{t('School')}: <b style={{ 'color': 'black' }}><TextToSpeech text={classDetails.school_name} /></b></p>
                                                    </li>
                                                    <li role="presentation">
                                                        <p>{t('Members in Class')}: <b style={{ 'color': 'black' }}><TextToSpeech text={myfriends.length} /></b></p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="announcements">
                                            <div className="row justify-content-start pt-4">
                                                {annoucement && annoucement.map((value, i) => (
                                                    <div className="col-lg-6 mb-3" key={i}>
                                                        <div className="other-class-box">
                                                            <div className="row w-100">
                                                                <div className="col-md-2 annoucement-img">
                                                                    <img alt={t("Announcement")} src="assets/web/annoucement.png" />
                                                                </div>
                                                                <div className="col col-md-10 picnic">
                                                                    <label><TextToSpeech text={value.title} /></label>
                                                                    <div className="picnic-team">
                                                                        {/* <img src="assets/web/team.png" /> */}
                                                                    </div>
                                                                    <div className="picninc-poster">
                                                                        <img alt={value.title} className='w-100' height={'200px'} style={{ "object-fit": "cover" }} src={value.announcement_image_full_path} />
                                                                    </div>
                                                                    <div className="register w-100 announcemetn-description">
                                                                        <p className="clear-style"><TextToSpeech text={value.description} /></p>
                                                                    </div>
                                                                    {value.description.length > 180 ? <a title={t('Read More')} className="add-friend " href="javaScript:" data-target="#Instruction_Modal" data-toggle="modal" onClick={() => setInstructionData(value?.description)} ><TextToSpeech text={t('Read More')} /></a> : null}
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                ))
                                                }

                                            </div>
                                        </div>
                                        <div className="tab-pane" id="teacher">
                                            <div className="about-profile-info">
                                                <ul role="tablist">
                                                    {classTeacherList && classTeacherList.map((item, i) => (
                                                        <li key={i} role="presentation">
                                                            <p > <TextToSpeech text={item.role === "class_teacher" ? t("Class Teacher"): t("Subject Teacher")} /> <b style={{ 'color': 'black' }}><TextToSpeech text={item.display_user_name ? item.display_user_name : item.user_name} /></b></p>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="right-side">
                                    <div className="school-right">
                                        <h3><TextToSpeech text={t('School')} /></h3>
                                        <div className="school-img">
                                            {schoolData != "" &&
                                                <>
                                                    <img alt={`${schoolData.school_name} profile picture`} src={schoolData.school_logo_full_path} className="s-img" />
                                                    <div className="school-name">
                                                        <h4><TextToSpeech text={schoolData.school_name} /></h4>
                                                        <h4><img alt={t("Location")} src={`${process.env.PUBLIC_URL}/assets/web/location-1.png`} /><TextToSpeech text={schoolData.school_location} /></h4>

                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                    {todayHomework(homeworknotice)}
                                    <div className="member-right" role="tabpanel">
                                        <h3><TextToSpeech text={`${t('Members in this Class')} (${myfriends.length})`} /></h3>
                                        <div className="mem-list">
                                            <ul role="tablist">
                                                {myfriends && myfriends.map((value, i) => (
                                                    <li key={i} role="presentation">
                                                        <Link  to="/otherUserProfile" state={{ "otherUserData": getPassDataOtherUser(value) }}>
                                                            <img alt={`${value?.display_user_name != "" ? value?.display_user_name : value?.username} profile picture`} src={value?.avatar_full_path != null ? `${value?.avatar_full_path}` : `${env.PRO_IMG}pro-img.png`} />
                                                            <TextToSpeech text={value?.display_user_name != "" ? value?.display_user_name : value?.username} />
                                                        </Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <Modal report_type={reportType} report_data={reportPostData} isUpdate={(data) => data === true && (setReportPostData(""), setInstructionData(""))} instructrionData={instructionData} />


        </React.Fragment >
    )
}

export default My_Class
