import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from 'react-redux';
import { addAudio, removeAudio } from '../../store/textspeechSlice';
import { text_speech } from '../user_apis';

const TextToSpeech = ({ visible = false, text }) => {
    const speak = localStorage.getItem('speak') === 'true' ? true : false;
    const dispatch = useDispatch();

    const handlePlay = () => {
        handleSubmit();
    };

    const handleStop = () => {
        dispatch(removeAudio(null))
    };

    const handleSubmit = async () => {
        try {
            if (speak && text) {
                let result = await text_speech(text.replace(/<[^>]*(>|$)| |‌|»|«|>/g, ' '));
                if (result) {
                    result = JSON.parse(result)
                    if (typeof result.url !== 'undefined') {
                        dispatch(addAudio(result.url))
                    }
                }
            }
        } catch (error) {
            console.error('Error generating speech:', error);
        }
    };

    const htmlOrText = () => {
        // let regexForHTML = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
        let regexForHTML = /<(“[^”]*”|'[^’]*’|[^'”>])*>/;
        let isValid = regexForHTML.test(text);
        if (isValid) {
            return <div className="row align-item-center justify-content-center my-5" dangerouslySetInnerHTML={{ __html: text }}></div>
        } else {
            return text;
        }
    }
    
    return (
        <span className="clear-style"  onMouseLeave={(e)=>{ e.stopPropagation(); handleStop(); }} onMouseEnter={(e)=>{ e.stopPropagation(); handlePlay();}}>{htmlOrText()}</span>
    );

}

export default TextToSpeech;