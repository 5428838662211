import React,{useState} from 'react'
import env from 'react-dotenv'
import Home_Header from '../../inc/home-header/Home_Header'
import Modal from '../../inc/modal/Modal'
import Left_Panel from '../left-panel/Left_Panel'
import '../my-homework-teacher/AddHomeWork.css'
import { useParams ,useNavigate} from 'react-router-dom'
import { uploadImage } from "../../../services/imageUpload";
import { add_home_work,get_homework_details,update_home_work } from '../../../services/user_apis'
import moment from 'moment'
import Progress from "react-progress-2";
import { useTranslation } from 'react-i18next';
import TextToSpeech from '../../../services/textToSpeech'

export default function AddHomeWork({ socketData }) {

   const { t } = useTranslation();
   var homeWorkId="";
   const [homeWorkDetails, setHomeworkDetails] = React.useState();
   const [circular, setcircular] = React.useState(false)

   let {id} =  useParams()
   var idList=id.split("&");
   // console.log("Select Id",idList);
   let navigate = useNavigate();

   const type=idList[0]
   const classId=idList[1]
   const divisionId=idList[2]

   if(type==="e"){
      homeWorkId=idList[3]
   }

   window.Buffer = window.Buffer || require("buffer").Buffer;

  const [upload, setUpload] = React.useState([]);
  const [uploadFilePath, setUploadFilePath] = React.useState([]);
  const [uploadedFilePath, setUploadedFilePath] = React.useState([]);

  const changeHandler = (event) => {
    let tmpFile =  event.target.files[0] 
    setUpload([...upload , tmpFile])
   //  console.log("event data  ====> ", event.target, "===== ", event.target.files[0])
  
    uploadImage(tmpFile , (callbackData) => {
      var fileType="word"
      if(tmpFile.type.includes("image")){
         fileType="image"
      }else if(tmpFile.type.includes("pdf")){
         fileType="pdf"
      }else{
         fileType="word"
      }
      // console.log("File Type",fileType);

      var tempData={"media":callbackData,"file_type":fileType}
      setUploadFilePath([...uploadFilePath,tempData])
      // console.log("uploaded data ===>" , uploadFilePath)
  })

}

const removeFile = (index) => {
    let tmpData = Object.assign([] , upload)
    tmpData.splice(index , 1);
    setUpload(tmpData)

    let tmpDataFilePath = Object.assign([] , uploadFilePath)
    tmpDataFilePath.splice(index , 1);
    setUploadFilePath([...uploadFilePath,tmpDataFilePath])   
}

const removeFileUploadedIt = (index) => {
   // console.log("File Uploaded data",uploadedFilePath)
   let tmpDataFilePath = Object.assign([] , uploadedFilePath)
   tmpDataFilePath.splice(index , 1);
   // console.log("File Uploaded data splice",tmpDataFilePath)
   setUploadedFilePath(tmpDataFilePath)   
}

React.useEffect(() => {

   if(type==="e"){
      // console.log("Call Home work details api")
      const homeWorkDetails = async () => {
          const post_body = {
              "homework_id": homeWorkId
          }
          const response = await get_homework_details(post_body)
          if (response.code === 1) {
            //   console.log("get homework details data  ===> ", response)
              setHomeworkDetails(response.data)
              setUploadedFilePath(response.data.homework_file_list)

              document.getElementById("hwTitle").value=response.data.homework_title
              document.getElementById("hwMode").value=response.data.homework_type
              document.getElementById("hwQuestion").value=response.data.homework_question
              document.getElementById("hwSubmissionDate").value=response.data.homework_last_submission_date
          }
      }
      homeWorkDetails()   
   }
   
}, [])

const clickSubmitButton = async (e)=>{
   e.preventDefault();

   if(type==="e"){

      var uploadNewFile=[]
      
      uploadedFilePath && uploadedFilePath.map((item) => {
         var tempData={"media":item.media,"file_type":item.file_type}
         uploadNewFile.push(tempData)
      })

      uploadFilePath && uploadFilePath.map((item) => {
         uploadNewFile.push(item)
      })

      let homeWorkBody = {
         homework_id:homeWorkId,
         class_id: classId,
         division_id: divisionId,
         homework_title: document.getElementById("hwTitle").value,
         homework_type: document.getElementById("hwMode").value,
         homework_question: document.getElementById("hwQuestion").value,
         homework_file_list:uploadNewFile,
         homework_last_submission_date:document.getElementById("hwSubmissionDate").value
     }
  
     if(uploadNewFile.length<=0){
      delete homeWorkBody.homework_file_list
      }
   
     let response = await update_home_work(homeWorkBody)
   
   //   console.log("Update Home Work",response);
     if (response.code === 1) {
         navigate(-1)
     }

   }else{
      let homeWorkBody = {
         class_id: classId,
         division_id: divisionId,
         homework_title: document.getElementById("hwTitle").value,
         homework_type: document.getElementById("hwMode").value,
         homework_question: document.getElementById("hwQuestion").value,
         homework_file_list:uploadFilePath,
         homework_last_submission_date:document.getElementById("hwSubmissionDate").value
     }
   
      if(uploadFilePath.length<=0){
      delete homeWorkBody.homework_file_list
      }
   
   
     let response = await add_home_work(homeWorkBody)
   
   //   console.log("Add Home Work",response);
     if (response.code === 1) {
         navigate(-1)
     }
   }
  return
}

  return (
    <React.Fragment>
    <div className="content">
        <div className="left-portion">
            <Left_Panel />
        </div>
        <div className="middle-portion">
            <Home_Header socketData={socketData} />
            <div className="container-fluid">
               <section className="post-section pt-4">
                  <div className="container">
                     <div className="row justify-content-center">
                        <div className="col-lg-10">
                           <div className="my-school">
                              <div className="create-post">
                                 <div className="post-title text-center">
                                    <h3> <TextToSpeech text={type==="e"? t("Edit Homework"): t("Create Homework")} /> </h3>
                                 </div>
                                 <form id="form_validation" method="" onSubmit={clickSubmitButton} data-message={t("Create/Edit Homework")} action="#">
                                 <div className="class-form">
                                    <div className="form-group">
                                       <input aria-label={t("Homework Title")} type="text" name="hwTitle" id="hwTitle" placeholder={t("Homework Title")} className="form-control" required/>
                                    </div>
                                    <div className="form-group">
                                       <input aria-label={t("Last Submission Date")} type="date" name="hwSubmissionDate" id="hwSubmissionDate" placeholder={t("Last Submission Date")} className="form-control datepicker" required/>
                                    </div>
                                    <div className="form-group text-center">
                                       <h4><TextToSpeech text={t('Question Mode')} /></h4>
                                       <p><TextToSpeech text={t('Textboxes and upload files are available for adding questions.')} /></p>
                                    </div>
                                    <div className="form-group">
                                       <select aria-label={t("Answer Mode")} type="text" name="hwMode" id="hwMode" placeholder={t("Answer Mode")} className="form-control" required>
                                             <option value="text"><TextToSpeech text={t('Answer Mode Text')} /></option>
                                             <option value="file"><TextToSpeech text={t('Answer Mode File')} /></option>
                                             <option value="both"><TextToSpeech text={t('Answer Mode Both')} /></option>
                                       </select>
                                    </div>
                                    <div className="form-group">
                                       <textarea aria-label={t("Write Questions")} className="form-control" name='hwQuestion' id='hwQuestion' placeholder={t("Write Questions")} rows="5" required></textarea>
                                    </div>   
                                    <div className="form-group">
                                       <div className="upload-view-box">
                                                <label className="upload-image">
                                                    <img alt={t("Add Homework")} src={process.env.PUBLIC_URL + "/assets/images/add.png"} className="addimg" />
                                                    <h5><img alt={t("Upload Homework")} src={process.env.PUBLIC_URL + "/assets/images/u-img.png"} className="up" /> <TextToSpeech text={t('Upload Image')} /></h5>
                                                    <input aria-label={t('Upload Image')} type="file" name="" onChange={changeHandler}  accept="image/*,.doc,.docx,.pdf" />
                                                </label>                                                                    
                                                <div className="upload-view">
                                                    {uploadedFilePath && uploadedFilePath.map((item, index) => (
                                                    <div className='m-1' key={index}><img alt={t("Remove file")} src={item.file_type === "pdf" ? process.env.PUBLIC_URL + "/assets/images/pdf.png" : (item.file_type === "image" ) ? process.env.PUBLIC_URL + "/assets/images/file.png" : process.env.PUBLIC_URL + "/assets/images/doc.png"} style={{ width: 35, height: 35 }} /> {item.media} <i role="button" data-message={t("Remove file")} className="far fa-times" onClick={() => removeFileUploadedIt(index)} ></i></div>
                                                     ))}

                                                     {upload != '' && upload.map((item,index) => (
                                                    <div className='m-1' key={index}><img alt={t("Remove file")} src={item.type === "application/pdf" ? process.env.PUBLIC_URL + "/assets/images/pdf.png" : (item.type === "image/png" || item.type === "image/jpeg" || item.type === "image/jpg" ) ? process.env.PUBLIC_URL + "/assets/images/file.png" :  process.env.PUBLIC_URL + "/assets/images/doc.png"} style={{width:35  , height:35}} /> {item.name} <i role="button" className="far fa-times" data-label={t("Remove file")} onClick={() => removeFile(index)} ></i></div>
                                                    ))}
                                                </div>
                                                                    
                                        </div>

                                    </div>
                                    <div className="form-group text-center">
                                       <button aria-label={t("Create/Update Homework")} className="cybersmart-btn-m btn-block" data-dismiss="modal" type='submit'><TextToSpeech text={type==="e"? t("Update Homework"): t("Create Homework")} /></button>
                                    </div>
                                 </div>
                                 </form>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
            </div>

        </div>
        <div className="content">
         <div className="middle-portion">
         </div>
      </div>

    </div>
    <Modal />
</React.Fragment>
)
}
