import React, { useEffect, useState } from 'react'
import Home_Header from '../../inc/home-header/Home_Header'
import Modal from '../../inc/modal/Modal'
import Left_Panel from '../left-panel/Left_Panel'
import { fun_zone_list } from '../../../services/user_apis'
import { useNavigate, useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import TextToSpeech from '../../../services/textToSpeech'

const Fun_Zone = ({ socketData }) => {
    const { state } = useLocation();
    let url = state.funzonedata.fun_zone_content

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <React.Fragment>
            <div className="content">
                <div className="left-portion">
                    <Left_Panel />
                </div>
                <div className="middle-portion">
                    <Home_Header socketData={socketData} />
                    <div className="container-fluid" role="tabpanel">
                        <div className="row align-item-center justify-content-center my-5"><TextToSpeech text={url} /></div>
                    </div>
                </div>
            </div>
            <Modal />
        </React.Fragment>
    )
}

export default Fun_Zone


